/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import i18next from 'i18next';
import Sprite from '../Assets/Icons/sprite.svg'

export default class SozlesmeCardMobil extends Component {


    render() {
        const { active1, active2, active3, active4, active5, active6, active7 } = this.props;
        const title = active1 ?
            <p className="d-inline card--color">
                <a href="#" className="p-size-3">{i18next.t("FtShippingReturns")}  </a>
            </p>
            :
            active2 ?
                <p className="d-inline card--color">
                    <a href="#" className="p-size-3">{i18next.t("FtMembership")}</a>
                </p>
                :
                active3 ?
                    <p className="d-inline card--color">
                        <a href="#" className="p-size-3">{i18next.t("FtTermsConditions")}</a>
                    </p>
                    :
                    active4 ?

                        <p className=" d-inline card--color">
                            <a href="#" className="p-size-3"> {i18next.t("FtPrivacyPolicy")}</a>
                        </p>
                        :
                        ""
        // active5  &&
        //     <p className="d-inline">
        //       <a href="#" className="p-size-3"> FAQ</a>
        //     </p>


        return (
            <div>
                <DropdownButton id="dropdown-item-button" title={title} className="UserDetailCardMobil">
                    <Dropdown.Item active={active1} as="button">
                        <p className="">
                            <a href="/shipping-returns" className="p-size-3">{i18next.t("FtShippingReturns")}</a>
                        </p>
                    </Dropdown.Item>
                    <Dropdown.Item active={active2} as="button">
                        <p>
                            <a href="/membership" className="p-size-3">{i18next.t("FtMembership")}</a>
                        </p>
                    </Dropdown.Item>
                    <Dropdown.Item active={active4} as="button">
                        <p>
                            <a href="/termsconditions" className="p-size-3">{i18next.t("FtTermsConditions")}</a>
                        </p>
                    </Dropdown.Item>
                    <Dropdown.Item active={active3} as="button">
                        <p>
                            <a href="/privacy-policy" className="p-size-3"> {i18next.t("FtPrivacyPolicy")}</a>
                        </p>
                    </Dropdown.Item>

                    {/* <Dropdown.Item active={active5} as="button">
                        <p>
                            <a href="/faq" className="p-size-3">FAQ</a>
                        </p>
                    </Dropdown.Item> */}


                </DropdownButton>
            </div>

            // <div>
            //     <select  className="border-0">
            //     <option className="selected ">  342sdfsdf33423</option>
            //         <option onClick={()=>history.push("/")}>  dededede </option>
            //     </select>
            // </div>
        )
    }
}
