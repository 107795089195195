/* eslint-disable jsx-a11y/anchor-is-valid */
import i18next from 'i18next';
import React, { Component } from 'react';
import ArtsAndWorkshopCard from '../Components/ArtsAndWorkshopCard';
import BreadCrumb from '../Components/BreadCrumb';
import InspirationBanner from '../Components/InspirationBanner';
import KeepInTouch from '../Components/KeepInTouch';
import ApiBase from '../lib/ApiBase';
export default class ArtsAndWorkShopsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingBlogs: false,
      loadingBlogDetail: false,
      ArtsDetailData: [],
      similarArtList: [],
      IsContinue:false,
    }
    // console.log("id", this.props.match.params.blogId)

  }
  componentDidMount() {
    this.getArtsDetail();
    this.getSimilarArts();
  }
  getArtsDetail = () => {
    this.setState({ loadingBlogDetail: true });
    const _ArtsId = this.props.match.params.artsId;
    ApiBase.Post({
      url: '/get-arts-workshops-detail',
      data: {
        Id: _ArtsId
      },
      successFunction: (e) => {
        console.log(e.data.data)
        this.setState({
          ArtsDetailData: e.data.data.detailData,
          loadingBlogDetail: false
        });
      },
      errorFunction: (e) => {
        this.setState({ loadingBlogDetail: false })
        // console.log(e.data.message)
      }
    })
  };

  getSimilarArts = () => {
    const _ArtsId = this.props.match.params.artsId;
    this.setState({ loadingBlogs: true })
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-similiar-workshops',
      data: {
        LanguageId: LangId,
        WorkshopId: _ArtsId,

      },
      successFunction: (e) => {
        // console.log("similiar", e.data.data)
        this.setState({
          similarArtList: e.data.data.workshopList,
          loadingBlogs: false

        });
      },
      errorFunction: (e) => {
        this.setState({ loadingBlogs: false })
        // console.log(e.data.message)
      }
    })
  };


  render() {

    const item = this.state.ArtsDetailData;

    const BannerList = this.state.similarArtList.map((item, index) => {
      return (
        <div key={item.Id} className="col-lg-4">
          <InspirationBanner isBlog blogHref={`/artsandworkshopdetail/${item.Url}`} className={'InspirationBanner__text mt-auto '} item={item} />
        </div>
      )
    });
    return (
      <div className="BlogDetail">
        <div className="container">
          {/* <BreadCrumb title={i18next.t("BlogMagazine")} titleLink={"/blogandmagazine"} path={item.Title} ></BreadCrumb> */}


          <div className="BlogAndMagazine__blog">
            <div className="row">
              <div className=" col-lg-6 BlogAndMagazine__Description">



                <div className="BlogAndMagazine__toptittle"> {i18next.t('LatestNews')}</div>
                <p className="BlogAndMagazine__tittle">{item.Title}</p>
                <p className="BlogAndMagazine__text  " dangerouslySetInnerHTML={{ __html: item.Description }}>
                </p>
                {/* <div className="row">
                  <div className="pl-5">
                    <a className="BlogAndMagazine__link2" href={`/artsandworkshopdetail/${item.Url}`}> {i18next.t('StartShopping')}<span> {'>'} </span></a>
                  </div>
                </div> */}
              </div>
              <div className="col-lg-6">
                <div className="BlogAndMagazine__Img BlogAndMagazine__Img--pd ">
                  <img src={item.CoverPhoto} className="img-fluid" alt={item.Title}></img>

                </div>
              </div>
            </div>
          </div>


          <div className="BlogDetail__Banner" >
            <img src={item.DetailPhoto} className="img-fluid" alt={item.Title} ></img>
          </div>
        </div>
        <div className="BlogAndMagazine__header">
          <h4 >Wondrous Magazine</h4></div>
        <div className="InspirationBanner">
          <div className="container">
            <div className="row">
              {BannerList}
            </div>
          </div>
        </div>
        <KeepInTouch></KeepInTouch>
      </div>

    )
  }
}
