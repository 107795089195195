/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import BreadCrumb from '../Components/BreadCrumb';
import i18next from 'i18next';
import { inject, observer } from 'mobx-react';
import ApiBase from '../lib/ApiBase';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import KeepInTouchImg from '../Assets/Image/KeepInTouch__Img.svg';
import Sprite from '../Assets/Icons/sprite.svg';
const DesignerList= observer(class DesignerList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CategoryList:[],
            SelectedCategoryId:0,
            designerList:[]
        }
    }
componentDidMount(){
    this.getDesignerList();
    this.getMainCategories();
}
getMainCategories = () => {
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    this.setState({ actLoading: true })
    ApiBase.Post({
      url: '/get-main-categories',
      data: {    
        CategoryId: this.state.SelectedCategoryId,     
        LanguageId: LangId,
        Take: 24
      },
      successFunction: (e) => {
         console.log("mainCategories", e.data.data.categories)
        this.setState({
        CategoryList:e.data.data.categories
        });
      },
      errorFunction: (e) => {
        // console.log(e.data.message)
        this.setState({ actLoading: false, showErrorModal: true, errorMessage: e.data.message });
      }
    })
  }
    getDesignerList = () => {
        var Lang = localStorage.getItem("lang")
        var LangId = Lang === "tr" ? 1 : 2;
        this.setState({ actLoading: true })
        ApiBase.Post({
          url: '/designer-list',
          data: {    
            CategoryId: this.state.SelectedCategoryId,     
            LanguageId: LangId,
            Take: 24
          },
          successFunction: (e) => {
             console.log("designerList", e.data.data.List)
    
            this.setState({
            designerList:e.data.data.List
            });
    
    
          },
          errorFunction: (e) => {
            // console.log(e.data.message)
            this.setState({ actLoading: false, showErrorModal: true, errorMessage: e.data.message });
    
          }
        })
    
      }
      onClickCategories=(item)=>{
          this.setState({SelectedCategoryId:item.Id},()=>{
              this.getDesignerList();
          })
      }
      onClickAll=()=>{
          this.setState({SelectedCategoryId:0},()=>{
              this.getDesignerList();
          })
      }
    render() {
        return (
            <div className="DesignerList">
                <div className="container">
                    <BreadCrumb path={"MARKALAR"} pathLink={"/designerlist"} />
                    <div className="DesignerList__Detail">
                    <h2 className="h-size-4 text-center mt-4 ">MARKALAR</h2>
                    <div className="row mt-4 ">
                        <div className="col-lg-10 mx-auto">
                            <p className="p-size-1 text-center">Alanında profesyonel tasarımcıların özel tasarım ürünlerinden oluşturduğumuz seçkimiz ve yeni tasarımcıları da destekleyen misyonumuz ile sizlere sürekli olarak güncel ve ilham verici bir koleksiyon sunuyoruz.  </p>
                        </div>
                    </div>
                    <div className="DesignerList__filterBtns row text-center">
                        <div className="col-12">
                            <a onClick={()=>{this.onClickAll()}} className={this.state.SelectedCategoryId >0 ? "":"active"}>{i18next.t("All")}</a>
                            {this.state.CategoryList.map(item=>{
                                return(
                                <a onClick={()=>{this.onClickCategories(item)}} className={this.state.SelectedCategoryId==item.Id ? "active":""}>{item.Name}</a>
                                )
                            })}
                            {/* <a href="#" className="">{i18next.t("House")}</a>
                            <a href="#" className="">{i18next.t("Art")}</a>
                            <a href="#" className="">{i18next.t("Woman")}</a>
                            <a href="#" className="">{i18next.t("Man")}</a>
                            <a href="#" className="">{i18next.t("Child")}</a>
                            <a href="#" className="">{i18next.t("JeweleryAccessory")}</a>
                            <a href="#" className="">{i18next.t("Pet")}</a>
                            <a href="#" className="">{i18next.t("Office")}</a> */}
                            {/* <a href="#" className="font-weight-bold p-size--color3 ">{i18next.t("Discount")}</a> */}
                        </div>


                    </div>


                    <div className="all-designer-list mt-4">
                        {this.state.designerList.map(item => {
                            return (
                                <div className="item d-inline-block w-100 ">
                                    <figure className="d-block">
                                        <span>{item.FirstChar}</span>
                                        {item.DesignerNames.map(dItem => {
                                            return (
                                                <ul className="list-unstyled mb-1">
                                                    <li key={dItem.Id}>
                                                         <a href={`/designers/${dItem.Url}`} className="designer__link p-size-1">{dItem.Name}</a>
                                                    </li>
                                                </ul>
                                            )
                                        })}
                                    </figure>
                                </div>
                            )
                        })}


                    </div>
                    </div>
                 
                </div>
            </div>

        )
    }
})
export default inject((stores) => ({
    Store: stores.Store,
  }))(DesignerList);