import React, { Component } from 'react';
import ProfilDetailCard from '../Components/ProfilDetailCard';
import BreadCrumb from '../Components/BreadCrumb';
import CreditCards from '../Components/CreditCards';
import UserDetailCardMobil from '../Components/UserDetailCardMobil';
import i18next from 'i18next';


export default class KayıtlıAdreslerim extends Component {
    constructor(props) {
        super(props);
        this.state = {

            creditCards: [
                {
                    creditCardId: 1,
                    creditCardNumber: '4783 4783 3872 3878',
                    receiverName: 'Jane Doe',
                    expirationDate: '03/09'

                },
                {
                    creditCardId: 2,
                    creditCardNumber: '4783 4783 3872 3878',
                    receiverName: 'Jane Doe',
                    expirationDate: '03/09'

                },
                {
                    creditCardId: 3,
                    creditCardNumber: '4783 4783 3872 3878',
                    receiverName: 'Jane Doe',
                    expirationDate: '03/09'

                },
                {
                    creditCardId: 4,
                    creditCardNumber: '4783 4783 3872 3878',
                    receiverName: 'Jane Doe',
                    expirationDate: '03/09'

                }
            ],

            selectedId:0,



        };


    }

    selectCheckBox = (id) => {
        this.setState({ selectedId: id })
    }

    render() {
        const creditCards = this.state.creditCards.map(item => {
            return (
                <div className="col-12 col-lg-6  ">
                    <div class="row input-group mb-3  w-auto">
                        <div class=" col-1 input-group-prepend ">
                            <div class="input-group-text border-0 bg-transparent p-0">
                                <input onClick={() => this.selectCheckBox(item.creditCardId)} type="checkbox"  checked ={ this.state.selectedId === item.creditCardId}/>
                            </div>
                        </div>
                        <div className="kayitlikartlar col-10">
                            <CreditCards item={item} />
                        </div>
                    </div>
                </div>
            )
        });
        return (
            <div className="KayitliKartlarim">
                <div className="container">
                    <BreadCrumb hesabim path={i18next.t("MyCreditCards")}></BreadCrumb>
                    <div className="row  UserDetail__KayitliKartlarim">
                        <div className="col-lg-3  d-none d-lg-block UserDetail__section1">
                            <ProfilDetailCard  className5={"bg--secondary"}></ProfilDetailCard>
                        </div>
                        <div className="col-lg-3  d-lg-none UserDetail__section1">
                            <UserDetailCardMobil active4></UserDetailCardMobil>
                        </div>
                        <div className="col-lg-9   UserDetail__section2">
                            <div className="row">

                                {creditCards}
                            </div>
                        </div>
                    </div>

                </div>

            </div>
              
            )
    }
}
