/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Sprite from '../Assets/Icons/sprite.svg'
import i18next from 'i18next';
export default class OrderDetail extends Component {
    render() {
        const { item, orderDetail } = this.props;
        return (

            <div className="row UserDetail__sectionSub2 py-2">
                <span className="col-lg-3 OrderDetail__OrderCode  my-auto d-block">
                    {item.OrderNo}
                    <span className=" OrderDetail__ItemCount OrderDetail__ItemCount--f  d-inline d-lg-block ml-2">{item.ProductCount} {i18next.t("Product")}</span>
                </span>


                <div className="col-lg-3   OrderDetail__OrderStatus my-auto" >
                    {
                        item.Status !== 11 ?
                            <p className={item.Status === 6 || item.Status === 9 || item.Status === 10 || item.Status === 11 ? "OrderDetail__OrderStatus2" : item.Status === 4 ? "OrderDetail__OrderStatus3" : "OrderDetail__OrderStatus1"} >{item.StatueStr}</p>
                            :
                            <p className={item.Status === 6 || item.Status === 9 || item.Status === 10 || item.Status === 11 ? "OrderDetail__OrderStatus2" : item.Status === 4 ? "OrderDetail__OrderStatus3" : "OrderDetail__OrderStatus1"} >{item.PreviousStatueStr} ({item.StatueStr})</p>
                    }
                    {/* {item.Status === 1 ?
                        <p className="OrderDetail__OrderStatus1">{i18next.t("OrderCompleted")}</p> : item.Status === 2 ?
                            <p className="OrderDetail__OrderStatus2">{i18next.t("OrderCanceled")}</p> :
                            <div>
                                <p className="OrderDetail__OrderStatus3">{i18next.t("SentCargo")}</p>
                            </div>}
                </div>
                <div className="col-lg-1 order-6 order-lg-0 my-auto">
                    {item.Status === 3 ?
                        <p  >
                            <a href="#" className="cargo-icon d-block text-center py-2" >
                                <svg className="iconSize-xxxs  cargo-icon--m" >
                                    <use href={Sprite + "#cargo-truck"} ></use>
                                </svg >
                                <span className="d-lg-none d-inline">{i18next.t("CargoTracking")}</span>
                            </a>
                        </p> : <></>} */}
                </div>
                <div className="col-lg-2  offset-lg-1 OrderDetail__OrderDate my-auto">
                    <p> {item.DateStr}</p>
                </div>
                <div className="col-lg-2  OrderDetail__OrderTotalPrice my-auto">
                    <p> <span className="d-inline d-lg-none font-weight-normal"> {i18next.t("TotalPrice")} </span>{orderDetail ? item.TotalProductPriceStr : item.PriceStr}₺</p>
                </div>
                <div className="col-lg-1 my-auto OrderDetail__ArrowRight">
                    <p>
                        <a href={`/siparislerimdetay/${item.OrderId}`}>
                            <svg className="iconSize-sm " >
                                <use href={Sprite + "#arrow-right"} ></use>
                            </svg >
                        </a>
                    </p>
                </div>

            </div >
        )
    }
}
