/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import BreadCrumb from '../Components/BreadCrumb';
import FavorilerimItems from '../Components/FavorilerimItems';
import OrderSummary from '../Components/OrderSummary';
import Slider from "react-slick";
import i18next from 'i18next';
import ProductCard from '../Components/ProductCard';
import { inject, observer } from 'mobx-react';
import ApiBase from '../lib/ApiBase';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import KeepInTouchImg from '../Assets/Image/KeepInTouch__Img.svg';
import Sprite from '../Assets/Icons/sprite.svg';
const Sepetim = observer(class Sepetim extends Component {
  constructor(props) {
    super(props);
    this.state = {
      favoriteList: [],
      isFavorited: -1,
      selectedValue: null,
      CartList: [],
      TotalPrice: null,
      VariationList: [],
      CartLargeList: [],
      RecommendedProducts: [],
      FavoriteList: [],
      CargoPriceStr: "",
      TotalPriceStr: "",
      favoriteIds: [],
      TotalProductPriceStr: "",
      CartPrices: [],
      productListString:[],
      actLoading:false
    };


  }

  componentDidMount() {
    this.getMyCarts();
    this.getRecommendedProducts();
    this.getFavoriteIds();
    this.getCartPrice();
  }


  getCartPrice = () => {
    this.setState({ actLoading: true });

    var _isLogin = localStorage.getItem("isLogin");
    // console.log(_isLogin)
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    var Lang = localStorage.getItem("lang")
    var AUID = localStorage.getItem("AnonymousUserId");
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-cart-prices',
      data: {
        LanguageId: LangId,
        UserId: _UserId,
        AnonymousUserId: AUID,
      },
      successFunction: (e) => {
        console.log("cartPrice", e.data.data)

        this.setState({
          CartPrices: e.data.data
        })
      },
      errorFunction: (e) => {
        // console.log(e.data.message)
        this.setState({ actLoading: false, showErrorModal: true, errorMessage: e.data.message });

      }
    })
  }
  toggleFavorite = (item) => {
    const i = this.state.favoriteList.indexOf(item.ProductId)

    if (i >= 0) {
      this.state.favoriteList.splice(i, 1)
      // console.log("i 0 dan büyük",this.state.favoriteList)
    } else {
      this.state.favoriteList.push(item.ProductId)
      // console.log("i 0 dan küçük", this.state.favoriteList)
    }
    this.setState({ favoriteList: this.state.favoriteList });
    // // console.log(this.state.favoriteList)
    // console.log("iiii", i)
  };
  notify = () => toast.success("Sepet Güncellendi !");
  _successFunction = () => {
    this.getMyCarts();
    this.getCartPrice()
  }
  getMyCarts = () => {
    this.setState({ actLoading: true });
let _productListString=[];
    var _isLogin = localStorage.getItem("isLogin");
    // console.log(_isLogin)
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    var Lang = localStorage.getItem("lang")
    var AUID = localStorage.getItem("AnonymousUserId");
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-cart-products',
      data: {
        LanguageId: LangId,
        UserId: _UserId,
        AnonymousUserId: AUID,
      },
      successFunction: (e) => {
        console.log("sepetim", e.data.data)
        this.setState({
          CargoPriceStr: e.data.data.CargoPriceStr,
          TotalPriceStr: e.data.data.TotalPriceStr,
          TotalProductPriceStr: e.data.data.TotalProductPriceStr,
          CartList: [],
          CartList: e.data.data.List,
          VariationList: e.data.data.List.Variations
        },()=>{
          e.data.data.List.map(item=>{
            _productListString.push(item.Id)
          });
          this.setState({productListString:_productListString});
        });
      },
      errorFunction: (e) => {
        // console.log(e.data.message)
        this.setState({ actLoading: false, showErrorModal: true, errorMessage: e.data.message });

      }
    })
  }

  onClickOdemeyeGec = () => {
    var _isLogin = localStorage.getItem("isLogin");

    this.setState({ odemeyeGecLoading: true });
    // console.log(_isLogin)
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    var Lang = localStorage.getItem("lang")
    var AUID = localStorage.getItem("AnonymousUserId");
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/check-cart-stock',
      data: {
        productListString: this.state.productListString,
        UserId: _UserId,
        AnonymousUserId: AUID,
      },
      successFunction: (e) => {
      this.setState({odemeyeGecLoading:false})
        if (_isLogin === "true") {
          this.props.history.push('/adresbilgisi')
        } else {
          this.props.history.push('/siparisodeme')
        
      }
      },
      errorFunction: (e) => {
        // console.log(e.data.message)
        this.setState({ odemeyeGecLoading: false, showErrorModal: true, errorMessage: e.data.message });

      }
    })
  }
  
    // console.log(_isLogin)
 
  _onChangeSizeValues = (e,) => {
    this.setState({ selectedValue: e.target.value });
  }
  ////FAVORİEKLE/////
  AddFavorite = (ProductId) => {
    this.setState({ addFavoriteLoading: true })
    var _isLogin = localStorage.getItem("isLogin");
    var cUser = [];
    if (_isLogin === "true") {
      cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
    }
    ApiBase.Post({
      url: '/add-favourite',
      data: {
        ProductId: ProductId,
        CurrentUser: cUser
      },
      successFunction: (e) => {
        // console.log("favoriekle", e.data)
        this.setState({ addFavoriteLoading: false })
        this.getFavoriteIds();

        // this.toggleFavorite(item)
      },
      errorFunction: (e) => {
        this.setState({ addFavoriteLoading: false, showErrorModal: true, errorMessage: e.data.message })
        // console.log(e.data.message)

      }
    })
  }

  getFavoriteIds = () => {
    var _isLogin = localStorage.getItem("isLogin");
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    var favIdList = [];
    ApiBase.Post({
      url: '/get-favorite-ids',
      data: {

        UserId: _UserId,

      },
      successFunction: (e) => {
        // console.log("favoriteıds", e.data.data.List)
        e.data.data.List.map(item =>
          favIdList.push(item.ProductId));
        this.setState({ favoriteIds: favIdList })
        //this.setState({ CartList: e.data.data.List, CartTotalPriceStr: e.data.data.TotalPriceStr })
      },
      errorFunction: (e) => {

        // console.log("hataaa",e.data.message)
      }
    })
  }
  getRecommendedProducts = () => {
    this.setState({ loading: true })
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-featured-products',
      data: {
        LanguageId: LangId,
      },
      successFunction: (e) => {
        // console.log("name", e.data.data.productList)
        this.setState({
          RecommendedProducts: e.data.data.productList,
        });
      },
      errorFunction: (e) => {
        this.setState({ loadingBlogs: false })
        // console.log(e.data.message)
      }
    })
  }
  //////////
  getCartCount = () => {
    var AUID = localStorage.getItem("AnonymousUserId")
    var _isLogin = localStorage.getItem("isLogin");
    // console.log(_isLogin)
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    this.props.Store.CartStore.GetCartCount(_UserId, AUID)
  }
  clearCart = () => {
    var AUID = localStorage.getItem("AnonymousUserId")
    var _isLogin = localStorage.getItem("isLogin");
    // console.log(_isLogin)
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }



    ApiBase.Post({
      url: '/clear-cart',
      data: {

        AnonymousUserId: AUID,
        UserId: _UserId,
      },
      successFunction: (e) => {

        // console.log("sepeteekle", e.data)


        // console.log("categories",e.data.data.product.Categories[0].NameTr)
        this.setState({ addCartLoading: false, })
        this.getCartCount();
        this.getMyCarts();
        // this.notify();

      },
      errorFunction: (e) => {
        // this.setState({ addCartLoading: false, showErrorModal: true, errorMessage: e.data.message })
        // console.log(e.data.message)
      }
    })
  }
  removeFavorites = (ProductId) => {
    this.setState({ addFavoriteLoading: true });
    var _isLogin = localStorage.getItem("isLogin");
    var cUser = [];
    if (_isLogin === "true") {
      cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
    }


    ApiBase.Post({
      url: '/delete-favourite',
      data: {
        ProductId: ProductId,
        CurrenUser: cUser
      },
      successFunction: (e) => {
        // console.log("remove", e.data)

        this.getFavoriteIds();
        this.setState({ addFavoriteLoading: false })
        // this.setState({ loading: false })
      },
      errorFunction: (e) => {
        // console.log(e.data.message)
        this.setState({ addFavoriteLoading: false, showErrorModal: true, errorMessage: e.data.message });

      }
    })
  }
  succesFunc = () => {
    this.getCartPrice();
    this.getMyCarts();
  }
  render() {

    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: this.state.RecommendedProducts.length < 4 ? this.state.RecommendedProducts.length : 4,
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            autoplaySpeed: 3000,
            arrows: false,


          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: false,
            autoplaySpeed: 3000,
            arrows: false,



          }
        }
      ]

    };
    const ListItem = this.state.RecommendedProducts.map((pitem) => {
      // console.log(pitem)
      return (
        <li key={pitem.ProductId} >
          <ProductCard item={pitem} _className={this.state.favoriteIds.indexOf(pitem.Id) >= 0 ? "product-card__like-button active" : "product-card__like-button"} toggleFavorite={() => { this.state.favoriteIds.indexOf(pitem.Id) >= 0 ? this.removeFavorites(pitem.Id) : this.AddFavorite(pitem.Id) }} history={this.props.history} ></ProductCard>
        </li>
      );
    });

    // });
    // const optionsList = this.state.optionList.map((item) => {
    //   return (
    //     <option value={item.value}>{item.label}</option>
    //   )
    // });

    const variationsOptionsList = [];
    this.state.CartList.map(item => {
      item.Variations.map(vItem => {
        return (
          variationsOptionsList.push({ value: vItem.Id, label: vItem.Stock <= 0 ? vItem.Name + " (Stok Tükendi)" : vItem.Name })
        )
        // return(
        // <option value={vItem.Id} >{vItem.NameTr}</option>
        // ) 
      })
    });



    const sepetItems = this.state.CartList.map((item, index) => {
      return <FavorilerimItems succesFunc={this.succesFunc} item={item} />
    });
    const orderSummary = this.state.CartList.map(item => {

      return <OrderSummary loading={this.state.odemeyeGecLoading} onClickOdemeyeGec={this.onClickOdemeyeGec} CargoPriceStr={this.state.CargoPriceStr} TotalPriceStr={this.state.TotalPriceStr} item={item} />
    });



    return (
      <>
        {this.state.addFavoriteLoading == true &&
          <div className="favoriteoverlay">

          </div>
        }
              <BreadCrumb path={i18next.t("MyBasket")}></BreadCrumb>
        <div className="Sepetim">
          <div className="container">
            <div className="sepetim__wrapper">
              <div className="row sepetim__mt">
                <div className="col-lg-9">
                  <div className="row d-none d-lg-flex">
                    <div className="col-5">
                      <h5>{i18next.t("ProductInformation")}</h5>
                    </div>
                    <div className="col-2">
                      <h5>{i18next.t("SizeUp")}</h5>
                    </div>
                    <div className="col-2">
                      <h5>{i18next.t("CountUp")}</h5>
                    </div>
                    <div className="col-3">
                      <h5>{i18next.t("TotalPrice")}</h5>
                    </div>

                  </div>
                  {sepetItems}
                  <a onClick={this.clearCart} className="text-right d-block h-size-2 text-dark font-weight-normal my-2">{i18next.t("ClearCart")}</a>
                </div>
                <div className="col-lg-3">
                  {/* {orderSummary} */}
                  <OrderSummary onClickOdemeyeGec={this.onClickOdemeyeGec} item={this.state.CartPrices} />
                </div>
              </div>
              <div className="divCategoryName mb-3 mt-3">
                  Benzer Ürünler
                    <div className="divHomeBlackLine"></div>
                </div>
              <div className=" product-card--pd">
                <div className="">
                  <Slider {...settings}>
                    {ListItem}
                  </Slider>
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
          <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showErrorModal} onHide={this.hideLoginModal}>
              <Modal.Header closeButton>
              </Modal.Header>
              <h4 className="LoginModal__title text-center">BAŞARISIZ</h4>
              <p></p>
              <Modal.Body>
                <div className="text-center mb-3">
                  <svg className="iconSize-md" >
                    <use href={Sprite + "#sad-face"} ></use>
                  </svg >
                </div>
                <form className="LoginModal  ">
                  <p className="text-center">{this.state.errorMessage}</p>
                  <div className="text-center  w-50  mx-auto">
                    <a onClick={() => { this.setState({ showErrorModal: false }) }} className="submit-button">{i18next.t('OK')}</a>
                  </div>
                </form>
              </Modal.Body>
              {/* <Modal.Footer>
                <div className="KeepInTouch__Img ">
                  <img src={KeepInTouchImg} className="img-fluid"></img>
                </div>
              </Modal.Footer> */}
          </Modal>
        </div>
      </>
    )
  }
})
export default inject((stores) => ({
  Store: stores.Store,
}))(Sepetim);
