import React, { Component } from 'react'

export default class CategorySliderCard extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        const { item } = this.props;

        return (
            <div className="CategorySliderCard text-center">
                <a href={`/shop/${item.Url}`}>
                    <img style={{objectFit:"contain"}} src={item.PhotoPath} alt={item.Name} className="img-fluid" />
                    <a className="text-dark h-size-2">{item.Name}</a>
                </a>

            </div>
        )
    }
}
