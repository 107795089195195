import React, { Component } from 'react'
import i18next from 'i18next';
import Sprite from '../Assets/Icons/sprite.svg';
import Modal from 'react-bootstrap/Modal';
import KeepInTouchImg from '../Assets/Image/KeepInTouch__Img.svg';
import ApiBase from '../lib/ApiBase';
import Logo from '../Assets/Image/logo.png';
export default class ForgotPasswordChange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: "",
      newPasswordAgain: "",
      actLoading: false,
      showSuccessActivationModal: false,
      showErrorModal: false,
      UserId: null,
      Code: null,
      message: "",
    };

  }
  componentDidMount() {
    this.GetForgotPasswordData();
  }
  GetForgotPasswordData = () => {
    this.setState({ actLoading: true })
    const _guid = this.props.match.params.guid;
    // console.log(_guid)
    ApiBase.Post({
      url: '/get-forgot-password-data',
      data: {
        Guid: _guid
      },
      successFunction: (e) => {
        // console.log(e.data)
        this.setState({ actLoading: false, message: e.data.message, Code: e.data.data.Code, UserId: e.data.data.UserId });
        // this.setState({ loading: false })
      },
      errorFunction: (e) => {
        // console.log(e.data)
        this.setState({ actLoading: false, message: e.data.message });

      }
    })
  }

  ForgotPasswordSetNewPass = () => {
    this.setState({ actLoading: true })
    const _guid = this.props.match.params.guid;
    // console.log(_guid);
    var Lang = localStorage.getItem("lang");
    var LangId = Lang === "tr" ? 1 : 2;
    if (this.state.newPassword !== this.state.newPasswordAgain) {
      this.setState({showErrorModal:true, errorMessage:"şifreler uyuşmamaktadır"})
    } else {
      ApiBase.Post({
        url: '/forgot-set-newpass',
        data: {
          UserId: this.state.UserId,
          Code: this.state.Code,
          Password: this.state.newPassword,
          LanguageId: LangId
        },
        successFunction: (e) => {
          // console.log(e.data)
          this.setState({ actLoading: false, errorMessage: e.data.message,showSuccessActivationModal:true });
          // this.setState({ loading: false })
        },
        errorFunction: (e) => {
          // console.log(e.data)
          this.setState({ actLoading: false, message: e.data.message, showErrorModal: true });

        }
      })
    }


  }

  render() {

    return (
      <div className="container">


        <div className="row">
          <div className="col-lg-12 text-center mx-auto  ">
            <form className="LoginModal  ForgotPassowordPage d-flex flex-column justify-content-center">
              <p className="h-size-4 py-4 text-center">{this.state.message}</p>

              <div class="form-group  mx-auto">
                <input type="password" placeholder={i18next.t('NewPassword')} value={this.state.newPassword} onChange={(e) => this.setState({ newPassword: e.target.value })} />
              </div>
              <div class="form-group  mx-auto">
                <input type="password" placeholder={i18next.t('NewPasswordAgain')} value={this.state.newPasswordAgain} onChange={(e) => this.setState({ newPasswordAgain: e.target.value })} />
              </div>
              <div className="text-center  w-50  mx-auto">
         <a onClick={this.ForgotPasswordSetNewPass}  className="submit-button">{i18next.t('Update')}</a>
              </div>


            </form>
          </div>

          {/* <div className="SignUpImg col-lg-6  text-center d-lg-flex d-none">
            <img src={Logo} className="img-fluid" alt="register" />

          <div className="text-center  w-50  mx-auto">
          

          </div>

        </div> */}

        <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showSuccessActivationModal} onHide={this.hideLoginModal}>
            <Modal.Header closeButton>
            </Modal.Header>
            <h4 className="LoginModal__title text-center">{i18next.t('ProcessSuccess')}</h4>
            <Modal.Body>
              <form className="LoginModal  ">
                <div className="text-center mb-3">
                  <svg className="iconSize-md" >
                    <use href={Sprite + "#smile"} ></use>
                  </svg >
                </div>
                <p className="text-center p-size-1">{i18next.t('NewPasswordSuccessText')}</p>
                <div className="text-center  w-50  mx-auto">
                  <a href="/" className="submit-button">{i18next.t('Home')}</a>
                </div>
              </form>
            </Modal.Body>
            {/* <Modal.Footer>
              <div className="KeepInTouch__Img ">
                <img src={KeepInTouchImg} className="img-fluid"></img>
              </div>
            </Modal.Footer> */}
        </Modal>
        <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showErrorModal} onHide={() => { this.setState({ showErrorModal: false }) }}>
            <Modal.Header closeButton>
            </Modal.Header>
            <h4 className="LoginModal__title text-center">{i18next.t('UnSuccessful')}</h4>
            <p></p>
            <Modal.Body>
              <div className="text-center mb-3">
                <svg className="iconSize-md" >
                  <use href={Sprite + "#sad-face"} ></use>
                </svg >
              </div>
              <form className="LoginModal  ">
                <p className="text-center  p-size-1">{this.state.message}</p>
                <div className="text-center  w-50  mx-auto">
                  <a onClick={() => this.setState({ showErrorModal: false })} className="submit-button">{i18next.t('TurnBack')}</a>
                </div>
              </form>
            </Modal.Body>
            {/* <Modal.Footer>
              <div className="KeepInTouch__Img ">
                <img src={KeepInTouchImg} className="img-fluid"></img>
              </div>
            </Modal.Footer> */}
        </Modal>
      </div>
      </div>
    )
  }
}
