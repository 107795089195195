import React, { Component } from 'react';
import KeepInTouchImg from '../Assets/Image/KeepInTouch__Img.svg';
import i18next from 'i18next';
import ApiBase from '../lib/ApiBase';
import Modal from 'react-bootstrap/Modal';
import Sprite from '../Assets/Icons/sprite.svg';
export default class KeepInTouch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Email: '',
      showSuccesModal: false
    }
  }
  AddSubscriber = () => {
    this.setState({ addLoading: true })

    ApiBase.Post({
      url: '/add-subscriber',
      data: {
        Email: this.state.Email,
      },
      successFunction: (e) => {

        this.setState({ addLoading: false, showSuccesModal: true });
        // this.setState({ loading: false })
      },
      errorFunction: (e) => {
        this.setState({ addLoading: false })
        alert(e.data.message)
      }
    })
  }
  render() {
    return (
      <>
      <div className="divENewsRegister">
          <div className="divEnewsRegisterTitle">Bülten</div>
          <div className="divEnewsRegisterTitleLine"></div>
          <div className="divEnewsRegisterDesc">{i18next.t('ToReceiveNew')}</div>
          <div className="divFormContainer">
            <div className="divEmailInput"><input className="form-control" type="text" placeholder="E-Posta" value={this.state.Email} onChange={(e) => this.setState({ Email: e.target.value })}/></div>
            <div className="divEmailSubmitBtn" onClick={this.AddSubscriber}>KAYIT OL</div>
          </div>
        </div>
        <div className=" container-fluid">
          {/* <div className="KeepInTouch__Img ">
            <img src={KeepInTouchImg} className="img-fluid" alt="keepintouch"></img>
          </div>
          <p className="KeepInTouch_tittle">
            {i18next.t('KeepInTouch')}
          </p>
          <div className="KeepInTouch__hr">
            <div className="headerSlider__border"></div>
          </div>
          <p className="KeepInTouch__text">
            {i18next.t('ToReceiveNew')}
          </p>
          <div className="KeepInTouch__subscribe">
            <div className="row ">
              <div className="col-lg-6 mx-auto ">
                <div className="input-group px-2 ">
                  <input className="email w-75" placeholder="E-mail" value={this.state.Email} onChange={(e) => this.setState({ Email: e.target.value })}></input>
                  <div class="input-group-append  w-25">
                    <button onClick={this.AddSubscriber} class=" w-100 " type="button">
                      {i18next.t('Register')}
                    </button>

                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showSuccesModal} onHide={this.hideLoginModal}>
            <Modal.Header closeButton>
            </Modal.Header>
            <h4 className="LoginModal__title text-center"> {i18next.t("ProcessSuccess")}</h4>
            <p></p>
            <Modal.Body>
              <div className="text-center mb-3">
                <svg className="iconSize-md" >
                  <use href={Sprite + "#smile"} ></use>
                </svg >
              </div>
              <form className="LoginModal  ">
                <p className="text-center p-size-1"> {i18next.t("SuccessMessage")}</p>
                <div className="text-center  w-50  mx-auto">
                  <button href={"/"} className="submit-button">{i18next.t('OK')}</button>
                </div>
              </form>
            </Modal.Body>
            {/* <Modal.Footer>
              <div className="KeepInTouch__Img ">
                <img src={KeepInTouchImg} className="img-fluid"></img>
              </div>
            </Modal.Footer> */}
          </Modal>
        </div>
        
      </>

    )
  }
}
