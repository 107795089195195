
import React, { Component } from 'react'
import BreadCrumb from '../Components/BreadCrumb';
import i18next from 'i18next';
import IsBirlikleriImgCard from '../Components/IsBirlikleriImgCard';
import Slider from "react-slick";
import ProductCard from '../Components/ProductCard';
import ApiBase from '../lib/ApiBase';
import Modal from 'react-bootstrap/Modal';
import KeepInTouchImg from '../Assets/Image/KeepInTouch__Img.svg';
import Sprite from '../Assets/Icons/sprite.svg';
import KeepInTouch from '../Components/KeepInTouch';
import { inject, observer } from 'mobx-react';

const IsBirlikleriDetay = observer(class IsBirlikleriDetay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            favoriteList: [],
            isFavorited: -1,
            cardList: [
                {
                    id: 1,
                    Photo: 'https://dummyimage.com/500x700/807680/0f0f12',
                    Title: 'Muum',
                    text: 'Eget sagittis pharetra, at duis vitae ultrices. Ornare in tellus risus at lorem condimentum blandit. Ac sed fringilla quisque nec, urna ut platea volutpat bibendum. Metus, magna auctor pretium sed turpis porta maecenas. Nulla bibendum id maecenas et id ultricies sollicitudin at. Est, semper egestas mi facilisi eget integer erat.'
                }
            ], productList: [
                {
                    ProductId: 1,
                    CategoryId: 1,
                    ProductName: "LePetitChiquitoMiniBag",
                    Brand: 'Zara',
                    Discount: true,
                    DiscountAmount: '%12',
                    Price: '99.99 TL',
                    ShowPrice: '56.55 TL',
                    Photo: 'http://via.placeholder.com/384x490',
                    IsFavorite: false
                },
                {
                    ProductId: 2,
                    CategoryId: 1,
                    ProductName: "Maui Dress",
                    Brand: 'Bottega Veneta',
                    Discount: false,
                    DiscountAmount: '%12',
                    Price: '99.99 TL',
                    ShowPrice: '56.55 TL',
                    Photo: 'http://via.placeholder.com/384x490',
                    IsFavorite: true
                },
                {
                    ProductId: 3,
                    CategoryId: 1,
                    ProductName: "The Pouch Bag",
                    Brand: 'Jacquemus',
                    Discount: true,
                    DiscountAmount: '%12',
                    Price: '99.99 TL',
                    ShowPrice: '56.55 TL',
                    Photo: 'http://via.placeholder.com/384x490',
                    IsFavorite: false
                },
                {
                    ProductId: 4,
                    CategoryId: 1,
                    ProductName: "Grey Stainless Steel Qua...",
                    Brand: 'Hip + Happen',
                    Discount: false,
                    DiscountAmount: '%12',
                    Price: '99.99 TL',
                    ShowPrice: '56.55 TL',
                    Photo: 'http://via.placeholder.com/384x490',
                    IsFavorite: true
                },
                {
                    ProductId: 5,
                    CategoryId: 1,
                    ProductName: "5",
                    Brand: 'Zara',
                    Discount: true,
                    DiscountAmount: '%12',
                    Price: '99.99 TL',
                    ShowPrice: '56.55 TL',
                    Photo: 'http://via.placeholder.com/384x490',
                    IsFavorite: false
                },
                {
                    ProductId: 6,
                    CategoryId: 1,
                    ProductName: "6",
                    Brand: 'Zara',
                    Discount: false,
                    DiscountAmount: '%12',
                    Price: '99.99 TL',
                    ShowPrice: '56.55 TL',
                    Photo: 'http://via.placeholder.com/384x490',
                    IsFavorite: false
                },
                {
                    ProductId: 7,
                    CategoryId: 1,
                    ProductName: "7",
                    Brand: 'asdasd',
                    Discount: true,
                    DiscountAmount: '%12',
                    Price: '99.99 TL',
                    ShowPrice: '56.55 TL',
                    Photo: 'http://via.placeholder.com/384x490',
                    IsFavorite: false
                },
                {
                    ProductId: 8,
                    CategoryId: 1,
                    ProductName: "8",
                    Brand: 'asdasd',
                    Discount: true,
                    DiscountAmount: '%12',
                    Price: '99.99 TL',
                    ShowPrice: '56.55 TL',
                    Photo: 'http://via.placeholder.com/384x490',
                    IsFavorite: false
                }

            ],

            RecommendedProducts: [],
            favoriteIds: [],
            showErrorModal: false,
            errorMessage: "",
            CollaborationDetailData: [],
            CollaborationProduct: []
        };
    }
    componentDidMount() {
        this.getCollaborationDetail();
    }

    getCollaborationDetail = () => {
        this.setState({ detailLoading: true })
        ApiBase.Post({
            url: '/get-collaboration-detail',
            data: {
                Take: 10,
                CollaborationId: this.props.match.params.isbirlikleriId
            },
            successFunction: (e) => {
                console.log("send contact ", e.data.data)
                this.setState({
                    CollaborationDetailData: e.data.data.collaborationData,
                    CollaborationProduct: e.data.data.designerProducts
                })

            },
            errorFunction: (e) => {
                // this.setState({ SendLoading: false, showErrorModal: true, errorMessage: e.data.message })
                console.log("layouterror", e.data.message)
            }
        })
    }

    toggleFavorite = (item) => {
        const i = this.state.favoriteList.indexOf(item.Id)

        if (i >= 0) {
            this.state.favoriteList.splice(i, 1)
            // console.log("i 0 dan büyük",this.state.favoriteList)
        } else {
            this.state.favoriteList.push(item.Id)
            // console.log("i 0 dan küçük", this.state.favoriteList)
        }
        this.setState({ favoriteList: this.state.favoriteList });
        // // console.log(this.state.favoriteList)
        // console.log("iiii", i)
    };
    ///favoriekle
    AddFavorite = (item, ProductId) => {
        this.setState({ loading: true })
        var _isLogin = localStorage.getItem("isLogin");
        var cUser = [];
        if (_isLogin === "true") {
            cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
        }
        ApiBase.Post({
            url: '/add-favourite',
            data: {
                ProductId: ProductId,
                CurrentUser: cUser
            },
            successFunction: (e) => {
                // console.log("favoriekle", e.data)
                this.getFavoriteIds();
                // this.toggleFavorite(item)
            },
            errorFunction: (e) => {
                this.setState({ showErrorModal: true, errorMessage: e.data.message })
                // console.log(e.data.message)
                if (e.data.message === "Ürün, favori ürünleriniz arasında bulunmaktadır.") {
                    // this.toggleFavorite(item)
                }
            }
        })
    }
    removeFavorites = (ProductId) => {
        this.setState({ actLoading: true });
        var _isLogin = localStorage.getItem("isLogin");
        var cUser = [];
        if (_isLogin === "true") {
            cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
        }


        ApiBase.Post({
            url: '/delete-favourite',
            data: {
                ProductId: ProductId,
                CurrenUser: cUser
            },
            successFunction: (e) => {
                // console.log("remove", e.data)

                this.getFavoriteIds();
                // this.setState({ loading: false })
            },
            errorFunction: (e) => {
                // console.log(e.data.message)
                this.setState({ actLoading: false, showErrorModal: true, errorMessage: e.data.message });

            }
        })
    }
    //////

    getFavoriteIds = () => {
        var _isLogin = localStorage.getItem("isLogin");
        var _UserId = null;
        if (_isLogin === "true") {
            var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
            _UserId = cUser.UserId
        }
        var favIdList = [];
        ApiBase.Post({
            url: '/get-favorite-ids',
            data: {

                UserId: _UserId,

            },
            successFunction: (e) => {
                // console.log("favoriteıds", e.data.data.List)
                e.data.data.List.map(item =>
                    favIdList.push(item.ProductId));
                this.setState({ favoriteIds: favIdList })
                //this.setState({ CartList: e.data.data.List, CartTotalPriceStr: e.data.data.TotalPriceStr })
            },
            errorFunction: (e) => {

                // console.log("hata", e.data.message)
            }
        })
    }
    render() {
        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: this.state.CollaborationProduct.length < 5 ? this.state.CollaborationProduct.length : 5,
            slidesToScroll: 5,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 2000,



            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        arrows: false,
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true,
                        autoplaySpeed: 3000,

                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        arrows: false,
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        dots: true,
                        autoplaySpeed: 3000,


                    }
                },

            ],




        };
        const { history } = this.props;
        const lang = localStorage.getItem("lang")
        const item = this.state.cardList[0];

        const cardList = this.state.cardList.map((item, index) => {
            return (
                <IsBirlikleriImgCard item={item} />
            )
        });
        const ListItem = this.state.CollaborationProduct.map((pitem) => {
            return (
                <li key={pitem.ProductId} >
                    <ProductCard item={pitem} _className={this.state.favoriteIds.indexOf(pitem.Id) >= 0 ? "product-card__like-button active" : "product-card__like-button"} toggleFavorite={() => { this.state.favoriteIds.indexOf(pitem.Id) >= 0 ? this.removeFavorites(pitem.Id) : this.AddFavorite(pitem, pitem.Id) }} ></ProductCard>
                </li>
            );
        });


        return (
            <div className="isBirlikleriDetay">
                <div className="container">
                    <BreadCrumb title={i18next.t("NewCollaboration")} titleLink={"/isbirlikleri"} path={this.state.CollaborationDetailData.DesignerName} />
                    <div className="BlogAndMagazine__blog">
                        <div className="row">
                            <div className=" col-lg-6 BlogAndMagazine__Description">
                                <div className="BlogAndMagazine__toptittle"> Meet The Designer</div>
                                <p className="BlogAndMagazine__tittle text-uppercase">
                                    {this.state.CollaborationDetailData.DesignerName}
                                </p>
                                <p className="BlogAndMagazine__text" dangerouslySetInnerHTML={{ __html: this.state.CollaborationDetailData.Description }}>
                                    { }
                                </p>

                            </div>
                            <div className="col-lg-6">
                                <div className="BlogAndMagazine__Img">
                                    <img src={this.state.CollaborationDetailData.CoverPhoto} className="img-fluid" alt={item.DesignerName}></img>

                                </div>
                            </div>
                        </div>
                    </div>
                    <h5 className="container text-center h-size-4 mt-4">
                        TASARIMCIYA AİT ÜRÜNLER

                    </h5>
                    <div className="container  product-card--pd">
                        <div className="product-card">
                            <Slider {...settings}>
                                {ListItem}
                            </Slider>
                        </div>
                    </div>
                    <div className="container">
                        <div className=" row mb-5 ">
                            <div className="col-lg-6 mx-auto">
                                <div className="col-lg-7 mx-auto">
                                    <div className="slider__button">
                                        <a onClick={() => { this.props.history.push(`/designers/${this.state.CollaborationDetailData.DesignerName}/${this.state.CollaborationDetailData.DesignerId}`) }}>{i18next.t("SeeAll")}</a> </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <KeepInTouch />


                </div>
                <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showErrorModal} onHide={() => this.setState({ showErrorModal: false })}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <h4 className="LoginModal__title text-center">{i18next.t('UnSuccessful')}</h4>
                    <p></p>
                    <Modal.Body>
                        <div className="text-center mb-3">
                            <svg className="iconSize-md" >
                                <use href={Sprite + "#sad-face"} ></use>
                            </svg >
                        </div>

                        <form className="LoginModal  ">
                            <p className="text-center  p-size-1">{this.state.errorMessage}</p>
                            <div className="text-center  w-50  mx-auto">
                                <a onClick={() => this.setState({ showErrorModal: false })} className="submit-button">{i18next.t('OK')}</a>
                            </div>
                        </form>
                    </Modal.Body>
                    {/* <Modal.Footer>
                            <div className="KeepInTouch__Img ">
                                <img src={KeepInTouchImg} className="img-fluid"></img>
                            </div>
                        </Modal.Footer> */}
                </Modal>
            </div>
        )
    }
})
export default inject((stores) => ({
    Store: stores.Store,
}))(IsBirlikleriDetay);
