import React, { Component } from 'react';
import CreditCardLogo from '../Assets/Image/creditCardLogo.svg'
import i18next from 'i18next';
export default class PaymentCard extends Component {
    render() {
        const { item } = this.props;
        return (
            <div className="paymentCard" >
                <div className="mb-4">
               
                  
                    <p className="d-inline pl-2"> {item.PaymentTypeStr}</p>
                </div>

                <div className="d-flex justify-content-between">
                    <p>{i18next.t("TotalPrice")}:</p>
                    <p>{item.TotalProductPriceStr} ₺</p>
                </div>
                <div className="d-flex justify-content-between">
                    <p>{i18next.t("Cargo")}:</p>
                    <p>{item.CargoPriceStr} ₺ </p>
                </div>
                <div className="d-flex justify-content-between">
                    <p className="paymentCard--bold">{i18next.t("GrandTotal")}:</p>
                    <p className="paymentCard--bold">{item.PriceStr} ₺</p>
                </div>
            </div>
        )
    }
}
