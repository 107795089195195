
import React, { Component } from 'react';
import BreadCrumb from '../Components/BreadCrumb';
import KeepInTouch from '../Components/KeepInTouch';
import i18next from 'i18next';
import Sprite from '../Assets/Icons/sprite.svg';
import Form from 'react-bootstrap/Form';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import ApiBase from '../lib/ApiBase';
import Modal from 'react-bootstrap/Modal';
import KeepInTouchImg from '../Assets/Image/KeepInTouch__Img.svg';
export default class MusteriHizmetleri extends Component {
    constructor(props) {
        super(props);
        this.state = {
            LayoutInfo: [],
            Map: "",
            NameSurname: "",
            Email: "",
            Phone: "",
            Message: "",
            TermsOfUse: false,
            Subject: "",
        }
    }
    componentDidMount() {
        this.getLayoutInfo();
    }
    getLayoutInfo = () => {
        var Lang = localStorage.getItem("lang")
        var LangId = Lang === "tr" ? 1 : 2;
        ApiBase.Post({
            url: '/get-layout-info',
            data: {

            },
            successFunction: (e) => {
                console.log("layout info ", e.data.data)
                this.setState({
                    LayoutInfo: e.data.data,
                    Map: e.data.data.Map
                });
            },
            errorFunction: (e) => {

                console.log("layouterror", e.data.message)
            }
        })
    };

    sendContactForm = () => {
        if (this.state.TermsOfUse != true) {
            this.setState({ showErrorModal: true, errorMessage: "Lütfen Kişisel Verilerin Korunması Kanunu'nu okuyup kabul ediniz." })
        } else {
            this.setState({ SendLoading: true });
            ApiBase.Post({
                url: '/add-contact-form',
                data: {
                    NameSurname: this.state.NameSurname,
                    Email: this.state.Email,
                    Phone: "+90" + this.state.Phone,
                    Message: this.state.Message,
                    Subject: this.state.Subject
                },
                successFunction: (e) => {
                    console.log("send contact ", e)
                    this.setState({ SendLoading: false, showSuccessModal: true, successMessage: e.data.message })

                },
                errorFunction: (e) => {
                    this.setState({ SendLoading: false, showErrorModal: true, errorMessage: e.data.message })
                    console.log("layouterror", e.data.message)
                }
            })
        }
    }

    handleChangeSubject = (option) => {
        this.setState({ Subject: option.value })
    }


    render() {
        const customStyles = {

            option: (provided, state) => ({
                ...provided,
                fontSize: 12,
                color: 'black',
                boxShadow: 'none',
                backgroundColor: "transparent",

                '&:hover': {
                    backgroundColor: "#E5E5E5",
                    boxShadow: 'none',

                },
                '&:focus': {
                    backgroundColor: "#E5E5E5",
                    boxShadow: 'none',
                },
            }),
            control: (provided, state) => ({
                ...provided,
                paddingTop: 0,
                paddingBottom: 10,
                borderWidth: 0,
                borderBottomWidth: "1px",
                borderColor: '#EDE8DE!important',
                backgroundColor: "transparent",
                borderRadius: 0,
                outline: "transparent",
                boxShadow: '0 !important',
                '&:focus': {
                    borderColor: "#CEC2A6!important",
                    boxShadow: 'none',

                    '&:hover': {
                        borderColor: "#CEC2A6!important",
                        boxShadow: 'none!important',


                    },
                },

            }),

            ValueContainer: (provided, state) => ({
                ...provided,
                padding: '0 6px',
                '&:focus': {
                    borderColor: "#EDE8DE",
                    boxShadow: 'none',
                },
                '&:hover': {
                    borderColor: "#CEC2A6",
                    boxShadow: 'none',

                },

            }),

            container: (provided) => ({
                ...provided,
                //  backgroundColor:'red',
                '&:focus': {
                    borderColor: "#CEC2A6",

                },
                '&:hover': {
                    borderColor: "#CEC2A6",

                },

            }),

            dropdownIndicator: (provided) => ({
                ...provided,
                //  backgroundColor:'red',
                color: '#CEC2A6',
                borderLeft: 0

            }),
            indicatorSeparator: (provided) => ({
                ...provided,
                width: 0
            }),
            placeholder: (provided) => ({
                ...provided,
                color: '#E5E5E5',
            })


        }
        const options = [
            { value: "İptal İade", label: 'İptal & İade' },
            { value: "Ürün Problemi", label: 'Ürün Problemi' },
            { value: "Yeni Marka", label: 'Yeni Marka' },
            { value: "Diğer", label: 'Diğer' },


        ]
        return (
            <>
                <BreadCrumb className="mb-4" path={i18next.t("CustomerService")}></BreadCrumb>
                <div className="MusteriHizmetleri">
                    <div className="container">
                        <div className="MusteriHizmetleri__body">
                            <div className="row  py-4">
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div className="col-lg-12 mx-auto text-center divContactMarginTitle">
                                            <p className="MusteriHizmetleri__title">
                                                {i18next.t("ExpectToVisit")}
                                            </p>
                                            <p className="MusteriHizmetleri__text">
                                                {this.state.LayoutInfo.Address}
                                            </p>
                                        </div>
                                        <div className="col-lg-12 mx-auto text-center divContactMarginTitle">
                                            <p className="MusteriHizmetleri__title">
                                                {i18next.t("CallUs")}
                                            </p>
                                            <p className="MusteriHizmetleri__text ">
                                                {this.state.LayoutInfo.Phone}
                                            </p>
                                        </div>
                                        <div className="col-lg-12 mx-auto text-center divContactMarginTitle">
                                            <p className="MusteriHizmetleri__title">
                                                {i18next.t("WriteUs")}
                                            </p>
                                            <p className="MusteriHizmetleri__text ">
                                                {this.state.LayoutInfo.Email}
                                            </p>
                                        </div>

                                    </div>
                                    {/* <p className="MusteriHizmetleri__title">
                                    {i18next.t("FollowUs")}
                                </p> */}
                                    <p className="MusteriHizmetleri__text" style={{ marginTop: 50 }}>
                                        <div className="row">
                                            <div className="col-lg-6 mx-auto">
                                                <div className="socialMediaTags">
                                                    <ul className="d-flex justify-content-lg-around flex-wrap">
                                                        <a href={this.state.LayoutInfo.Facebook} target="_blank" >
                                                            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M14.7445 9.62817V6.53113C14.7445 5.67945 15.4414 4.98262 16.293 4.98262H17.8416V1.11133H14.7445C12.1766 1.11133 10.099 3.18892 10.099 5.75688V9.62817H7.00195V13.4995H10.099V25.8876H14.7445V13.4995H17.8416L19.3901 9.62817H14.7445Z" fill="#222222" />
                                                            </svg>
                                                        </a>
                                                        <a href={this.state.LayoutInfo.Twitter} target="_blank" >
                                                            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M24.7638 6.61153C23.9239 6.98153 23.0312 7.22233 22.0974 7.3398C23.0547 6.7701 23.7888 5.86564 24.1295 4.78498C23.2368 5.31943 22.2501 5.69532 21.1988 5.90675C20.3531 5.00228 19.1432 4.44434 17.8217 4.44434C15.261 4.44434 13.1996 6.52343 13.1996 9.0665C13.1996 9.43063 13.2289 9.78302 13.3053 10.1237C9.47012 9.93572 6.06369 8.09156 3.77904 5.29007C3.37967 5.97723 3.15061 6.7701 3.15061 7.62171C3.15061 9.22507 3.97285 10.6405 5.20621 11.4627C4.4662 11.451 3.73205 11.2337 3.11537 10.893C3.11537 10.9048 3.11537 10.9283 3.11537 10.9459C3.11537 13.1953 4.71874 15.0571 6.82133 15.4917C6.44545 15.5974 6.03433 15.6444 5.61146 15.6444C5.3178 15.6444 5.01827 15.6268 4.73636 15.5681C5.33542 17.4005 7.03276 18.7454 9.059 18.7866C7.48499 20.0199 5.48812 20.7599 3.32681 20.7599C2.94505 20.7599 2.58679 20.7423 2.22266 20.6953C4.26064 22.0168 6.68625 22.7685 9.30567 22.7685C17.8159 22.7685 22.4674 15.7208 22.4674 9.6127C22.4674 9.40714 22.4615 9.21333 22.4498 9.01364C23.366 8.36172 24.1354 7.55123 24.7638 6.61153Z" fill="#222222" />
                                                            </svg>

                                                        </a>
                                                        <a href={this.state.LayoutInfo.Instagram} target="_blank" >
                                                            <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.83507 2.22217H18.1684C22.0052 2.22217 25.1128 5.32981 25.1128 9.16661V17.4999C25.1128 21.3368 22.0052 24.4444 18.1684 24.4444H9.83507C5.99826 24.4444 2.89062 21.3368 2.89062 17.4999V9.16661C2.89062 5.32981 5.99826 2.22217 9.83507 2.22217ZM18.1684 22.3611C20.8478 22.3611 23.0295 20.1793 23.0295 17.4999V9.16661C23.0295 6.48721 20.8478 4.3055 18.1684 4.3055H9.83504C7.15564 4.3055 4.97392 6.48721 4.97392 9.16661V17.4999C4.97392 20.1793 7.15564 22.3611 9.83504 22.3611H18.1684Z" fill="#222222" />
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.44531 13.3334C8.44531 10.2663 10.9337 7.77783 14.0009 7.77783C17.068 7.77783 19.5564 10.2663 19.5564 13.3334C19.5564 16.4005 17.068 18.8889 14.0009 18.8889C10.9337 18.8889 8.44531 16.4005 8.44531 13.3334ZM10.5286 13.3334C10.5286 15.2489 12.0853 16.8056 14.0008 16.8056C15.9163 16.8056 17.473 15.2489 17.473 13.3334C17.473 11.4179 15.9163 9.86117 14.0008 9.86117C12.0853 9.86117 10.5286 11.4179 10.5286 13.3334Z" fill="#222222" />
                                                                <path d="M20.7159 7.36086C20.7159 7.77174 20.386 8.1016 19.9751 8.1016C19.5642 8.1016 19.2344 7.77174 19.2344 7.36086C19.2344 6.94998 19.5642 6.62012 19.9751 6.62012C20.386 6.62012 20.7159 6.94998 20.7159 7.36086Z" fill="#222222" />
                                                            </svg>

                                                        </a>
                                                        <a href={this.state.LayoutInfo.Youtube} target="_blank">
                                                            <svg width="25" height="17" viewBox="0 0 25 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.3428 0.691244C21.7664 0.771359 22.4628 0.980892 23.1037 2.121C23.7693 3.2611 24.1082 5.22701 24.1021 8.69046V8.69662V8.70278C24.1021 12.1477 23.7631 14.126 23.0975 15.2538C22.4566 16.3939 21.7602 16.5972 20.3428 16.6958C18.9254 16.7759 15.3633 16.8253 12.2758 16.8253C9.1821 16.8253 5.62005 16.7759 4.20262 16.6958C2.78519 16.6034 2.08881 16.3939 1.44172 15.2538C0.782309 14.126 0.443359 12.1477 0.443359 8.70278V8.69662V8.69046V8.68429C0.443359 5.22701 0.782309 3.2611 1.44172 2.121C2.09497 0.980892 2.79136 0.771359 4.20262 0.691244C5.62005 0.59264 9.1821 0.555664 12.2758 0.555664C15.3633 0.555664 18.9254 0.59264 20.3428 0.691244ZM16.7129 8.69049L9.31763 4.25332V13.1276L16.7129 8.69049Z" fill="#222222" />
                                                            </svg>

                                                        </a>
                                                        <a href={this.state.LayoutInfo.LinkedIn} target="_blank" >
                                                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4.49639 6.10352H0.445312V18.5539H4.49639V6.10352Z" fill="#222222" />
                                                                <path d="M15.5281 6.24955C15.4857 6.2354 15.4432 6.22125 15.4008 6.2071C15.3442 6.19295 15.2923 6.18352 15.2357 6.17409C15.0188 6.13165 14.783 6.09863 14.5095 6.09863C12.1467 6.09863 10.6517 7.81527 10.1565 8.48023V6.10335H6.10547V18.5537H10.1565V11.7626C10.1565 11.7626 13.2173 7.4993 14.5095 10.6307C14.5095 13.4274 14.5095 18.5537 14.5095 18.5537H18.5558V10.1497C18.5558 8.27273 17.2683 6.70229 15.5281 6.24955Z" fill="#222222" />
                                                                <path d="M4.40678 2.42507C4.40678 3.51919 3.52017 4.40581 2.42605 4.40581C1.33193 4.40581 0.445312 3.51919 0.445312 2.42507C0.445312 1.33095 1.33193 0.444336 2.42605 0.444336C3.52017 0.444336 4.40678 1.33095 4.40678 2.42507Z" fill="#222222" />
                                                            </svg>

                                                        </a>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </p>

                                </div>
                                <div className="col-lg-6 MusteriHizmetleri__border">
                                    <div className="divCategoryName mt-3">
                                        BİZE ULAŞIN
                                    <div className="divHomeBlackLine"></div>
                                    </div>
                                    <Form className="iletisim px-lg-5 ">
                                        <div className="divFormSubTitle">{i18next.t("NameSurname")}</div>
                                        <input type="text" id="name" className="form-control divFromInput" required value={this.state.NameSurname} onChange={(e) => this.setState({ NameSurname: e.target.value })} />
                                        <div className="divFormSubTitle">{i18next.t("EmailAdress")}</div>
                                        <input type="mail" id="emailadress" className="form-control divFromInput" required value={this.state.Email} onChange={(e) => this.setState({ Email: e.target.value })} />
                                        <div className="divFormSubTitle">{i18next.t("PhoneNumber")}</div>
                                        <InputMask mask="(999) 999 99 99"
                                            className="c-input form-control divFromInput"
                                            maskChar={null}
                                            placeholder
                                            type="tel"
                                            id="telno"
                                            value={this.state.Phone}
                                            onChange={(e) => { this.setState({ Phone: e.target.value }) }}
                                            required>
                                        </InputMask>
                                        <div className="divFormSubTitle">{i18next.t("Subject")}</div>
                                        <div className="form-control divFromInput">
                                            <Select
                                                placeholder={i18next.t("Subject")}
                                                styles={customStyles}
                                                onChange={this.handleChangeSubject}
                                                options={options}
                                            >
                                            </Select>
                                        </div>
                                        <div className="divFormSubTitle">{i18next.t("YourMessage")}</div>
                                        <textarea
                                            placeholder={i18next.t("YourMessage")}
                                            value={this.state.Message}
                                            onChange={(e) => { this.setState({ Message: e.target.value }) }}
                                            className="w-100 form-control divFromInput"
                                            rows="3"
                                            required >
                                        </textarea>
                                        <div className="form-check conditions">
                                            <input type="checkbox" class="form-check-input" id="conditions" onChange={() => { this.setState({ TermsOfUse: !this.state.TermsOfUse }, () => { console.log(this.state.TermsOfUse) }) }} />
                                            <label class="form-check-label" htmlFor="conditions">
                                                <span className="p-size-2 p-size--color"><a href="/privacy-policy" className="text-dark"> {i18next.t("ProtectPersonalData")} </a>  {i18next.t("ContractText2")} </span>
                                            </label>
                                        </div>
                                        <div className="row mt-4 mb-4 mx-0">
                                            <div className="col-lg-12  p-0 mx-auto ">
                                                <a className={this.state.SendLoading === true ? "submit-button" : "submit-button"} onClick={this.sendContactForm} >{i18next.t("Send")}</a>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="divMapContainer">
                        <iframe
                            // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2274.1201499492954!2d27.17880288530369!3d38.452192276348214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b962af25ba9cdd%3A0x4a75346417a5f120!2zUFIgfCBZYXrEsWzEsW0!5e0!3m2!1str!2str!4v1605014795227!5m2!1str!2str"
                            src={this.state.LayoutInfo.Map}

                            style={{
                                width: '100%', height: 380, frameBorder: 0, allowfullscreen
                                    : "", ariaHidden: "false", tabindex: 0
                            }}
                        ></iframe>
                    </div>
                    <KeepInTouch />
                    {/* <KeepInTouch /> */}
                    <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showErrorModal} onHide={() => { this.setState({ showErrorModal: false }) }}>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <h4 className="LoginModal__title text-center">{i18next.t('UnSuccessful')}</h4>
                        <p></p>
                        <Modal.Body>
                            <div className="text-center mb-3">
                                <svg className="iconSize-md" >
                                    <use href={Sprite + "#sad-face"} ></use>
                                </svg >
                            </div>
                            <form className="LoginModal  ">
                                <p className="text-center  p-size-1">{this.state.errorMessage}</p>
                                <div className="text-center  w-50  mx-auto">
                                    <a onClick={() => this.setState({ showErrorModal: false })} className="submit-button">{i18next.t('TurnBack')}</a>
                                </div>
                            </form>
                        </Modal.Body>
                        {/* <Modal.Footer>
                            <div className="KeepInTouch__Img ">
                                <img src={KeepInTouchImg} className="img-fluid"></img>
                            </div>
                        </Modal.Footer> */}
                    </Modal>
                    <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showSuccessModal} onHide={() => { this.setState({ showSuccessModal: false }) }}>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <h4 className="LoginModal__title text-center"> {i18next.t("ProcessSuccess")}</h4>
                        <p></p>
                        <Modal.Body>
                            <div className="text-center mb-3">
                                <svg className="iconSize-md" >
                                    <use href={Sprite + "#smile"} ></use>
                                </svg >
                            </div>
                            <form className="LoginModal  ">
                                <p className="text-center p-size-1"> {this.state.successMessage}</p>
                                <div className="text-center  w-50  mx-auto">
                                    <button onClick={() => this.props.history.push('/')} className="submit-button">{i18next.t('Home')}</button>
                                </div>
                            </form>
                        </Modal.Body>
                        {/* <Modal.Footer>
                            <div className="KeepInTouch__Img ">
                                <img src={KeepInTouchImg} className="img-fluid"></img>
                            </div>
                        </Modal.Footer> */}
                    </Modal>
                </div>
            </>
        )
    }
}


