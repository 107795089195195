/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import BreadCrumb from '../Components/BreadCrumb';
import ProfilDetailCard from '../Components/ProfilDetailCard';
import i18next from 'i18next';
import UserDetailCardMobil from '../Components/UserDetailCardMobil';
import ApiBase from '../lib/ApiBase';
import { inject, observer } from 'mobx-react';
import KeepInTouchImg from '../Assets/Image/KeepInTouch__Img.svg';
import Modal from 'react-bootstrap/Modal';
import Sprite from '../Assets/Icons/sprite.svg';
var cUser = [];
const Profilim = observer(class Profilim extends Component {


  constructor(props) {
    super(props);
    this.state = {
      changePassword: false,
      pageName: 'PROFILE',
      NameSurname: "",
      Email: "",
      Phone: "",
      OldPassword: "",
      NewPassword: "",
      ReNewPassword: "",
      showSuccesModal: false,
      showErrorModal: false,
      errorMessage: "",
    };

  }

  changePassword = () => {
    this.setState({ changePassword: true })
  }
  confirmChangePassword = () => {
    this.setState({ changePassword: false })
  }
  componentDidMount() {
    cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
    // console.log(cUser)

    this.setState({
      NameSurname: cUser.NameSurname,
      Email: cUser.Email,
      Phone: cUser.Phone
    })
  };

  changePersonalInfo = () => {
    this.setState({ actLoading: true })
    ApiBase.Post({
      url: '/change-personal-information',
      data: {
        NameSurname: this.state.NameSurname,
        Phone: this.state.Phone,
        CurrenUser: JSON.stringify(cUser)
      },
      successFunction: (e) => {
        // console.log("asdasdada", e.data)
        cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
        this.setState({ actLoading: false, showSuccesModal: true });
        // this.setState({ loading: false })
        this.props.Store.CurrentUserStore.ReloadUser(cUser.UserId)
      },
      errorFunction: (e) => {
        // console.log(e.data.message)
        this.setState({ actLoading: false, showErrorModal: true, errorMessage: e.data.message });

      }
    })

  }

  updateMyPassword = () => {
    this.setState({ actLoading: true })

    ApiBase.Post({
      url: '/update-my-password',
      data: {
        OldPassword: this.state.OldPassword,
        Password: this.state.NewPassword,
        RePassword: this.state.ReNewPassword,

      },
      successFunction: (e) => {
        // console.log(e.data)
        this.setState({ actLoading: false, showSuccesModal: true, changePassword: false });
        // this.setState({ loading: false })
      },
      errorFunction: (e) => {
        // console.log(e.data)
        this.setState({ actLoading: false, showErrorModal: true, errorMessage: e.data.message, changePassword: false });

      }
    })

  }

  render() {

    return (
      <>
            <BreadCrumb hesabim path={i18next.t("MyProfile")}></BreadCrumb>
        <div className="UserDetail">
          <div className="container">

            <div className="row  UserDetail__Profilim">
              <div className="col-lg-3 d-none d-lg-block UserDetail__section1">
                <ProfilDetailCard className1={"bg--secondary"}></ProfilDetailCard>
              </div>
              <div className="col-lg-3 d-lg-none UserDetail__section1">
                <UserDetailCardMobil active1></UserDetailCardMobil>
              </div>
              <div className="col-lg-5 UserDetail__section2">
              <div className="divProfileDetailTitle">Üyelik Bilgilerim</div>
                <Form className="iletisim">
                  <div className="divFormSubTitle">İsim Soysisim</div>
                  <input type="text" className="form-control divFromInput" id="name" required value={this.state.NameSurname} onChange={(e) => this.setState({ NameSurname: e.target.value }, () => { console.log(this.state.NameSurname) })} />
                  <div className="divFormSubTitle">Email</div>
                  <input type="mail" className="form-control divFromInput" id="emailadress" required value={this.state.Email} onChange={(e) => this.setState({ Email: e.target.value })} />
                  <div className="divFormSubTitle">Telefon</div>
                  <input type="tel" className="form-control divFromInput" id="telno" required value={this.state.Phone} onChange={(e) => this.setState({ Phone: e.target.value })} />
                  <div class="mb-4">
                    {/* <div className="selectCountry">
                    <Form.Group controlId="exampleForm.ControlSelect1">

                      <Form.Control as="select">
                        <option className="selected">{i18next.t("SelectCountry")}</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Form.Control>
                    </Form.Group>
                  </div> */}

                  </div>
                  {this.state.changePassword !== true ?
                    <>
                      <div className="row mb-2 ">
                        <div className="col-lg-10  p-0 mx-auto">
                          <a onClick={this.changePersonalInfo} className="submit-button">{i18next.t("SaveAllChanges")}</a>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-10 p-0 mx-auto">
                          <a href="#" onClick={this.changePassword} className="submit-button">{i18next.t("ChangePassword")}</a>
                        </div>
                      </div>
                    </>
                    :
                    <></>}
                </Form>
              </div>
              {this.state.changePassword === true ?
                <div className="col-lg  UserDetail__section3" >
                  <div className="divUyelikBilgi">ŞİFRE</div>
                  <Form className="iletisim">
                    <div className="divFormSubTitle">Eski Şifre</div>
                    <input type="password" className="form-control divFromInput" id="user_password" required value={this.state.OldPassword} onChange={(e) => this.setState({ OldPassword: e.target.value })} />
                    <div className="divFormSubTitle">{i18next.t("NewPassword")}</div>
                    <input type="password" className="form-control divFromInput" id="userNew_password" required value={this.state.NewPassword} onChange={(e) => this.setState({ NewPassword: e.target.value })} />
                    <div className="divFormSubTitle">{i18next.t("NewPasswordAgain")}</div>
                    <input type="password" className="form-control divFromInput" id="userNewAgain_password" required value={this.state.ReNewPassword} onChange={(e) => this.setState({ ReNewPassword: e.target.value })} />
                    <div className="row">
                      <div className="col-lg-10 p-0 mx-auto">
                        <a onClick={this.updateMyPassword} className="submit-button">{i18next.t("ChangePassword")}</a>
                      </div>
                    </div>
                  </Form>

                </div>
                : <></>}
            </div >
          </div>
          <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showSuccesModal} onHide={() => { this.setState({ showSuccesModal: false }) }}>
            <Modal.Header closeButton>
            </Modal.Header>
            <h4 className="LoginModal__title text-center"> {i18next.t("ProcessSuccess")}</h4>
            <p></p>
            <Modal.Body>
              <div className="text-center mb-3">
                <svg className="iconSize-md" >
                  <use href={Sprite + "#smile"} ></use>
                </svg >
              </div>
              <form className="LoginModal  ">
                <p className="text-center p-size-1"> {i18next.t("SuccessMessage")}</p>
                <div className="text-center  w-50  mx-auto">
                  <a onClick={() => { this.setState({ showSuccesModal: false }) }} className="submit-button">{i18next.t('Back')}</a>
                </div>
              </form>
            </Modal.Body>
            {/* <Modal.Footer>
              <div className="KeepInTouch__Img ">
                <img src={KeepInTouchImg} className="img-fluid"></img>
              </div>
            </Modal.Footer> */}
          </Modal>
          <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showErrorModal} onHide={() => { this.setState({ showErrorModal: false }) }}>
            <Modal.Header closeButton>
            </Modal.Header>
            <h4 className="LoginModal__title text-center">{i18next.t('UnSuccessful')}</h4>
            <p></p>
            <Modal.Body>
              <div className="text-center mb-3">
                <svg className="iconSize-md" >
                  <use href={Sprite + "#sad-face"} ></use>
                </svg >
              </div>
              <form className="LoginModal  ">
                <p className="text-center  p-size-1">{this.state.errorMessage}</p>
                <div className="text-center  w-50  mx-auto">
                  <a onClick={() => this.setState({ showErrorModal: false })} className="submit-button">{i18next.t('OK')}</a>
                </div>
              </form>
            </Modal.Body>
            {/* <Modal.Footer>
              <div className="KeepInTouch__Img ">
                <img src={KeepInTouchImg} className="img-fluid"></img>
              </div>
            </Modal.Footer> */}
          </Modal>
        </div>
      </>
    )
  }
})
export default inject((stores) => ({
  Store: stores.Store,
}))(Profilim);
