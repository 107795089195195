import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import common_tr from './Translations/tr.json';
import common_en from './Translations/en.json';

const resources = {
  tr: {
    translation: common_tr
  },
  en: {
    translation: common_en
  }
}

i18n
.use(initReactI18next)
.init({
  resources,
  lng: 'tr',
  keySeparator:false,
  interpolation:{
    escapeValue:false
  }
});

export default i18n;