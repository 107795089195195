/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import BreadCrumb from '../Components/BreadCrumb';
import Form from 'react-bootstrap/Form';
import i18next from 'i18next';
import ApiBase from '../lib/ApiBase';
import InputMask from 'react-input-mask';
import ApplyDesignerPagePhoto from '../Assets/Image/designer-form.png';
import KeepInTouchImg from '../Assets/Image/KeepInTouch__Img.svg';
import Modal from 'react-bootstrap/Modal';
import Sprite from '../Assets/Icons/sprite.svg';

export default class ApplyDesigner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MainCategories: [],
      SelectedCategory: 0,
      PhoneNumber: "",
      NameSurname: "",
      Email: "",
      WebSiteUrl: "",
      CityList: [],
      DistrictList: [],
      DistrictLoading: false,
      CityLoading: false,
      SelectedCityId: 0,
      SelectedDistrictId: 0,
      showSuccesModal: false
    }

  }
  componentDidMount() {
    this.getMainCategories();
    this.getCityList();
  };

  getMainCategories = () => {
    this.setState({ loading: true })
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-main-categories',
      data: {
        LanguageId: LangId,
      },
      successFunction: (e) => {
        // console.log("kategoriler", e.data.data.categories)
        this.setState({ MainCategories: e.data.data.categories });
        // this.setState({ loading: false })
      },
      errorFunction: (e) => {
        this.setState({ loading: false })
        // console.log(e.data.message)
      }
    })
  };

  getCityList = () => {
    this.setState({ CityLoading: true });
    ApiBase.Post({
      url: '/get-city-list',
      data: {
        CountryId: 223,
      },
      successFunction: (e) => {
        //   console.log("şehirler", e.data.data)
        this.setState({ CityList: e.data.data.cities, CityLoading: false });

      },
      errorFunction: (e) => {
        this.setState({ CityLoading: false })
        // console.log(e.data.message)
      }
    })
  };

  getDistrictList = () => {
    this.setState({ DistrictLoading: true });
    ApiBase.Post({
      url: '/get-district-list',
      data: {
        CityId: this.state.SelectedCityId,
      },
      successFunction: (e) => {
        //  console.log("ilçeler", e.data.data)
        this.setState({ DistrictList: e.data.data.districts, DistrictLoading: false });

      },
      errorFunction: (e) => {
        this.setState({ DistrictLoading: false })
        // console.log(e.data.message)
      }
    })
  };


  onChangeCategory = (e) => {
    this.setState({ SelectedCategory: e.target.value }, () => { console.log(this.state.SelectedCategory) })
  };

  onChangeCity = (e) => {
    this.setState({ SelectedCityId: e.target.value }, () => {
      this.getDistrictList();
    })
  };

  onChangeDistrict = (e) => {
    this.setState({ SelectedDistrictId: e.target.value });

  };

  addDesigner = () => {
    this.setState({ addLoading: true })

    ApiBase.Post({
      url: '/add-designer-form',
      data: {
        NameSurname: this.state.NameSurname,
        Email: this.state.Email,
        Phone: this.state.PhoneNumber,
        CategoryId: this.state.SelectedCategory,
        Website: this.state.WebSiteUrl,
        CityId: this.state.SelectedCityId,
        DistrictId: this.state.SelectedDistrictId

      },
      successFunction: (e) => {

        this.setState({ addLoading: false, showSuccesModal: true });
        // this.setState({ loading: false })
      },
      errorFunction: (e) => {
        this.setState({ addLoading: false })
        alert(e.data.message)
      }
    })
  }
  hideLoginModal = () => {
    this.setState({ showSuccesModal: false })
  }
  render() {
    const categoryOptions = this.state.MainCategories.map((item) => {
      return (
        <option value={item.Id}  >{item.Name}</option>
      )
    });

    const cityOptions = this.state.CityList.map((item) => {
      return (
        <option value={item.CityId}> {item.City} </option>
      )
    })

    const districtOptions = this.state.DistrictList.map((item) => {
      return (
        <option value={item.DistrictId}>{item.District}</option>
      )
    })
    return (
      <div className="ApplyDesigner">
        <div className="container">
          <BreadCrumb title={i18next.t("About").toUpperCase()} titleLink={"/about"} path={i18next.t("ApplyDesigner")} />
          <h2 className="h-size-4 text-center mt-4">{i18next.t("JoinUs")}</h2>
          <div className="row mt-4 ">
            <div className="col-lg-10 mx-auto">
              <p className="p-size-1">Alanında profesyonel ve yeni tasarımcıları destekleyen misyonumuz ile siz değerli tasarımcıların ilham verici işlerini seçkimizde görmekten mutluluk duyuyoruz.
            <br></br><br></br>
                  Markanıza daha fazla tanınma imkanı ve alternatif bir satış noktası daha oluşturmak için aşağıdaki formu doldurabilirsiniz.
              </p>
            </div>
          </div>

          <div className=" ApplyDesigner__applyForm row d-flex align-items-center ">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-10">
                  <Form className="iletisim">
                    <div className=" mb-3 ">
                      <div className="input-field">
                        <input type="text" id="name" required value={this.state.NameSurname} onChange={(e) => this.setState({ NameSurname: e.target.value })} />
                        <label htmlFor="name">{i18next.t("NameSurname")}</label>
                      </div>
                    </div>
                    <div className=" mb-3">
                      <div className="input-field">
                        <input type="mail" id="emailadress" required value={this.state.Email} onChange={(e) => this.setState({ Email: e.target.value })} />
                        <label htmlFor="emailadress">{i18next.t("EmailAdress")}</label>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="input-field">
                        <InputMask mask="(999) 999 99 99"
                          className="c-input"
                          maskChar={null}
                          placeholder
                          type="tel"
                          id="telno"
                          value={this.state.PhoneNumber}
                          onChange={(e) => { this.setState({ PhoneNumber: e.target.value }) }}
                          required>

                        </InputMask>
                        <label htmlFor="telno">{i18next.t("PhoneNumber")}</label>
                      </div>
                    </div>
                    <div class="mb-4">

                      <div className="selectCountry">
                        <Form.Group controlId="exampleForm.ControlSelect1">

                          <Form.Control as="select" onChange={this.onChangeCategory} value={this.state.SelectedCategory} >
                            <option className="selected">{i18next.t("Categories")}</option>
                            {categoryOptions}
                          </Form.Control>
                        </Form.Group>
                      </div>

                    </div>

                    <div className=" mb-3 ">
                      <div className="input-field">
                        <input type="text" id="website" required value={this.state.WebSiteUrl} onChange={(e) => this.setState({ WebSiteUrl: e.target.value })} />
                        <label htmlFor="website">{i18next.t("Website")}</label>
                      </div>
                    </div>
                    <div class="mb-4">

                      {/* <div className="selectCountry">
                        <Form.Group controlId="exampleForm.ControlSelect1">

                          <Form.Control as="select">
                            <option className="selected">{i18next.t("SelectCountry")}</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </Form.Control>
                        </Form.Group>
                      </div> */}

                    </div>
                    <div class="mb-4">
                      <div className="selectProvince">
                        <Form.Group >

                          <Form.Control as="select" onChange={this.onChangeCity} value={this.state.SelectedCityId} >
                            <option className="selected">{i18next.t("SelectProvince")}</option>
                            {cityOptions}
                          </Form.Control>
                        </Form.Group>
                      </div>
                    </div>
                    <div class="mb-4">
                      <div className="selectProvince">
                        <Form.Group >

                          <Form.Control as="select" onChange={this.onChangeDistrict} value={this.state.SelectedDistrictId} >
                            <option className="selected">{i18next.t("SelectDistrict")} </option>
                            {districtOptions}
                          </Form.Control>
                        </Form.Group>
                      </div>
                    </div>
                  </Form>
                  <div className=" h-size-2 p-0 col-7 border mx-auto ApplyDesigner__btn">
                    <a onClick={this.addDesigner}> {i18next.t("Send")}</a>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-lg-6 ApplyDesigner__img d-none d-lg-flex">
              <img src={ApplyDesignerPagePhoto}></img>
            </div>

          </div>

        </div>
        <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showSuccesModal} onHide={this.hideLoginModal}>
            <Modal.Header closeButton>
            </Modal.Header>
            <h4 className="LoginModal__title text-center"> {i18next.t("ProcessSuccess")}</h4>
            <p></p>
            <Modal.Body>
              <div className="text-center mb-3">
                <svg className="iconSize-md" >
                  <use href={Sprite + "#smile"} ></use>
                </svg >
              </div>
              <form className="LoginModal  ">
                <p className="text-center p-size-1"> {i18next.t("SuccessMessage")}</p>
                <div className="text-center  w-50  mx-auto">
                  <button href={"/"}  className="submit-button">{i18next.t('OK')}</button>
                </div>
              </form>
            </Modal.Body>
            {/* <Modal.Footer>
              <div className="KeepInTouch__Img ">
                <img src={KeepInTouchImg} className="img-fluid"></img>
              </div>
            </Modal.Footer> */}
        </Modal>
      </div>

    )
  }
}
