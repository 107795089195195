
import React, { Component } from 'react'
import BreadCrumb from '../Components/BreadCrumb';
import i18next from 'i18next';
import IsBirlikleriImgCard from '../Components/IsBirlikleriImgCard';
import KeepInTouch from '../Components/KeepInTouch';
import ApiBase from '../lib/ApiBase';
export default class IsBirlikleri extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cardList: [
                {
                    id: 1,
                    Photo: 'https://dummyimage.com/500x700/807680/0f0f12',
                    Title: 'Meet The Designer',
                    subTitle:'Cemal Candle Co',
                },
                {
                    id: 2,
                    Photo: 'https://dummyimage.com/500x700/807680/0f0f12',
                    Title: 'Meet The Designer',
                    subTitle:'Cemal Candle Co',
                },
                {
                    id: 3,
                    Photo: 'https://dummyimage.com/500x700/807680/0f0f12',
                    Title: 'Meet The Designer',
                    subTitle:'Cemal Candle Co'
                  
                }
             
            ],
            
            CollaborationList:[]
        };
    }
    componentDidMount(){
        this.getCollabrations();
    }
    getCollabrations = () => {
        this.setState({ loading: true });
        var Lang = localStorage.getItem("lang")
        var LangId = Lang === "tr" ? 1 : 2;
        ApiBase.Post({
            url: '/get-collaborations',
            data: {
                Take: 5,
                LanguageId:LangId
            },
            successFunction: (e) => {
                console.log("collabrations ", e.data.data.collaborationList)
                this.setState({CollaborationList:e.data.data.collaborationList})
              
            },
            errorFunction: (e) => {

                console.log("collabrations", e.data.message)
            }
        })
    }
    render() {
        const { history } = this.props;
        const lang = localStorage.getItem("lang")
        const cardList = this.state.CollaborationList.map((item) => {
            return (
                <div className="col-lg-4 p-0">
                    <IsBirlikleriImgCard isbirlikleriHref={`/isbirlikleridetay/${item.Url}`} item={item}></IsBirlikleriImgCard>
                </div>
            )
        })
        return (
            <div className="isBirlikleri">
                <div className="container">
                    <BreadCrumb path={i18next.t("NewCollaboration")}></BreadCrumb>
                    <div className="BlogAndMagazine__header">
                        <h4>{i18next.t('NewCollaboration')}</h4>
                        <div className="row ">
                            <div className="col-10  mx-auto">
                                <p className="">{i18next.t('IsbirlikleriDetailText')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row m-0">
                        {cardList}
                    </div>
                    <KeepInTouch />
                </div>
            </div>
        )
    }
}
