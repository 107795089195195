import React, { Component } from 'react'
import i18next from 'i18next';
import ApiBase from '../lib/ApiBase';
import Logo from '../Assets/Image/logo.png';
export default class OdemeSayfasiBasarisiz extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errorMessage: "",
        }
    }

    componentDidMount(){
        this.getActivationData();
    }

    getActivationData = () => {
        this.setState({ actLoading: true })
        const _guid = this.props.match.params.guid;
        // console.log(_guid)
        ApiBase.Post({
            url: '/get-payment-fail-reason',
            data: {
                MerchantOid: _guid
            },
            successFunction: (e) => {
                // console.log(e.data)
                this.setState({ actLoading: false, errorMessage: e.data.message });
                // this.setState({ loading: false })
            },
            errorFunction: (e) => {
                // console.log(e.data)
                this.setState({ actLoading: false, errorMessage: e.data.message });

            }
        })
    }
    render() {
        return (
            <div className="ActivationPage">
                <div className="container ">
                    <div className="ActivationPage__Body d-flex flex-column">
                        <div className=" text-center my-auto">
                            {/* <img src={Logo} className="img-fluid"></img> */}
                            {this.stateactLoading !== true &&
                                <h4 className="text-center h-size-4 mt-4 "> Ödeme İşleminiz Başarısız </h4>}

                            <div className="row">
                                <div className=" col-lg-2 mx-auto py-3 ">
                                    <p>{this.state.errorMessage}</p>
                                    <a className="addAddressBtn h-size-2 text-uppercase" href="/">{i18next.t("Home")}</a>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>

            </div>
        )
    }
}
