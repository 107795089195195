import { extendObservable, action, observable, configure, runInAction } from 'mobx';
import ApiBase from '../lib/ApiBase';

function CartStore() {

  extendObservable(this, {
    CartCount: 0,
    GetCartCount: action(function (UserId, AnonymousUserId) {
      ApiBase.Post({
        url: '/get-cart-count',
        data: {
          UserId: UserId,
          AnonymousUserId: AnonymousUserId
        },
        successFunction: (e) => {
          // console.log("CartCount",e.data.data )
            
            this.CartCount = e.data.data
        },
        errorFunction: (e) => {
          // console.log("cart count",e.data.message)
        },
      });
    }),
  })


}


export default new CartStore();