/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import ArtCard from '../Components/ArtsAndWorkshopCard'
import BreadCrumb from '../Components/BreadCrumb';

import i18next from 'i18next';
import ProgressBar from 'react-bootstrap/ProgressBar';


import ApiBase from '../lib/ApiBase';

export default class ArtsAndWorkshops extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ArtsAndWorkshopsList: [],
            Status: 1,// tümü
            TotalCount: 0,
            loading: true,
        };
        this.getArtsAndWorkshops();

    }
    componentDidMount() {
        document.title = "Wondrous/Sanat & Atolyeler";
    }
    getArtsAndWorkshops = () => {
        this.setState({ loading: true })
        var Lang = localStorage.getItem("lang")
        var LangId = Lang === "tr" ? 1 : 2;
        ApiBase.Post({
            url: '/get-arts-workshops',
            data: {
                LanguageId: LangId,
                Take: 10
            },
            successFunction: (e) => {
                console.log(e.data.data)
                this.setState({
                    ArtsAndWorkshopsList: e.data.data.dataList,
                    FeatureBlog: e.data.data.featuredBlog,
                    loading: false,
                    TotalCount: e.data.data.TotalCount,
                    IsContinue: e.data.data.Continue,
                });
            },
            errorFunction: (e) => {
                this.setState({ loading: false })
                // console.log(e.data.message)
            }
        })
    }
    loadMore = () => {
        // this.setState({ loading: true })
        var Lang = localStorage.getItem("lang")
        var LangId = Lang === "tr" ? 1 : 2;
        ApiBase.Post({
            url: '/get-arts-workshops',
            data: {
                LanguageId: LangId,
                Take: 10,
                Skip: this.state.ArtsAndWorkshopsList.length
            },
            successFunction: (e) => {
                console.log(e.data.data)
                this.setState({
                    ArtsAndWorkshopsList: [...this.state.ArtsAndWorkshopsList, ...e.data.data.dataList],
                    FeatureBlog: e.data.data.featuredBlog,
                    loading: false,
                    TotalCount: e.data.data.TotalCount,
                    IsContinue: e.data.data.Continue,
                });
            },
            errorFunction: (e) => {
                this.setState({ loading: false })
                // console.log(e.data.message)
            }
        })
    }

    render() {
        const allList = this.state.ArtsAndWorkshopsList.map((item) => {
            return (
                <div key={item.Id} className="col-lg-6">
                    <ArtCard artsHref={`/artsandworkshopdetail/${item.Url}`} item={item}></ArtCard>
                </div>
            )
        });
        const artsList = this.state.ArtsAndWorkshopsList.filter(x => x.IsWorkshop === false).map((item) => {
            return (
                <div key={item.Id} className="col-lg-6">
                    <ArtCard item={item}></ArtCard>
                </div>
            )
        });

        const workshopsList = this.state.ArtsAndWorkshopsList.filter(x => x.IsWorkshop === true).map((item) => {
            return (
                <div key={item.Id} className="col-lg-6">
                    <ArtCard item={item}></ArtCard>
                </div>
            )
        });

        return (
            <>
                {this.state.loading == true ?
                    <div className="loadingImage">

                    </div>
                    :
                    <div className="ArtsAndWorkshop">
                        <div className="container">

                            <BreadCrumb path={i18next.t("ArtsWorkshopsUppercase")} />
                            <h2 className="h-size-4 text-center mt-4">{i18next.t("ArtsWorkshopsUppercase")}</h2>



                            <div className="row ">
                                <div className="col-10 offset-1">
                                    <p className="p-size-1 text-center mt-3">Düzenleyeceğimiz temalı etkinlikler sayesinde markalar ve siz müşterilerimiz arasında etkileşimli bir alışveriş deneyimi yaşatmak ve sürekli yenilenen seçkimiz ile İzmir insanının moda ve sanat ihtiyaçlarına çözüm ortağı olmayı hedefliyoruz.
{/*                             
                       <a href="/blogandmagazine" className="p-size--color2 p-size--b"> blog </a> */}
                                    </p>

                                </div>
                            </div>
                            <div className="   mb-5 mt-3 ">
                                <div className="row d-flex justify-content-center ArtsAndWorkshop__topBtns">

                                    <div className="col-sm-1 col-3"><a onClick={() => { this.setState({ Status: 1 }) }} className={this.state.Status === 1 ? "p-size-3 active" : "p-size-3"} >{i18next.t("All")}</a></div>
                                    <div className="col-sm-1 col-3"><a onClick={() => { this.setState({ Status: 2 }) }} className={this.state.Status === 2 ? "p-size-3 active" : "p-size-3"} >{i18next.t("Art")}</a></div>
                                    <div className="col-sm-2 col-6"><a onClick={() => { this.setState({ Status: 3 }) }} className={this.state.Status === 3 ? "p-size-3 active" : "p-size-3"}>{i18next.t("Workshops")}</a></div>

                                </div>


                            </div>

                            <div className="row">
                                {this.state.Status === 1 ?
                                    allList
                                    :
                                    this.state.Status === 2 ?
                                        artsList
                                        :
                                        this.state.Status === 3 ?
                                            workshopsList
                                            :
                                            <></>
                                }
                            </div>
                            <div className="row my-5">
                                <div className="col-lg-7 text-lg-right text-center mb-3 mb-lg-0" >
                                    <a onClick={this.loadMore} className="p-size-2 text-dark">{i18next.t("LoadMore")}</a>
                                </div>
                                {/* <div className="col-lg-3 offset-lg-2 text-right ">
                                    <ProgressBar now={this.state.ArtsAndWorkshopsList.length} max={this.state.TotalCount ? this.state.TotalCount : 0} />
                                    <span className="p-size-2">{this.state.ArtsAndWorkshopsList.length} {i18next.t("Product")} {i18next.t("display")} / {this.state.TotalCount ? this.state.TotalCount : 0} {i18next.t("TotalLowercase")}   {i18next.t("Product")}</span>
                                </div> */}

                            </div>

                        </div>
                    </div>
                }
            </>
        )
    }
}
