import React, { Component } from 'react'
import BreadCrumb from '../Components/BreadCrumb';
import SozlesmeCard from '../Components/SozlesmeCard';
import SozlesmeCardMobil from '../Components/SozlesmeCardMobil';
import i18next from 'i18next';

export default class PrivacyPolicy extends Component {
    render() {
        return (
            <>
                <BreadCrumb path={i18next.t("FtPrivacyPolicy")}></BreadCrumb>
                <div className="sozlesme body--margin">
                    <div className="container-fluid">
                        <div className="container">
                        </div>
                    </div>
                    <div className="container ">
                        <div className="row">
                            <div className="col-lg-3  d-none d-lg-block divLeftCol">
                                <SozlesmeCard className3={"bg--secondary"} />
                            </div>
                            <div className="col-lg-3  d-lg-none UserDetail__section1">
                                <SozlesmeCardMobil active3></SozlesmeCardMobil>
                            </div>
                            <div className="col-lg-9">
                                <div className="divCategoryName mt-3">
                                    GİZLİLİK POLİTİKASI
                                    <div className="divHomeBlackLine"></div>
                                </div>
                                {/* <p className="p-size-1">
                                Değerli Site Ziyaretçilerimiz ve Üyelerimiz,
                              <br></br>  <br></br>
                            Gizlilik Kuralları ve Kişisel Verilerin Korunması Kanunu kapsamında sizleri bilgilendirmek isteriz.
                            Şirketimiz Wondrous ve sitesi  www.shopwondrous.com Sitemizde (mobil uygulaması dahil), ziyaretçi-üye bilgilerinin gizliliği, kişisel verilerin korunması, saklanması, işlenmesi-kullanımı-imhası, ticari elektronik iletişimler ve diğer hususlarda, aşağıda esasları belirtilen Gizlilik Kuralları ve Uygulama-Kullanım Şartları geçerlidir.

                            <p className="ft-link-sub py-3">Bilgilerin Korunması </p>
                            Ziyaretçiler tarafından verilen bilgilerin ve işlemlerinin güvenliği için gerekli önlemler bilgi ve işlemin mahiyetine göre Şirketimiz sistemlerinde ve internet altyapısında, teknolojik imkânlar dâhilinde, uygun teknik ve idari yöntemler ile alınmıştır.
                             <br></br>  <br></br>
                            Sitemizi kullanımlarınızda (varsa) tüm kredi kartı işlemleri ve onayları Şirketimiz ‘den bağımsız olarak ilgili Banka veya Kart Kuruluşlarınca online olarak aranızda gerçekleştirilmekte olup Kredi kartı "şifresi" gibi bilgiler Şirketimiz tarafından görülmez ve kaydedilmez.
                              <br></br>  <br></br>
                            Sitemize üyelik,  ürün/hizmet satın alma ve bilgi güncelleme amaçlı girilen bilgiler, keza kredi kartı ve banka kartlarına ait gizli bilgiler, diğer internet kullanıcıları tarafından görüntülenemez.

                            <p className="ft-link-sub py-3">Bilgilerin Toplanma Yöntemleri, İşlenme-Aktarım Amacı ve Kişisel Veri İşlemleri </p>

                            Gizliliklerinin korunması için gereken tedbirler almak ve kişisel verilerin işlenmesine dair tüm yasal ilkeler ile kişisel veri saklama-imha politikalarına uygun olmak üzere; kanunen zorunlu ise bazı uygulamalar için ayrıca izni de rica edilerek; Ziyaretçilerimizin fiziki ve sanal işletmeleri ziyaret, alışveriş ve iletişim bilgileri ile aşağıda detaylı belirtilen diğer kişisel bilgileri,
                              <br></br>  <br></br>

                      -- Veri Sorumlusu Şirketimiz, servis sağlayıcıları-tedarikçileri ve belirlenecek (sosyal medya-online reklam işletmecileri dahil) diğer işlenme amacının gerektirdiği üçüncü kişiler/kuruluşlar (duruma göre veri sorumluları, işleyenler ve/veya alıcı grupları) tarafından ve arasında,
                      <br></br>  <br></br>
                        -- her türlü ürün-hizmet tanıtımı, reklam, iletişim, promosyon, satış, pazarlama, kredi kartı ve kullanıcı/üyelik/müşteri bilgilendirmeleri, işlemleri ve uygulamaları yapılması amacıyla,
                          <br></br>  <br></br>
                        -- burada belirtilen ve ayrıca kanunlarda kişisel verilerin işlenebileceği ve aktarılabileceği açıkça öngörülen hallerde, bilgilerin tarafınca alenileştirildiği durumlarda, her türlü tüketici, üyelik ve diğer sözleşmelerin kurulması veya ifasıyla doğrudan doğruya ilgili olarak sözleşme taraflarına ait kişisel verilerin işlenmesinin gerekli olduğu hallerde, keza veri sorumlusu/işleyen olarak her türlü hukuki yükümlülükleri yerine getirebilme için zorunlu durumlarda, ayrıca hakların tesisi, kullanılması veya korunması için veri işlemenin ve aktarmanın zorunlu olduğu durumlar ile temel hak ve özgürlüklerine zarar vermemek kaydıyla veri sorumlusu/işleyen olarak burada ve yasalarda öngörülmüş meşru menfaatler için veri işlemenin ve aktarmanın zorunlu/gerekli olduğu hallerde, işlenebilir ve aktarılabilir.
                        <p className="ft-link-sub py-3"> Tanımlama Bilgisi Uygulaması  </p>
                        
                             www.shopwondrous.com sitemizde (mobil uygulamaları dahil tüm dijital platformlarında) muhtelif türde çerezler kullanılmaktadır. Bunlar oturum çerezleri, kalıcı çerezler, zorunlu çerezler, işlevsellik çerezleri, analiz çerezleri, ticari çerezler ve üçüncü parti çerezleri gibi tanımlama bilgileridir. 

                                      <br></br>  <br></br>


                                Çerezler ziyaret edilen internet sitesinin düzgün bir şekilde çalışmasını ve geliştirilmesini, kullanıcı deneyiminin kişiselleştirilmesini ve iyileştirilmesini, siteleri oturum açmadan ziyaret edebilmesini ve/veya tarafına ticari-sosyal amaçlı bildirimler yollanmasını ve genel olarak site kullanıcıları-ziyaretçilerine ilgili sitede, genel ya da özelleştirilmiş bilgilendirmeler, reklam ve tanıtımlar sunulmasını-iletilmesini sağlamak amacıyla bilgisayarlara ve mobil cihazlara yerleştirilen küçük veri parçacıklarıdır. 
                                  <br></br>  <br></br>

                                Çerezler bilgisayar-cihazlarda, varsa kanuni azami süre aşılmamak kaydıyla, amaca uygun bir süre ile tutulurlar. 

                                  
                                  <br></br>  <br></br>

                                Sitemizi (mobil versiyonları dahil) kullanan Ziyaretçiler yukarıda belirtilen uygulamayı, keza ilgili tanımlama bilgilerinin burada, kişisel veri mevzuatında ve bu bilgilendirme metninin diğer kısımlarında çeşitli bilgileriniz için öngörülen amaçlarla ve kapsamda-şartlarda islenmesini kabul etmiş sayılırlar. 

                                                            <br></br>  <br></br>


                                Ziyaretçiler istedikleri her zaman cihazlarındaki program ve/veya işletim sistemi ve/veya internet tarayıcısının ayarlarından çerezleri düzenleyerek kaldırabilirler ve/veya anılan bildirimleri durdurabilirler.

                                <p className="ft-link-sub py-3">İletişimler </p>

                                Ziyaretçilerimiz ile, yine kanunlara uygun şekilde, her türlü ürün ve hizmetlere ilişkin tanıtım, reklam, iletişim, promosyon, satış ve pazarlama amacıyla, kredi kartı ve üyelik/müşteri bilgilendirme, SMS/kısa mesaj, bilgisayar, telefon, e-posta/mail, faks, her türlü bildirimler, diğer elektronik iletişim araçları ile sosyal, ticari ve sair elektronik iletişimler yapılabilir, Ziyaretçilere ticari elektronik iletiler gönderilebilir. 


                                <p className="ft-link-sub py-3">İşlenen - Aktarılan Kişisel Verileriniz ve Kişisel Verilerinize Dair Kanuni Haklarınız  </p>

                                Değerli Ziyaretçimiz, isim, soy isim ,yas/doğum tarihi, cinsiyet, yaşanılan bölge, adres ve teslimat adresi, meslek/is gibi üye/kullanıcı bilgileri, özel-resmi kimlik, kimlik no. ve vergi bilgileri, , fatura içerikleri, ödeme yöntemleri, şifre gibi gizli bilgiler hariç kart ve hesap bilgileri, eski ve yeni cep/ev/is telefon/faks numaraları, mail adresleri, elektronik ticari ve sair iletişimler, tanımlama bilgileri (çerez, web tarayıcı işaretleri-bilgisi, IP, vb) ile konum verilerinizden oluşan kişisel bilgilerinizin toplanma yöntemi ve hukuki sebebi, hangi amaçla isleneceği ve kimlere hangi amaçla aktarılabileceği hususlarında tarafınıza bilgi verilmesini isteme haklarınızın yanı sıra; 

  
                                                            <br></br>  <br></br>

                                    -- kişisel bilgilerinizin islenip islenmediğini, islenmişse buna ilişkin bilgileri, amacına uygun kullanılıp kullanılmadığını, yurt içinde ve dışında aktarıldığı üçüncü kişileri öğrenme, bilme ve tüm bu hususlarda bilgi talep etme haklarına, bilgilerin eksik veya yanlış işlenmiş olmaları halinde düzeltilmesini, kanuni şartlarına ve yöntemine uygun olarak silinmesini veya yok edilmesini ve bu durumun verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme ve kanuna aykırı olarak islenmesi sebebi ile zarara uğramanız halinde giderilmesini talep etme haklarına sahip olduğunuzu bilgilerinize sunarız. 

                                      
                                                                <br></br>  <br></br>

                                    Yukarıdaki hususlar kişisel verilere ilişkin aydınlatma yükümlülüğümüz gereği bilgilerinize sunulmakla beraber, mevzuatın ilgili kişiden açık rıza alınmaksızın islenmesine imkan verdiği haller dışındaki her türlü kişisel veri işlemleri için izin alındığını ve izinsiz islenme yapılmadığını bilmenizi rica ederiz. 

                                                                <br></br>  <br></br>


                                    Bu çerçevede, söz konusu izni mağazamızda yazılı-imzalı verebileceğiniz gibi dijital ortamda da verebilirsiniz.  

                                    <p className="ft-link-sub py-3">Alışverişler ve Diğer Tüketici İşlemleri  </p>

                                    Ziyaretçilerin yukarıda belirtilen suretler ile bilgilenmeleri ve/veya taraflarına yapılan iletişimler, bilgilendirmeler, bildirimler, tanıtım ve reklamlar neticesi bir ürün/hizmet satın alması söz konusu olursa anılan işlem ayrıca ve kanuni usulünce ilgili satıcı/sağlayıcı ile yapacakları tüketici sözleşmesine tabidir. Tüketici sözleşmesi kendi şartlarında ve tarafları arasında uygulanır. 

  
                                                                <br></br>  <br></br>

                                        Sitemizden alışverişlerinizde, her işlem sırasında göreceğiniz sipariş ön bilgilendirme formu-mesafeli satış sözleşmesi şartları geçerli olacaktır. 

                                        <p className="ft-link-sub py-3">Değişiklikler </p>

                                        Şirketimiz gizlilik, kişisel veri saklama-kullanım ve imha politikası ve Site kullanım şartları gibi konularda, gerekli görebileceği her türlü değişikliği yapma hakkini saklı tutar; bu değişiklikler şirketimizce Siteden veya diğer uygun yöntemler ile duyurulduğu andan itibaren geçerli olur. 



                            </p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
