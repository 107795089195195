/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import BreadCrumb from '../Components/BreadCrumb';
import OrderSummary from '../Components/OrderSummary';
import Form from 'react-bootstrap/Form';
import i18next from 'i18next';
import Sprite from '../Assets/Icons/sprite.svg';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import CcvImg from '../Assets/Image/ccv.jpg';
import InputMask from 'react-input-mask';
import axios from 'axios';
import FormGroup from 'react-bootstrap/esm/FormGroup';
import Modal from 'react-bootstrap/Modal';
import KeepInTouchImg from '../Assets/Image/KeepInTouch__Img.svg';
import ApiBase from '../lib/ApiBase';
import { inject, observer } from 'mobx-react';

const OdemeYontemi = observer(class OdemeYontemi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      favoriteList: [],
      isFavorited: -1,
      showSuccessModal: false,
      showErrorModal: false,
      chekedCreditCard: true,
      checkedRemittance: false,
      checkedSozlesme: false,
      orderSummary: [
        {

          TotalPrice: '99,99',
          CargoPrice: '6,99',
          GeneralTotalPrice: '106,98'
        }
      ],
      selectedItem: 0,
      selectedBankAccountId: 0,
      CCVPopupTarget: null,
      showCCVpopup: false,
      BankAccount: [],
      errorMessage: "",
      OrderSummaryCardPrice: [],

      CardOwnerNameSurname: "",
      CardNumber: "",
      SelectedCartMonth: 0,
      SelectedCardYear: 0,
      CardCCVCode: "",
      InstallmentCount:"",

      UserEmail: "",
      UserTotalPrice: "",
      UserNameSurname: "",
      UserAddressDescription: "",
      UserPhone: "",



      PaytrCardType: "",
      PaytrClientLang: "",
      PaytrCurrency: "",
      PaytrDebugOn: "",
      PaytrInstallmentCount: "",
      PaytrMerchantFailUrl: "",
      PaytrMerchantOkUrl: "",
      PaytrMerchantId: "",
      PaytrMerchantOId: "",
      PaytrNon3dTestFailed: "",
      PaytrPaymentNon3d: "",
      PaytrPaymentAmount: "",
      PaytrPaymentType: "",
      PaytrTestMode: "",
      PaymentToken: "",
      PaytrUserBasket: "",
      PaytrUserId: "",

      errorMessage: "",
    };
    this.ref = React.createRef();
    this.buttonRef = React.createRef();
    this.onBtnClick = this.buttonTrigger.bind(this);
    // console.log(this.props.history.location.state)


  }

  componentDidMount() {
    this.getBankAccount();
    this.getCartPrices();
    var adresBilgieriData = this.props.history.location.state
    localStorage.setItem("adresBilgileri", JSON.stringify(adresBilgieriData));
    var AddressData = JSON.parse(localStorage.getItem("adresBilgileri"))
    var _isLogin = localStorage.getItem("isLogin");
    var cUser = [];
    if (_isLogin === "true") {
      cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      this.setState({
        UserEmail: cUser.Email,
        UserNameSurname: cUser.NameSurname,
        UserPhone: cUser.Phone,
        UserAddressDescription: AddressData.SelectedAddressDescriptionTeslimat
      })
    } else {
      this.setState({
        UserEmail: AddressData.TeslimatEmail,
        UserNameSurname: AddressData.TeslimatNameSurname,
        UserPhone: AddressData.TeslimatPhone,
        UserAddressDescription: AddressData.TeslimatAddressDesc
      })
    }
    this.setState({ checkedSozlesme: false })
    window.scrollTo(0, 0);
  }


  paymentProcessHavale = () => {
    var _isLogin = localStorage.getItem("isLogin");
    // console.log(_isLogin)
    var _UserId = 0;

    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    } else {
      _isLogin = false
    }
    var Lang = localStorage.getItem("lang")
    var AUID = localStorage.getItem("AnonymousUserId");
    var AddressData = JSON.parse(localStorage.getItem("adresBilgileri"))
    this.setState({ odemeyeGecLoading: true });


    var LangId = Lang === "tr" ? 1 : 2;
    if (this.state.checkedSozlesme == true) {
      ApiBase.Post({
        url: '/payment-process',
        data: {
          UserId: _UserId,
          AnonymousUserId: AUID,
          IsLoggedIn: _isLogin,
          BillAddressId: AddressData.SelectedFaturaAddresId,
          DeliveryAddressId: AddressData.SelectedTeslimatAddressId,
          IsCorporate: !AddressData.IsBireysel,
          PersonalNameSurname: AddressData.BillTypeNameSurname,
          CitizenshipNo: AddressData.TCNo,
          CompanyName: AddressData.BillTypeCompanyName,
          TaxNumber: AddressData.BillTypeTaxNumber,
          TaxDepartment: AddressData.BillTypeTaxName,
          DeliveryNameSurname: AddressData.TeslimatNameSurname,
          DeliveryEmail: AddressData.TeslimatEmail,
          DeliveryPhone: AddressData.TeslimatPhone,
          DeliveryCityId: AddressData.TeslimatCityId,
          DeliveryDistrictId: AddressData.TeslimatDistrictId,
          DeliveryZipCode: AddressData.TeslimatZipCode,
          DeliveryAddress: AddressData.TeslimatAddressDesc,
          IsSameAddress: AddressData.IsSameAddress,
          BillNameSurname: AddressData.FaturaNameSurname,
          BillEmail: AddressData.FaturaEmail,
          BillPhone: AddressData.FaturaPhone,
          BillCityId: AddressData.FaturaCityId,
          BillDistrictId: AddressData.FaturaDistrictId,
          BillZipCode: AddressData.FaturaZipCode,
          BillAddress: AddressData.FaturaAddressDecription,
          IsForChecking: false,
          IsBank: this.state.checkedRemittance,
          BankAccountId: this.state.selectedBankAccountId,
          MerchantOid: this.state.PaytrMerchantOId,
          IsGiftWrap: AddressData.IsGiftWrap
        },

        successFunction: (e) => {
          // console.log("payment process", e.data)
          this.setState({ showSuccessModal: true, odemeyeGecLoading: false });
          this.getCartCount();
        },

        errorFunction: (e) => {

          this.setState({ showErrorModal: true, errorMessage: e.data.message, odemeyeGecLoading: false });

        }
      })
    } else {
      this.setState({ odemeyeGecLoading: false, showErrorModal: true, errorMessage: "Sözleşmeyi onaylamanız gerekmektedir" })
    }
  }

  getCartCount = () => {
    var AUID = localStorage.getItem("AnonymousUserId")
    var _isLogin = localStorage.getItem("isLogin");

    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId

    }
    this.props.Store.CartStore.GetCartCount(_UserId, AUID)
  }
  paymentProcess = () => {
    this.setState({ odemeyeGecLoading: true })
    var _isLogin = localStorage.getItem("isLogin");
    // console.log(_isLogin)
    var _UserId = 0;

    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    } else {
      _isLogin = false
    }
    var Lang = localStorage.getItem("lang")
    var AUID = localStorage.getItem("AnonymousUserId");
    var AddressData = JSON.parse(localStorage.getItem("adresBilgileri"))
    //this.setState({ loadingOdemeyiTamamla: true });
    if (this.state.CardCCVCode === "") {
      this.setState({ odemeyeGecLoading: false, showErrorModal: true, errorMessage: "CCV Kodu boş geçilemez" })
    }

    else if (this.state.CardNumber === "") {
      this.setState({ odemeyeGecLoading: false, showErrorModal: true, errorMessage: "Kart Numarası boş geçilemez" })
    }

    else if (this.state.CardOwnerNameSurname === "") {
      this.setState({ odemeyeGecLoading: false, showErrorModal: true, errorMessage: "Kart üzerinde bulunan isim soyisim boş geçilemez" })
    }
    else if (this.state.SelectedCardYear === 0) {
      this.setState({ odemeyeGecLoading: false, showErrorModal: true, errorMessage: "Kart son kullanma yılı boş geçilemez" })
    }
    else if (this.state.SelectedCartMonth === 0) {
      this.setState({ odemeyeGecLoading: false, showErrorModal: true, errorMessage: "Kart son kullanma ayı boş geçilemez" })
    }
    else {

      var LangId = Lang === "tr" ? 1 : 2;
      ApiBase.Post({
        url: '/payment-process',
        data: {
          UserId: _UserId,
          AnonymousUserId: AUID,
          IsLoggedIn: _isLogin,
          BillAddressId: AddressData.SelectedFaturaAddresId,
          DeliveryAddressId: AddressData.SelectedTeslimatAddressId,
          IsCorporate: !AddressData.IsBireysel,
          PersonalNameSurname: AddressData.BillTypeNameSurname,
          CitizenshipNo: AddressData.TCNo,
          CompanyName: AddressData.BillTypeCompanyName,
          TaxNumber: AddressData.BillTypeTaxNumber,
          TaxDepartment: AddressData.BillTypeTaxName,
          DeliveryNameSurname: AddressData.TeslimatNameSurname,
          DeliveryEmail: AddressData.TeslimatEmail,
          DeliveryPhone: AddressData.TeslimatPhone,
          DeliveryCityId: AddressData.TeslimatCityId,
          DeliveryDistrictId: AddressData.TeslimatDistrictId,
          DeliveryZipCode: AddressData.TeslimatZipCode,
          DeliveryAddress: AddressData.TeslimatAddressDesc,
          IsSameAddress: AddressData.IsSameAddress,
          BillNameSurname: AddressData.FaturaNameSurname,
          BillEmail: AddressData.FaturaEmail,
          BillPhone: AddressData.FaturaPhone,
          BillCityId: AddressData.FaturaCityId,
          BillDistrictId: AddressData.FaturaDistrictId,
          BillZipCode: AddressData.FaturaZipCode,
          BillAddress: AddressData.FaturaAddressDecription,
          IsForChecking: false,
          IsBank: this.state.checkedRemittance,
          BankAccountId: this.state.selectedBankAccountId,
          MerchantOid: this.state.PaytrMerchantOId,
          InstallmentCount: this.state.PaytrInstallmentCount,
          IsGiftWrap: AddressData.IsGiftWrap
        },

        successFunction: (e) => {
          console.log("payment process", e.data)
          this.setState({ odemeyeGecLoading: false })
          this.buttonTrigger();

        },

        errorFunction: (e) => {
          console.log(e)
          this.setState({ odemeyeGecLoading: false, showErrorModal: true, errorMessage: e.data.message });

        }
      })

    }

  }

  getBankAccount = () => {
    ApiBase.Post({
      url: '/get-bank-accounts',
      data: {},
      successFunction: (e) => {
        // console.log("bankAccount", e.data.data.ResultList)

        this.setState({ BankAccount: e.data.data.ResultList, loading: false })

      },
      errorFunction: (e) => {
        // console.log(e.data.message)
        this.setState({ showErrorModal: true, errorMessage: e.data.message });

      }
    })
  }





  buttonTrigger = () => {
    this.buttonRef.current.click();
    // console.log(this.state.CardNumber, this.state.CardOwnerNameSurname, this.state.SelectedCardYear, this.state.SelectedCartMonth, this.state.CardCCVCode)
  }

  getCartPrices = () => {
    var _isLogin = localStorage.getItem("isLogin");
    // console.log(_isLogin)
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    var Lang = localStorage.getItem("lang")
    var AUID = localStorage.getItem("AnonymousUserId");
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-cart-prices',
      data: {
        UserId: _UserId,
        AnonymousUserId: AUID
      },
      successFunction: (e) => {
        // console.log("cardPrices", e.data.data)

        this.setState({ OrderSummaryCardPrice: e.data.data, loading: false })

      },
      errorFunction: (e) => {
        // console.log(e.data.message)
        this.setState({ showErrorModal: true, errorMessage: e.data.message });

      }
    })
  }


  _checkedSozlesme = () => {
    this.setState({ checkedSozlesme: true })
  }

  odemeyeGec = () => {
    this.setState({ loadingOdemeyiTamamla: true })
    var _isLogin = localStorage.getItem("isLogin");
    // console.log(_isLogin)
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    var Lang = localStorage.getItem("lang")
    var AUID = localStorage.getItem("AnonymousUserId");
    var LangId = Lang === "tr" ? 1 : 2;
    if (this.state.checkedSozlesme == true) {
      ApiBase.Post({
        url: '/get-paytr-token',
        data: {
          UserId: _UserId,
          AnonymousUserId: AUID,
          Email: this.state.UserEmail,
          NameSurname: this.state.CardOwnerNameSurname,
          CardNumber: this.state.CardNumber,
          CcvCode: this.state.CardCCVCode,
          CardYear: this.state.SelectedCartMonth + "/" + this.state.SelectedCardYear,
          InstallmentCount: this.state.InstallmentCount
        },
        successFunction: (e) => {
          // console.log("paytr token", e.data.data)

          this.setState({

            PaytrCardType: e.data.data.card_type,
            PaytrClientLang: e.data.data.client_lang,
            PaytrCurrency: e.data.data.currency,
            PaytrDebugOn: e.data.data.debug_on,
            PaytrInstallmentCount: e.data.data.installment_count,
            PaytrMerchantFailUrl: e.data.data.merchant_fail_url,
            PaytrMerchantOkUrl: e.data.data.merchant_ok_url,
            PaytrMerchantId: e.data.data.merchant_id,
            PaytrMerchantOId: e.data.data.merchant_oid,
            PaytrNon3dTestFailed: e.data.data.non3d_test_failed,
            PaytrPaymentNon3d: e.data.data.non_3d,
            PaytrPaymentAmount: e.data.data.paymentAmount,
            PaytrPaymentType: e.data.data.payment_type,
            PaytrTestMode: e.data.data.test_mode,
            PaymentToken: e.data.data.token,
            PaytrUserBasket: e.data.data.user_basket_json,
            PaytrUserId: e.data.data.userip,
          })
          this.paymentProcess();
        },
        errorFunction: (e) => {
          // console.log(e.data.message)
          this.setState({ showErrorModal: true, errorMessage: e.data.message });

        }
      })
    } else {
      this.setState({ showErrorModal: true, errorMessage: "Sözleşmeyi onaylamanız gerekmektedir" })
    }

  }



  showCcvPopup = (event) => {
    this.setState({ showCCVpopup: true, CCVPopupTarget: event.target, })
  }
  hideCCVPopup = () => {
    this.setState({ showCCVpopup: false })
  }
  onClickOdemeyeGec = () => {

    this.onBtnClick();



  }
  hideLoginModal = () => {
    this.setState({ showSuccessModal: false, showErrorModal: false })

  }
  selectBank = (item) => {
    this.setState({ selectedItem: item.Id, selectedBankAccountId: item.GetRandomBankAcc.Id })
  }

  onClickCreditCard = () => {
    this.setState({ chekedCreditCard: true, checkedRemittance: false })
  }
  onClickRemittance = () => {
    this.setState({ checkedRemittance: true, chekedCreditCard: false })
  }

  copyCodeToClipboard = () => {
    const el = this.textarea
    el.select()
    document.execCommand("copy")
    this.setState({ copySuccess: true })
  }
  onRadioButtonValueChange = (event) => {
    var value = event.target.value;
    var installment_ratio=3.55 + (0.94 * (value - 2));
    var newPrice=(((this.state.OrderSummaryCardPrice.TotalProductPrice / 100) * installment_ratio) + this.state.OrderSummaryCardPrice.TotalProductPrice).toFixed(2)
    this.setState({InstallmentCount: value});
    this.setState({OrderSummaryCardPrice:{...this.state.OrderSummaryCardPrice, TotalPriceStr: newPrice}});
  }
  installmentRows = () => {
    const installment_rows = []
    for (var i = 2; i <= 12; i++) {
      var installment_count = i;
      var installment_ratio = 3.55 + (0.94 * (i - 2));

      installment_rows.push(
        <div className="row">
          <div className="col-3">
            <input className="form-check-input" type="radio" name="installationOption" value={installment_count}  onChange={this.onRadioButtonValueChange}></input>
          </div>
          <div className="col-3">
            <div className="divColItem">{installment_count}</div>
          </div>
          <div className="col-3">
            <div className="divColItem">{(((this.state.OrderSummaryCardPrice.TotalProductPrice / 100) * installment_ratio) + this.state.OrderSummaryCardPrice.TotalProductPrice).toFixed(2)}</div>
          </div>
          <div className="col-3">
            <div className="divColItem">{((((this.state.OrderSummaryCardPrice.TotalProductPrice / 100) * installment_ratio) + this.state.OrderSummaryCardPrice.TotalProductPrice) / installment_count).toFixed(2)}</div>
          </div>
        </div>
      )
    }
    return installment_rows;
  }

  render() {




    const { history } = this.props;

    return (
      <>
            <BreadCrumb title={i18next.t("MyBasket")} titleLink={"/sepetim"} category={i18next.t("AddressDescription")} categoryLink={"/adresbilgisi"} path={i18next.t("Payment")} ></BreadCrumb>

        <div className="">
          <div className="container OdemeYontemi">
            <div className="row UserDetail__OdemeYontemi">
              <div className="col-lg-9 ">
                <h5 className="">{i18next.t("PaymentMethod")}</h5>
                <div className="row">
                  <div className="col-lg-12">
                    {/* <div class="form-check OdemeYontemi__options">
                      <input onClick={this.onClickCreditCard} className="form-check-input" type="radio" name="creditCardOption" id="creditCardOption" value="option1" />
                      <label className="form-check-label" htmlFor="creditCardOption">
                        <span className="h-size-3 ">  {i18next.t("PaymentWithCard")} </span></label>
                    </div> */}
                    {this.state.chekedCreditCard === true ?
                      <div className="row">
                      <div className="col-lg-6">
                        <Form className="iletisim my-4">
                          <div className="divFormSubTitle">{i18next.t("CreditCardNo")}</div>
                          <input type="text" id="deneme12" className="form-control divFromInput" required value={this.state.CardNumber} onChange={(e) => this.setState({ CardNumber: e.target.value })} />
                          <div className="divFormSubTitle">{i18next.t("NameOnTheCard")}</div>
                          <input type="text" id="nameOnCard" className="form-control divFromInput" required value={this.state.CardOwnerNameSurname} onChange={(e) => this.setState({ CardOwnerNameSurname: e.target.value })} />
                          <div className="row">
                            <div className="col-lg-6 py-2">
                              <div className="selectMonth form-control divFromInput">
                                <Form.Group>
                                  <Form.Control as="select" className="selectBorderless" value={this.state.SelectedCartMonth} onChange={(e) => this.setState({ SelectedCartMonth: e.target.value })}>
                                    <option className="selected">{i18next.t("Month")}</option>
                                    <option value="01">1</option>
                                    <option value="02">2</option>
                                    <option value="03">3</option>
                                    <option value="04">4</option>
                                    <option value="05">5</option>
                                    <option value="06">6</option>
                                    <option value="07">7</option>
                                    <option value="08">8</option>
                                    <option value="09">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>

                                  </Form.Control>
                                </Form.Group>
                              </div>
                            </div>
                            <div className="col-lg-6 py-2">
                              <div className="selectYear form-control divFromInput">
                                <Form.Group>
                                  <Form.Control as="select" className="selectBorderless" value={this.state.SelectedCardYear} onChange={(e) => this.setState({ SelectedCardYear: e.target.value })}>
                                    <option className="selected">{i18next.t("Year")}</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                    <option value="26">26</option>
                                    <option value="27">27</option>
                                    <option value="28">28</option>
                                    <option value="29">29</option>
                                    <option value="30">30</option>
                                    <option value="31">31</option>
                                    <option value="32">32</option>
                                    <option value="33">33</option>
                                    <option value="34">34</option>
                                    <option value="35">35</option>
                                    <option value="36">36</option>
                                  </Form.Control>
                                </Form.Group>
                              </div>
                            </div>
                          </div>
                          <div className="divFormSubTitle" style={{marginTop:12}}>
                            CCV
                            <span className="ml-auto d-inline-block float-right" style={{cursor:'pointer'}}>
                              <div onClick={this.showCcvPopup}>
                                <svg className="iconSize-xs" >
                                  <use href={Sprite + "#question"} ></use>
                                </svg >
                              </div>
                            </span>
                          </div>
                          <input type="text" id="CCV" className="form-control divFromInput" required value={this.state.CardCCVCode} onChange={(e) => this.setState({ CardCCVCode: e.target.value })} />
                          <div ref={this.ref}>
                            <Overlay
                              rootClose={true}
                              onHide={this.hideCCVPopup}
                              show={this.state.showCCVpopup}
                              target={this.state.CCVPopupTarget}
                              placement="bottom"
                              container={this.ref.current}
                              containerPadding={20}
                            >
                              <Popover id="popover-contained4">
                                <Popover.Title as="h3">
                                  <p className="mb-0 "> {i18next.t("WhatIsCCV")}</p></Popover.Title>
                                <Popover.Content>
                                  <p className="text-justify">{i18next.t("CCVText")} </p>
                                  <img src={CcvImg} className="img-fluid"></img>
                                  <p className="text-justify mt-3">  {i18next.t("CCVText2")}</p>
                                </Popover.Content>
                              </Popover>
                            </Overlay>
                          </div>
                        </Form>
                      </div>
                      <div className="col-12 divInstallationCol">
                        <div className="row">
                          <div className="col-3">
                          </div>
                          <div className="col-3">
                            <div className="divColTitle">{i18next.t("InstallmentCount")}</div>
                          </div>
                          <div className="col-3">
                            <div className="divColTitle">{i18next.t("InstallmentPrice")}</div>
                          </div>
                          <div className="col-3">
                            <div className="divColTitle">{i18next.t("InstallmentFee")}</div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-3">
                            <input className="form-check-input" type="radio" name="installationOption" value="0" onChange={this.onRadioButtonValueChange}></input>
                          </div>
                          <div className="col-3">
                            <div className="divColItem">{i18next.t("NoInstallment")}</div>
                          </div>
                          <div className="col-3">
                            <div className="divColItem">{this.state.OrderSummaryCardPrice.TotalProductPriceStr}</div>
                          </div>
                          <div className="col-3">
                            <div className="divColItem">{this.state.OrderSummaryCardPrice.TotalProductPriceStr}</div>
                          </div>
                        </div>
                        {this.installmentRows()}
                      </div>
                    </div>
                      :
                      <></>}
                  </div>
                  {/* <div className="col-lg-6">
                    <div class="form-check OdemeYontemi__options">
                      <input onClick={this.onClickRemittance} className="form-check-input" type="radio" name="creditCardOption" id="HavaleEFTOption" value="option2" />
                      <label className="form-check-label" htmlFor="HavaleEFTOption">
                        <span className="h-size-3">  {i18next.t("PaymentByMoneyOrder")}</span></label>
                    </div>
                    {this.state.checkedRemittance === true ?
                      <>
                        {this.state.BankAccount.map(item => {
                          return (
                            <div className="input-group mb-3">
                              <div className="input-group-prepend ">
                                <div className="input-group-text bg-transparent border-0">
                                  <input onClick={() => this.selectBank(item)} name="creditCardOption1" type="checkbox" checked={this.state.selectedItem === item.Id} />
                                </div>
                              </div>
                              <div>
                                <img className="bank__img" src={item.Logo} />


                              </div>
                              {this.state.selectedItem === item.Id
                                ?

                                <div div className="pl-5">
                                  <p className="h-size-3 mb-2" ><span className="font--opacity">{i18next.t("Bank")}:</span>  {item.Name}</p>
                                  <p className="h-size-3 mb-2" ><span className="font--opacity">Hesap Sahibi Adı:</span>  {item.GetRandomBankAcc.HesapSahibiAdi}</p>
                                  <p className="h-size-3 mb-2" ><span className="font--opacity">{i18next.t("BranchName")}:</span>  {item.GetRandomBankAcc.SubeAdi}</p>
                                  <p className="h-size-3 mb-2" ><span className="font--opacity">{i18next.t("AccountNumber")}:</span> {item.GetRandomBankAcc.HesapNo}</p>
                                  <p className="h-size-3 mb-2 d-flex align-items-center" ><span className="font--opacity mr-2">IBAN:</span> <textarea ref={(textarea) => this.textarea = textarea} className="p-size--b mr-2 IbanTextArea" value={item.GetRandomBankAcc.IBAN} />
                                    <a onClick={() => this.copyCodeToClipboard()}>
                                      <svg className="iconSize-xxxs" >
                                        <use href={Sprite + "#copy"} ></use>
                                      </svg >
                                    </a>
                                  </p>

                                </div>


                                :
                                <></>}


                            </div>
                          )
                        })}
                        <div className="row">
                          <div className="col-1 pr-0">
                            <svg className="iconSize-xs" >
                              <use href={Sprite + "#question"} ></use>
                            </svg >
                          </div>
                          <div className="col-11">
                            <p className="RemittanceInformation h-size-3">
                              {i18next.t("MoneyOrderDesc")} <span className="RemittanceInformation__strong" > {i18next.t("MoneyOrderDesc2")} </span>{i18next.t("MoneyOrderDesc3")}
                              <br></br><br></br>
                              {i18next.t("MoneyOrderDescription2")}
                            </p>
                          </div>
                        </div>


                      </>
                      :
                      <></>
                    }

                  </div> */}
                </div>

              </div>
              <div className="col-lg-3">

                <OrderSummary complete loading={this.state.odemeyeGecLoading} sozlesme onClickOdemeyeGec={this.state.checkedRemittance === true ? this.paymentProcessHavale : this.odemeyeGec} checkedSozlesme={this._checkedSozlesme} item={this.state.OrderSummaryCardPrice} />
              </div>
            </div>
            {/* Succes Modal */}
            <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showSuccessModal} onHide={this.hideLoginModal}>
                <Modal.Header closeButton>
                </Modal.Header>
                <h4 className="LoginModal__title text-center"> {i18next.t('ProcessSuccess')} </h4>
                <p></p>
                <Modal.Body>
                  <div className="text-center mb-3">
                    <svg className="iconSize-md" >
                      <use href={Sprite + "#smile"} ></use>
                    </svg >
                  </div>
                  <form className="LoginModal  ">
                    <p className="text-center">{i18next.t('OrderSuccess')}</p>
                    <div className="text-center  w-50  mx-auto">
                      <button onClick={() => { history.push('/') }} className="submit-button">{i18next.t('Home')}</button>
                    </div>
                  </form>
                </Modal.Body>
                {/* <Modal.Footer>
                  <div className="KeepInTouch__Img ">
                    <img src={KeepInTouchImg} className="img-fluid"></img>
                  </div>
                </Modal.Footer> */}
            </Modal>
            {/* Error Modal  */}
            <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showErrorModal} onHide={this.hideLoginModal}>
                <Modal.Header closeButton>
                </Modal.Header>
                <h4 className="LoginModal__title text-center">{i18next.t('UnSuccessful')}</h4>
                <p></p>
                <Modal.Body>
                  <div className="text-center mb-3">
                    <svg className="iconSize-md" >
                      <use href={Sprite + "#sad-face"} ></use>
                    </svg >
                  </div>
                  <form className="LoginModal  ">
                    <p className="text-center">{this.state.errorMessage}</p>
                    <div className="text-center  w-50  mx-auto">
                      <a onClick={() => { this.setState({ showErrorModal: false }) }} className="submit-button">{i18next.t('OK')}</a>
                    </div>
                  </form>
                </Modal.Body>
                {/* <Modal.Footer>
                  <div className="KeepInTouch__Img ">
                    <img src={KeepInTouchImg} className="img-fluid"></img>
                  </div>
                </Modal.Footer> */}
            </Modal>
          </div>
        </div >
        <html>
          <head>
            <title>
            </title>
          </head>
          <body>
            <form name="SecureForm" action="https://www.paytr.com/odeme" method="POST"  >
              <input ref={this.buttonRef} style={{ visibility: "hidden" }} type="submit" value="Gönder" />
              <input type="hidden" name="cc_owner" value={this.state.CardOwnerNameSurname} />
              <input type="hidden" name="card_number" value={this.state.CardNumber} />
              <input type="hidden" name="expiry_month" value={this.state.SelectedCartMonth} />
              <input type="hidden" name="expiry_year" value={this.state.SelectedCardYear} />
              <input type="hidden" name="cvv" value={this.state.CardCCVCode} />
              <input type="hidden" name="merchant_id" value={this.state.PaytrMerchantId} />
              <input type="hidden" name="user_ip" value={this.state.PaytrUserId} />
              <input type="hidden" name="merchant_oid" value={this.state.PaytrMerchantOId} />
              <input type="hidden" name="email" value={this.state.UserEmail} />
              <input type="hidden" name="payment_type" value={this.state.PaytrPaymentType} />
              <input type="hidden" name="payment_amount" value={this.state.PaytrPaymentAmount} />
              <input type="hidden" name="currency" value={this.state.PaytrCurrency} />
              <input type="hidden" name="test_mode" value={this.state.PaytrTestMode} />
              <input type="hidden" name="non_3d" value={this.state.PaytrPaymentNon3d} />
              <input type="hidden" name="merchant_ok_url" value={this.state.PaytrMerchantOkUrl} />
              <input type="hidden" name="merchant_fail_url" value={this.state.PaytrMerchantFailUrl} />
              <input type="hidden" name="user_name" value={this.state.UserNameSurname} />
              <input type="hidden" name="user_address" value={this.state.UserAddressDescription} />
              <input type="hidden" name="user_phone" value={this.state.UserPhone} />
              <input type="hidden" name="user_basket" value={this.state.PaytrUserBasket} />
              <input type="hidden" name="debug_on" value={this.state.PaytrDebugOn} />
              <input type="hidden" name="client_lang" value={this.state.PaytrClientLang} />
              <input type="hidden" name="paytr_token" value={this.state.PaymentToken} />
              <input type="hidden" name="non3d_test_failed" value={this.state.PaytrNon3dTestFailed} />
              <input type="hidden" name="installment_count" value={this.state.PaytrInstallmentCount} />
              <input type="hidden" name="card_type" value={this.state.PaytrCardType} />
            </form>
            <script language="text-javascript\" >document.SecureForm.submit();</script>
          </body>
        </html>
      </>
    )
  }
})
export default inject((stores) => ({
  Store: stores.Store,
}))(OdemeYontemi);
