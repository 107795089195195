/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Sprite from '../Assets/Icons/sprite.svg'
import i18next from 'i18next';
export default class ProfilDetailCard extends Component {
  render() {
    const { className1, className2, className3, className4, className5, className6 } = this.props;
    return (
      <div className="ProfilDetailCard">


        {/* <div className="ProfilPopever__header d-flex">
          <h6 class="d-inline" >{i18next.t('MyAccount')}</h6>

        </div> */}

        <div className="ProfilPopever__body">
          <p className={className1}>
            <svg className="iconSize-xxs" >
              <use href={Sprite + "#profile-icon2"} ></use>
            </svg> <a href="/profilim" className="p-size-3">{i18next.t('MyProfile')} </a>
          </p>
          <p className={className2}>
            <svg className="iconSize-xxs" >
              <use href={Sprite + "#truck2"} ></use>
            </svg ><a href="/siparislerim" className="p-size-3"> {i18next.t('MyOrders')}</a>
          </p>
          <p className={`${className3} p-0`}>
            <p className="ProfilPopever__like-button">
              <svg className="iconSize-xxs" >
                <use href={Sprite + "#like-button2"} ></use>
              </svg ><a href="/favorilerim" className="p-size-3">{i18next.t('MyFavorites')}</a>
            </p>
          </p>
          <p className={className4}>
            <svg className="iconSize-xxs" >
              <use href={Sprite + "#map-pin2"} ></use>
            </svg ><a href="/adreslerim" className="p-size-3">{i18next.t('MyAdresses')}</a>
          </p>
          {/* <p className={className5}>
            <svg className="iconSize-xxxs" >
              <use href={Sprite + "#credit-card"} ></use>
            </svg ><a href="/kayitlikartlarim" className="p-size-3"> {i18next.t('MyCreditCards')}</a>
          </p> */}
          {/* <p className={className6}>
            <svg className="iconSize-xxxs" >
              <use href={Sprite + "#gift"} ></use>
            </svg ><a href="#" className="p-size-3">{i18next.t('MyGifts')}</a>
          </p> */}
          <p className="ProfilPopever__logout">
            <svg className="iconSize-xxs" >
              <use href={Sprite + "#log-out2"} ></use>
            </svg ><a onClick={this.onPressLogOut} href="#" className="p-size-3"> {i18next.t('LogOut')}</a>
          </p>

        </div>


      </div>
    )
  }
}
