/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import CategoryCard from '../Components/CategoryCard';
import ProductCard from '../Components/ProductCard';
import InspirationBanner from '../Components/InspirationBanner';
import Slider from "react-slick";
import i18next from 'i18next';
import { inject, observer } from 'mobx-react';
import ApiBase from '../lib/ApiBase';
import Modal from 'react-bootstrap/Modal';
import KeepInTouchImg from '../Assets/Image/KeepInTouch__Img.svg';
import Sprite from '../Assets/Icons/sprite.svg';
import Cookies from 'universal-cookie';
import sliderholder from '../Assets/Image/slider-holder.png';
import bannerholder from '../Assets/Image/banner-holder.png';
import KeepInTouch from '../Components/KeepInTouch';

const Home = observer(class Home extends Component {
  constructor(props) {

    super(props);
    this.state = {
      className: "product-card__like-button",
      name: 'burak',
      favoriteList: [],
      isFavorited: -1,
      BlogList: [],
      RecommendedProducts: [],
      RecommendedProducts2: [],
      SliderList: [],
      favoriteIds: [],
      showErrorModal: false,
      errorMessage: "",
      BannerList: [],
      showWelcomeModal: false,
      popupData: [],
      loadingSlider: true,
      loadingBanner: true
    };

  }

  _
  componentDidMount() {
    // this.getBlogs();
    this.getRecommendedProducts();
    this.getSlider();
    this.getFavoriteIds();
    this.getBanners();
    // this.getPopup();
  }
  //getBlogs
  getBlogs = () => {
    this.setState({ loading: true })
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-blogs',
      data: {
        LanguageId: LangId
      },
      successFunction: (e) => {
        // console.log(e.data.data)
        this.setState({
          BlogList: e.data.data.blogList,
          FeatureBlog: e.data.data.featuredBlog,
          loadingBlogs: false
        });
      },
      errorFunction: (e) => {
        this.setState({ loadingBlogs: false })
        // console.log(e.data.message)
      }
    })
  }
  getPopup = () => {
    this.setState({ loading: true })
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-popup',
      data: {
        LanguageId: LangId
      },
      successFunction: (e) => {
        var date = new Date();
        var expireDate = new Date(date.setTime(date.getTime() + 1 * 86400000));
        const cookies = new Cookies();
        var isAnyCookie = cookies.get('popup');
        if (isAnyCookie === "true") {
          this.setState({ showWelcomeModal: false });
        }
        else {
          this.setState({ showWelcomeModal: true })
          cookies.set('popup', true, { path: '/', expires: expireDate });
        }
        // console.log("sliider", e.data.data)
        this.setState({
          popupData: e.data.data.popup
        });
      },
      errorFunction: (e) => {
        this.setState({ loadingBlogs: false, showWelcomeModal: false })
        // console.log(e.data.message)
      }
    })
  }
  /////
  getSlider = () => {
    this.setState({ loadingSlider: true })
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-slider',
      data: {
        LanguageId: LangId
      },
      successFunction: (e) => {
        // console.log("sliider", e.data.data)
        this.setState({
          SliderList: e.data.data.sliderList,
          loadingSlider: false
        });
      },
      errorFunction: (e) => {
        this.setState({ loadingSlider: false })
        // console.log(e.data.message)
      }
    })
  }
  ////
  toggleFavorite = (item) => {
    const i = this.state.favoriteList.indexOf(item.Id)

    if (i >= 0) {
      this.state.favoriteList.splice(i, 1)
      // console.log("i 0 dan büyük",this.state.favoriteList)
    } else {
      this.state.favoriteList.push(item.Id)
      // console.log("i 0 dan küçük", this.state.favoriteList)
    }
    this.setState({ favoriteList: this.state.favoriteList });
    // // console.log(this.state.favoriteList)
    // console.log("iiii", i)
  };
  ///favoriekle
  AddFavorite = (item, ProductId) => {
    this.setState({ addFavoriteLoading: true })
    var _isLogin = localStorage.getItem("isLogin");
    var cUser = [];
    if (_isLogin === "true") {
      cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
    }
    ApiBase.Post({
      url: '/add-favourite',
      data: {
        ProductId: ProductId,
        CurrentUser: cUser
      },
      successFunction: (e) => {
        // console.log("favoriekle", e.data)
        this.getFavoriteIds();
        this.setState({ addFavoriteLoading: false })
        // this.toggleFavorite(item)
      },
      errorFunction: (e) => {
        this.setState({ showErrorModal: true, errorMessage: e.data.message, addFavoriteLoading: false })
        // console.log(e.data.message)
        if (e.data.message === "Ürün, favori ürünleriniz arasında bulunmaktadır.") {
          // this.toggleFavorite(item)
        }
      }
    })
  }
  removeFavorites = (ProductId) => {
    this.setState({ addFavoriteLoading: true });
    var _isLogin = localStorage.getItem("isLogin");
    var cUser = [];
    if (_isLogin === "true") {
      cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
    }


    ApiBase.Post({
      url: '/delete-favourite',
      data: {
        ProductId: ProductId,
        CurrenUser: cUser
      },
      successFunction: (e) => {
        // console.log("remove", e.data)
        this.setState({ addFavoriteLoading: false })
        this.getFavoriteIds();
        // this.setState({ loading: false })
      },
      errorFunction: (e) => {
        // console.log(e.data.message)
        this.setState({ addFavoriteLoading: false, showErrorModal: true, errorMessage: e.data.message });

      }
    })
  }
  /////
  getBanners = () => {
    this.setState({ loadingBanner: true })
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-banners',
      data: {
        LanguageId: LangId,
      },
      successFunction: (e) => {
        console.log("banner", e.data.data)
        this.setState({
          BannerList: e.data.data.bannerList,
          loadingBanner: false
        });
      },
      errorFunction: (e) => {
        this.setState({ loadingBanner: false })
        // console.log(e.data.message)
      }
    })
  }
  //////
  getRecommendedProducts = () => {
    this.setState({ loading: true })
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-featured-products',
      data: {
        LanguageId: LangId,
      },
      successFunction: (e) => {
        // console.log("name", e.data.data.productList)
        this.setState({
          RecommendedProducts: e.data.data.productList,
          RecommendedProducts2: e.data.data.productList2
        });
      },
      errorFunction: (e) => {
        this.setState({ loadingBlogs: false })
        // console.log(e.data.message)
      }
    })
  }
  getFavoriteIds = () => {
    var _isLogin = localStorage.getItem("isLogin");
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    var favIdList = [];
    ApiBase.Post({
      url: '/get-favorite-ids',
      data: {

        UserId: _UserId,

      },
      successFunction: (e) => {
        // console.log("favoriteıds", e.data.data.List)
        e.data.data.List.map(item =>
          favIdList.push(item.ProductId));
        this.setState({ favoriteIds: favIdList })
        //this.setState({ CartList: e.data.data.List, CartTotalPriceStr: e.data.data.TotalPriceStr })
      },
      errorFunction: (e) => {

        // console.log("hata", e.data.message)
      }
    })
  }
  render() {
    const holders = [];
    for (let index = 0; index < 3; index++) {
      holders.push(<div className="col-lg-4"><div className="category-card d-flex flex-column" style={{ background: `url(${bannerholder})` }}>
      </div></div>)

    }
    var settingsRecom = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: this.state.RecommendedProducts.length < 5 ? this.state.RecommendedProducts.length : 5,
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            arrows: true,
            slidesToShow: this.state.RecommendedProducts2.length < 2 ? this.state.RecommendedProducts2.length : 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            autoplaySpeed: 3000,

          }
        },
        {
          breakpoint: 576,
          settings: {
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            autoplaySpeed: 3000,


          }
        },

      ],
    };
    var settingsRecom2 = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: this.state.RecommendedProducts2.length < 5 ? this.state.RecommendedProducts2.length : 5,
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            arrows: true,
            slidesToShow: this.state.RecommendedProducts2.length < 2 ? this.state.RecommendedProducts2.length : 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            autoplaySpeed: 3000,

          }
        },
        {
          breakpoint: 576,
          settings: {
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            autoplaySpeed: 3000,


          }
        },

      ],
    };

    var settings2 = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 4000,

      responsive: [
        {
          breakpoint: 992,
          settings: {
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 576,
          settings: {
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true

          }
        },

      ],




    };

    const BannerList = this.state.BlogList.map((item, index) => {
      const _className = item.IsWidePhoto === true ? 'col-lg-8' : 'col-lg-4';
      const _className2 = item.IsWidePhoto === true ? 'InspirationBanner__text mt-auto InspirationBanner__text--w ' : 'InspirationBanner__text mt-auto';
      return (
        <div key={item.Id} className={_className}>
          <InspirationBanner isBlog className={_className2} item={item} blogHref={`/blogdetail/${item.Url}`} />
        </div>
      )
    });
    const headerSlider = this.state.SliderList.map(item => {
      return (
        <a key={item.Id} href={item.ContinueUrl} target={item.IsBlank && "_blank"} >
          <header className="headerSlider" >
            {/* <img className="img-fluid" src={item.PhotoPath}></img> */}
            <div className="headerSliderShadowOverlay"></div>
            <div className="headerSliderBackGroundContainer" style={{ background: `url(${item.PhotoPath})` }}></div>
            <div className="headerSlider__texts text-center">
              <div className="headerSlider__header">
                <h1 className="headerSlider__title slider-m-title" style={{ color: item.FontColor }}>{item.Title}</h1>
              </div>
            </div>
          </header>
        </a>
      )
    });
    const banner3list = this.state.BannerList.map(item => {
      return (
        <div className="col-lg-4">
          <div className="category-card text-center" >
            <div >
              <div className="divHomeBannerImageContainer" style={{ background: `url(${item.PhotoPath})` }}></div>
            </div>
            {/* <div className="overlay-categorycard">
              <div className="overlay-categorycard-title">LOREM IPSUM</div>
              <div className="overlay-categorycard-subtitle">DOLOR SIT AMET</div>
            </div> */}
            {/* <a onClick={() => { this.props.history.push(item.ContinueUrl) }} className="category-card__button "
            //  href={item.ContinueUrl}
            >
              {item.Title}
            </a> */}
          </div>
        </div>

      )
    })

    const ListItem = this.state.RecommendedProducts.map((pitem) => {
      return (
        <li className="product-card-slider-item" key={pitem.ProductId} >
          <ProductCard item={pitem} _className={this.state.favoriteIds.indexOf(pitem.Id) >= 0 ? "product-card__like-button active" : "product-card__like-button"} toggleFavorite={() => { this.state.favoriteIds.indexOf(pitem.Id) >= 0 ? this.removeFavorites(pitem.Id) : this.AddFavorite(pitem, pitem.Id) }} ></ProductCard>
        </li>
      );
    });
    const ListItem2 = this.state.RecommendedProducts2.map((pitem, index) => {
      if (index <= 23) {
        return (
          <>
            <div className="col-lg-3 col-6 mx-auto" key={pitem.ProductId} >
              <ProductCard item={pitem} _className={this.state.favoriteIds.indexOf(pitem.Id) >= 0 ? "product-card__like-button active" : "product-card__like-button"} toggleFavorite={() => { this.state.favoriteIds.indexOf(pitem.Id) >= 0 ? this.removeFavorites(pitem.Id) : this.AddFavorite(pitem, pitem.Id) }} ></ProductCard>
            </div>
          </>
        );
      }
    });
    return (
      <>
        <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showWelcomeModal} onHide={() => { this.setState({ showWelcomeModal: false }) }}>
          <Modal.Header closeButton>
          </Modal.Header>
          {/* <h4 className="LoginModal__title text-center">HOŞGELDİN</h4> */}
          <div className="mt-3">
            <a href={this.state.popupData.ContinueUrl}>
              <img src={this.state.popupData.PhotoPath} className="img-fluid"></img>
            </a>
          </div>
          <p></p>
          <Modal.Body>
            {/* <div className="text-center mb-3">
                <svg className="iconSize-md" >
                  <use href={Sprite + "#happy-face"} ></use>
                </svg >
              </div> */}
            <form className="LoginModal  ">
              <p className="text-center">{this.state.errorMessage}</p>
              <div className="text-center  w-50  mx-auto">
                <a href={this.state.popupData.ContinueUrl} className="submit-button">{this.state.popupData.ButtonTitle}</a>
              </div>
            </form>
          </Modal.Body>
          {/* <Modal.Footer>
              <div className="KeepInTouch__Img ">
                  <img src={KeepInTouchImg} className="img-fluid"></img>
                </div>
            </Modal.Footer> */}
        </Modal>
        <>
          {this.state.addFavoriteLoading == true &&
            <div className="favoriteoverlay">

            </div>
          }
          <div id="123" className="Home">
            <div >
              {this.state.loadingSlider == true &&
                <div className="headerSlider">
                  <img src={sliderholder}></img>
                </div>}
              {this.state.loadingSlider != true &&
                <Slider {...settings2} ref={slider => (this.slider1 = slider)}>
                  {headerSlider}
                  {
                    this.slider1 && this.slider1.slickGoTo(0)
                  }
                </Slider>
              }
            </div>
            {this.state.loadingBanner == true &&
              <div className="container">
                <div className="row">
                  {holders}
                </div>
              </div>
            }
            <div className="container text-home-section-title">
              Yeni Gelenler
            </div>
            <div className="divHomeBlackLine"></div>
            <div className="container">
              {/* <div className="product-card">
                <Slider {...settingsRecom2}>
                  {ListItem2}
                </Slider>
              </div> */}
              <div className="row" style={{ marginTop: 30 }}>
                {ListItem2}
              </div>
              <div class="row" style={{ marginTop: 30, marginBottom: 50 }}>
                <div class="col-lg-3 mx-auto text-center">
                  <a href="/searchproducts" className="submit-button">DAHA FAZLASINI KEŞFET</a>
                </div>
              </div>
            </div>
            {/* <div className="container  text-home-section-title">
              {i18next.t('RecentBestSellers')}
            </div> */}
            {/* <div className="container">
              <div className="product-card">
                <Slider {...settingsRecom}>
                  {ListItem}
                </Slider>
              </div>
            </div> */}
            {this.state.loadingBanner != true &&
              <div >
                <div className="row divBannerRow">
                  {banner3list}
                </div>
              </div>
            }
            <KeepInTouch />
            {/* <div className="container">
              <div className=" row mb-5 ">
                <div className="col-lg-6 mx-auto">
                  <div className="col-lg-7 mx-auto">
                    <div className="slider__button">
                      <a onClick={() => { this.props.history.push(`/searchproducts/${false}/${true}`) }}>{i18next.t("AllProducts")}</a> </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="InspirationBanner">
              <div className="container">
                <div className="row">
                  {BannerList}
                </div>
              </div>
            </div> */}
            <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showErrorModal} onHide={() => this.setState({ showErrorModal: false })}>
              <Modal.Header closeButton>
              </Modal.Header>
              <h4 className="LoginModal__title text-center">{i18next.t('UnSuccessful')}</h4>
              <p></p>
              <Modal.Body>
                <div className="text-center mb-3">
                  <svg className="iconSize-md" >
                    <use href={Sprite + "#sad-face"} ></use>
                  </svg >
                </div>
                <form className="LoginModal  ">
                  <p className="text-center  p-size-1">{this.state.errorMessage}</p>
                  <div className="text-center  w-50  mx-auto">
                    <a onClick={() => this.setState({ showErrorModal: false })} className="submit-button">{i18next.t('OK')}</a>
                  </div>
                </form>
              </Modal.Body>
              {/* <Modal.Footer>
                  <div className="KeepInTouch__Img ">
                    <img src={KeepInTouchImg} className="img-fluid"></img>
                  </div>
                </Modal.Footer> */}
            </Modal>
          </div>
        </>
      </>
    )
  }

})

export default inject((stores) => ({
  Store: stores.Store,
}))(Home);