import React, { Component } from 'react'
import BreadCrumb from '../Components/BreadCrumb';
import SozlesmeCard from '../Components/SozlesmeCard';
import SozlesmeCardMobil from '../Components/SozlesmeCardMobil';
import i18next from 'i18next';

export default class ShippingReturns extends Component {
    render() {
        return (
            <>
                <BreadCrumb path={"İPTAL VE İADE KOŞULLARI"}></BreadCrumb>
                <div className="sozlesme body--margin">
                    <div className="container-fluid">
                        <div className="container">
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3  d-none d-lg-block  divLeftCol">
                                <SozlesmeCard className1={"bg--secondary"} />
                            </div>
                            <div className="col-lg-3  d-lg-none UserDetail__section1">
                                <SozlesmeCardMobil active1></SozlesmeCardMobil>
                            </div>
                            <div className="col-lg-9">
                                <div className="divCategoryName mt-3">
                                    İPTAL &amp; İADE KOLULLARI
                                    <div className="divHomeBlackLine"></div>
                                </div>
                                {/* <p className="p-size-1">
                                <p className="ft-link-main"> 1. TARAFLAR  </p>

İşbu Tedarik Sözleşmesi(“Sözleşme”), bir tarafta ……………………………………….. adresinde faaliyet göstermekte olan … sicil müdürlüğüne ………. sicil numarası ile kayıtlı ……………………………………..….. A.Ş. ("Tedarikçi") ile diğer tarafta …………………………………………… adresinde faaliyet göstermekte olan ………sicil müdürlüğüne……………………sicil numarası ile kayıtlı…………………..A.Ş. ("Wondrous") arasında aşağıda belirlenen şartlarda imzalanmıştır.


<p className="ft-link-main">
                                    2.KONU
</p>
Wondrous ile Tedarikçi, fikri sınai mülkiyeti Tedarikçi ’ye ait olan ürünlerin konsinye olarak tedarik ve teslim edilmesi, Wondrous mağazalarında ve internet sitesinde satılması hususunda aşağıda belirtilen şartlar çerçevesinde anlaşmışlardır.


<br></br><br></br>

Taraflar, işbu sözleşme dâhilinde Wondrous’n aldığı ürünleri belirtilen adreste yer alan mağazasının yanı sıra E-Ticaret/Sanal Mağazalarında satması için anlaşmışlardır.

<br></br><br></br>

                                <p className="ft-link-main">
                                    3. TANIMLAR
</p>
İşbu Sözleşme’nin uygulanmasında ve yorumlanmasında aşağıda yazılı terimler, ifadeler veya bunlardan türetilenler karşılarındaki yazılı açıklamaları ifade edeceklerdir.
<br></br><br></br>



                                <span className="ft-link-sub">Ayıplı Ürün:</span> Tedarikçi’nin işbu Sözleşme kapsamında tedarik etmiş olduğu ürünlerden, üretim hatasına bağlı olarak, niteliği etkileyen ve /veya kullanım amacı bakımından değerini azaltan ve/veya ortadan kaldıran maddi eksiklikler içeren mallar, ayıplı ürün anlamındadır.

<br></br><br></br>


                                <span className="ft-link-sub">İnternet Sitesi:</span>İnternet Sitesi: Wondrous’a ait yurt içi ve yurt dışındaki tüm web siteleri anlamındadır.

<br></br><br></br>


                                <span className="ft-link-sub">Kampanya:</span> Wondrous’n ürünleri internet sitesinde belirli bir süre boyunca satışa sunmasıdır.

<br></br><br></br>


                                <span className="ft-link-sub">Konsinye:</span> Wondrous’n Tedarikçi’den aldığı ürünleri, (kararlaştırılan bedel üzerinden) satmak ya da iade etmek biçimindeki seçimlik hakka sahip olduğu alış biçimidir.

<br></br><br></br>


                                <span className="ft-link-sub">Marka:</span> Tedarikçi’nin hak sahibi olduğu “..................” markası anlamındadır.
<br></br><br></br>



                                <span className="ft-link-sub">Nihai Müşteri :</span> Bir mal veya hizmeti satın alan gerçek ya da tüzel kişi anlamındadır.

<br></br><br></br>


                                <span className="ft-link-sub">Ürün veya Ürünler:</span> İşbu Sözleşme uyarınca Tedarikçi tarafından Alıcı’ya satılan ürünleri ifade eder.
<br></br><br></br>


                                <p className="ft-link-main">
                                    4. WONDROUS’N HAK VE YÜKÜMLÜLÜKLERİ
</p>
Wondrous, iş bu sözleşme uyarınca fikri sınai hakkı Tedarikçi’ye ait olan ürün/ürünlerin e­mail, whatssap, instagram, facebook vb. tüm görsel medyayı kullanarak tanıtımımı ve pazarlama yapma hak ve yetkisine sahiptir.
<br></br><br></br>

Wondrous,satın alınacak ürünlere ilişkin siparişleri yazılı olarak (mail ortamı da dahil olmak üzere) verecek; verilen siparişte, ürün türü (varsa kodu ile), miktarı, teslim tarihi, teslimat yeri belirtilecektir. Wondrous tarafında talep edilen ürünün Tedarikçi tarafından temin edilememesi halinde bu durumun derhal Wondrous’a bildirilecektir
<br></br><br></br>

Wondrous; kalıplara, renklere, ölçülere, ebatlara, koleksiyon karmasına, standartlara ve/veya numuneye ve genel olarak uygun olmadığını ya da herhangi bir şekilde ayıplı/hasarlı olduğunu tespit ettiği ürünleri her zaman Tedarikçi’ye iade edebilir. Bu halde, iade edilen Ürünler için Tedarikçi’ye hiçbir ödeme yapılmaz, yapılmış ise geri alınır.
<br></br><br></br>

Wondrous, ayıplı, hasarlı çıkan ürünler ile müşterilerin Tüketicinin Korunması Hakkında Kanun’dan doğan haklı talepleri nedeni ile müşterilerine karşı değişim, bedel iadesi yahut kanundan kaynaklanan diğer nedenlerle bir ödeme veya değişim yapması halinde bu bedelleri derhal Tedarikçi’den talep ve tahsil etme hakkına haizdir.
<br></br><br></br>

Tedarikçi, müşteriler tarafından tamir edilmek/onarılmak üzere Wondrous'a verilen ürünleri ücret talep etmeksizin ve her halükarda 7 iş gününden fazla olmamak üzere makul sure içinde onararak iade etmeyi kabul etmiştir.
<br></br><br></br>



Wondrous’n sözleşmenin diğer ilgili madde hükümleri ile tüketici mevzuatına göre karşıladığı müşteri taleplerine ilişkin rücu hakları her halükarda ve ayrıca saklıdır
<p className="ft-link-main">
                                    5. TEDARİKÇİNİN HAK VE YÜKÜMLÜLÜKLERİ
</p>
Tedarikçi, Wondrous tarafından sipariş verildikten sonra ürünleri, taraflarca kararlaştırılan tarihte ve kararlaştırılan yerde teslim etmeyi kabul, beyan ve taahhüt etmiştir. Tedarikçi herhangi bir sebeple ürünleri zamanında teslim etmediği takdirde, eksik teslimat sebebiyle Wondrous’n uğrayacağı her türlü zararı tazmin etmekle yükümlüdür.
<br></br><br></br>

Tedarikçi, ürünleri Wondrous tarafından bildirilecek yere (depolama hizmeti aldığı firmalar ile mağazalar dahil), gösterilen şekil ve usulde teslim eder. Wondrous teslim tarihinden 2 gün önce bildirmek suretiyle teslim yerini her zaman değiştirebilir. Bildirilen yere teslim edilmeyen ürünler, söz konusu yer Wondrous’a ait bir yer olsa dahi teslim edilmemiş sayılır ve her türlü sorumluluk Tedarikçi firmaya ait olur.

<br></br><br></br>


Ürünlerin teslim tarihinden farklı bir zamanda teslim edilebilmesi Wondrous'n yazılı onayına tabidir. Ürünlere ilişkin her türlü yükleme, indirme ve nakliye giderleri, nakliye sigortası masrafları ve sair tüm giderler ile hasar, zarar ve ziyandan sorumluluk Tedarikçi'ye aittir.
<br></br><br></br>


Sipariş yazısı ve/veya sevk irsaliyesi ve/veya irsaliyeli faturada yazılı ürün ile teslim edilmek istenilen ürün arasında cins, miktar, model, renk, kod vs. farklılıklar bulunması durumunda, bu farklılık gerek teslimat sırasında ve gerekse teslimattan sonra tespit edilsin;  nakliye masrafları Tedarikçi’ye ait olmak üzere, faturası düzenlenmiş ise faturası ile birlikte veya iade faturası düzenlemek suretiyle,Wondrous hiçbir bildirimde bulunmak zorunda olmaksızın iade etmek hakkına sahiptir

<br></br><br></br>


Tedarikçi herhangi bir sebeple ürünleri zamanında teslim etmediği takdirde, eksik teslimat sebebiyle Wondrous’n uğrayacağı her türlü zararı tazmin etmekle yükümlüdür.
<br></br><br></br>

Tüketici mevzuatı ve sair ilgili mevzuat uyarınca, ürün üzerinde bulunması ve/veya ürün ile birlikte tüketiciye verilmesi gereken tanıtım/kullanma kılavuzlarına, sembollere, etiketlere ve benzerlerine ilişkim tüm kanuni yükümlülükler, masrafları kendisine ait olmak üzere Tedarikçi tarafından yerine getirilir.


<br></br><br></br>

Tedarikçi tarafından Wondrous’a  teslim edilen ürün için Wondrous  tarafından hiçbir ihtirazi kayıt yazılmaksızın tesellüm kağıdı verilmiş olması, söz konusu malın ayıpsız ve/veya sözleşmeye uygun olduğunu göstermemektedir. Wondrous, Tedarikçi tarafından usulüne uygun olarak teslim edilen malı muayene ederek ambalajın dış yüzeyinde herhangi bir ayıbın tespitinden itibaren, Tedarikçi’ye yazılı olarak ihbar edecektir.  Ayıplı malın müşteri  tarafından tespiti halinde, ayıp ihbarının Tedarikçi’ye yazılı olarak ihbarını takiben  ayıplı ürünlerin ayıplarının giderilmesini veya ayıpsız olanı ile değiştirilmesini Tedarikçi’den talep edebileceği gibi bu ürünlerin iadesini de talep edebilir. Her iki durumda da Tedarikçi, Wondrous’n taleplerini ….. gün içerisinde yerine getirecektir. Aksi halde Wondrous tüketicilere ve resmi mercilere ödemek durumunda kalacağı her türlü menfi ve müspet  zarar ve ziyan için Tedarikçi firmaya rücu hakkına sahiptir. Bu hakkını derhal, nakden ve defaten ödenmek üzere talep edebileceği gibi dilerse Tedarikçi'ye yapacağı ödemelerden derhal mahsup sureti ile de temin edebilir.
<p className="ft-link-main">
                                    6. SİPARİŞ
</p>
Siparişler, …… tarafından belirlenen tarihlerde ürün numuneleri görülerek yahut kataloglardan seçilerek verilir. Wondrous tarafından verilen siparişler, konsinye olarak sağlanacağından ürünleri sezon sonunda iade etme hakkı mevcuttur.
<br></br><br></br>

Verilen siparişler, Tedarikçi ve Wondrous’n imza sirkülerinde imza yetkilisi olarak belirtilen kişilerin Sipariş formunu imzalamaları ile geçerlilik kazanır. Sipariş formu iki nüsha olarak düzenlenir bir nüshası Wondrous’a teslim edilir.
<p className="ft-link-main">
                                    7. TESLİMAT
</p>
Wondrous tarafından verilen siparişlerin teslimatı doğrudan Wondrous’n deposuna/mağazasına veyahut bildireceği farklı bir adrese yapılır.
<p className="ft-link-main">
                                    8. ÖDEME
</p>


Taraflar, konsinye şartları ve geri iade koşulu ile anlaşmışlardır. Sözleşme konusu konsinye alımlarda Wondrous; Tedarikçi’ye ilgili ayın sonunda müşterilere satışı gerçekleşen ürünlerin stok kod numaraları, satış adetleri ve perakende satış tutar(lar)ından oluşan satış bilgisini her ay/haftanın  ilk iş günü bildirir.

<br></br><br></br>


Wondrous, Tedarikçi’ye  fatura kesim tarihinde ödeme yapılacaktır. Ödemeler nakit havale şeklinde yapılacak olup TR……………………………………………………….. banka hesabına banka transferi yolu ile yapılacaktır.


<br></br><br></br>

Wondrous, belirlenen vadelerin dışındaki zamanlarda Dorimu’ya ödeme yapamayacaktır. Wondrous’n, fatura tarihinden itibaren …. gün içinde yapılmamış, gecikmiş ödemeler ile ilgili borçları bulunuyorsa, Tedarikçi firma öncelikle yazılı olarak Wondrous’a  ihtarda bulunacaktır, Wondrous mümkün olan en kısa sürede ödeme yapacaktır. Tedarikçi geciken ödemeler nedeniyle sözleşmeyi feshetmeyeceğini kabul ve taahhüt eder.

Taraflar, konsinye alımlarda,  her sezon sonunda satılmayan veya müşteri tarafından iade edilen ürünlerin tamamının Tedarikçi’ye iade edilmesini takiben, son iade irsaliyesi tarihinden itibaren en geç 30 (otuz) gün içerisinde ilgili sezon için stok hesap mutabakatı yaparlar. Tedarikçi sözleşme konusu ürünlerin Wondrous tarafından teslim alınmasını takiben işbu  ürünlerin  faturasını 15 (on beş̧) gün içinde düzenleyerek Wondrous ‘a gönderecektir.
<p className="ft-link-main">
                                    9.GİZLİLİK
</p>
Taraflar’a ait her türlü mali tablo, rapor, finansal ve hukuksal bilgiler, marka, üyelik bilgileri, ticari sır ya da diğer yasal korumaya konu olan ya da olmayan her türlü bilgi, söz konusu ticari ilişki esnasında tarafların ve çalışanlarının öğrenecekleri yazılı ve/veya sözlü tüm ticari, mali, teknik bilgiler, müşteri ve piyasa bilgileri ve diğer bilgi ve veriler “Gizli Bilgi” olarak kabul edilir.

<br></br><br></br>


İşbu Sözleşme ihlal edilmeksizin hukuki ve meşru yollarla genel kamunun bilgisi dâhilinde olan ve tarafların gizli olmadığını açıkça ve yazılı olarak belirttiği bilgiler Gizli Bilgi kapsamında değerlendirilmeyecektir. Taraflar yürürlükte olan bir kanun veya diğer düzenleme ya da verilmiş olan bir mahkeme kararı veya idari emir gereğince bu bilgileri açıklayabilir. Bu durumda birbirlerine bu hususta derhal ve her halde 2 (iki) iş günü içerisinde bilgi vereceklerdir. Taraflar bu madde kapsamında değerlendirilebilecek her türlü bilgi ve veriyi korumayı, her ne suretle olursa olsun 3. Kişilerle doğrudan/dolaylı olarak paylaşmamayı ve ticari ilişkinin amaçları dışında kullanmamayı taahhüt ederler. İşbu madde, sözleşme süresi sona erdiğinde dahi Taraflar arasında geçerli olmaya devam edecektir.
<p className="ft-link-main">
                                    10. REKABET VE MÜNHASIRLIK ŞARTLARI
</p>
Ürün/ürünlerin fiyatının belirlenmesi ve değiştirilmesi hususu tarafların karşılıklı mutabakata varacakları bedel üzerinden gerçekleşecektir.
<br></br><br></br>

Kampanyalı ürün fiyatları, indirimli satış dönemlerinde piyasa koşulları ve Tedarikçi’nin bizzat kendisinin satışa arz ettiği indirimli satış fiyatları dikkate alınarak, tarafların müştereken mutabakata vardıkları bedel üzerinden belirlenecektir.
<br></br><br></br>

Tedarikçi, iş bu sözleşme devam ettiği sürece tarafların karşılıklı ve yazılı mutabakatları bulunmadıkça Wondrous’n bulunduğu ilçe sınırlarında benzer sektörde çalışan diğer bir firma ve/veya firmalara ürün tedarikinde bulunmayacağını kabul ve taahhüt eder.
<p className="ft-link-main">
                                    11. MÜCBİR SEBEP
</p>
İşbu sözleşmenin imzalandığı tarihte var olmayan, öngörülemeyen ve tarafların kontrolü dışında gelişen olayların ortaya çıkması ile tarafların, yüklendikleri borç, görev ve sorumluluklarını kısmen veya tamamen zamanında yerine getirilmelerini imkânsızlaştıran haller Mücbir Sebepler olarak kabul edilecektir. İşbu Mücbir Sebeplerin ve diğer imkansızlık hallerinin meydana gelmesi durumunda etkilenen taraf diğer tarafı derhal yazılı olarak haberdar edecektir. Mücbir sebebe dayanan taraf mücbir sebebin varlığını ve illiyet bağını ispatla mükelleftir. Yukarıda belirtilen Mücbir sebeplerin tevsik şekilleri ilgili ülkenin resmi makamları vasıtasıyla olacaktır.
<br></br><br></br>

Yukarıdaki sebeplerden birinin veya birden fazlasının oluşması halinde taraflara edimlerini ifa etmesi için 30 gün ek süre verilecektir. Ancak belirtilen mücbir sebep nedeniyle edimin yerine getirilmesinin imkânsız hale gelmesi halinde, taraflar iyi niyetle durumu değerlendirerek karşılıklı olarak işbu sözleşmenin feshi cihetine gidebilirler.
<br></br><br></br>

 Taraflardan birinin Sözleşme ‘de mücbir sebepler dışında kendisine verilen herhangi bir hakkı kullanmaması ya da icra etmemesi, söz konusu haktan feragat ettiği anlamına gelmeyecek veya söz konusu hakkın daha sonra kullanılmasını ya da icra edilmesini engellemeyecektir
 <p className="ft-link-main">
                                    12. SÜRE
</p>
Sözleşme süresi imzalanma tarihinden itibaren ….. yıldır.
<br></br><br></br>

Sözleşmenin sona erme tarihinden evvel 1 ay öncesini geçmemek kaydı ile, Wondrous tarafından yazılı olarak sözleşmenin yenilenmesi iradesinin bildirilmesi ile taraflar bir araya gelerek yeni bir sözleşme akdederler.  Aksi takdirde sözleşme süresi sonunda kendiliğinden sona ermiş sayılacaktır.


<p className="ft-link-main">
                                    13. TEMLİK
</p>
Taraflar, diğer tarafın yazılı onayını almadan işbu Sözleşme’den kaynaklanan hak, alacak ve yükümlülüklerini üçüncü kişilere devir ve temlik edemez.


<p className="ft-link-main">
                                    14. KİŞİSEL VERİLERİN KORUNMASI
</p>


Taraflar, kişisel veriler hususunda 6698 sayılı Kişisel Verilerin Korunması Hakkındaki Kanun hükümleri uyarınca işbu Sözleşmeyle elde ettiği kişisel verileri yasaya uygun şekilde veya meşru amaçla işler, muhafaza eder ve gerektiğinde imha eder. Taraflar işbu sözleşmeyi imzalamak suretiyle kişisel verilerinin diğer tarafça gerekli ölçüde işlenmesine açıkça onay vermiş sayılırlar.
<p className="ft-link-main">
                                    15. FESİH
</p>
 Wondrous, 30 gün öncesinden Tedarikçi’ ye bildirerek sözleşmeyi her zaman tek taraflı olarak fesih hakkına sahiptir.
 <p className="ft-link-main">
                                    16. İHBAR
</p>
Taraflardan birinin diğer tarafa yapacağı tüm ihtar, ihbar, bildirim ve uyarılar noter marifetiyle tarafların işbu sözleşmede belirtilen adreslerine yapılacak olup, aksi takdirde geçersiz sayılacaktır. Taraflar herhangi bir adres ya da iletişim bilgisi değişikliği halinde, diğer tarafı en geç değişikliği takip eden 48 saat içinde haberdar edecektir.


<p className="ft-link-main">
                                    17. SÖZLEŞMENİN BÜTÜNLÜĞÜ
</p>
Sözleşme hükümlerinde değişiklik ancak tarafların imzasını taşıyan ek sözleşme imzalanması suretiyle gerçekleştirilebilir.


<p className="ft-link-main">
                                    18.FERAGAT
</p>
Hiçbir feragat yazılı olarak ve Taraflarca yapılmadıkça geçerli olmayacaktır. Taraflardan herhangi birinin bu Sözleşme’nin herhangi bir hükmünün veya şartının sağlanmasını talep etmemesi ya da herhangi bir tarafın işbu Sözleşme’nin herhangi bir ihlalinden doğan talep hakkından feragat etmesi, söz konusu hüküm veya şartın bundan sonra uygulanmasını engellemeyecektir ve bir sonraki ihlalden feragat olarak sayılmayacaktır.

<br></br><br></br>


UYUŞMAZLIKLARIN ÇÖZÜMÜ
<br></br><br></br>

Taraflar arasında bu sözleşmeden doğabilecek uyuşmazlıklarının çözümünde Wondrous’n ticari defterleri ve kayıtları kesin delil oluşturur.
<br></br><br></br>



Uyuşmazlıkların çözümünde İzmir Mahkemeleri ve İcra Müdürlükleri yetkilidir.
<br></br><br></br>



TEBLİGAT
<br></br><br></br>

Taraflar, yukarıda yazılı adreslerinin kanuni tebligat adresleri olduğunu, adres değişikliği diğer tarafa 2 gün içinde yazılı olarak bildirilmediği sürece bu adreslere ve/veya Sözleşme’ye uygun olarak bildirilmiş güncel adrese yapılacak bildirimlerin kanunen geçerli tebligatın bütün hukuki sonuçlarını doğuracağını kabul, beyan ve taahhüt ederler.

<br></br><br></br>


DAMGA VERGİSİ
<br></br><br></br>

Sözleşmeye ilişkin doğabilecek damga vergisi taraflarca eşit olarak karşılanır.

<br></br><br></br>


YÜRÜRLÜK
<br></br><br></br>

İki (2) nüsha, 22 (yirmi iki) maddeden oluşan işbu sözleşme taraflarca ………. tarihinde imzalanarak akdedilmiş ve yürürlüğe girmiştir.






                            </p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
