/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Sprite from '../Assets/Icons/sprite.svg';

export default class DeliveryInformation extends Component {

  render() {
    const { item, myAddress, orderDetail, locationItem } = this.props;
    return (
      <div className="AddressCard">
        <div className="row ">
          <div className="col-12">
            <h6 className="deliveryTitle">
              {item.AddressName}
            </h6>
            <span className="receiver__Name mr-2">
              {item.NameSurname}
            </span>
            <span className="receiver__Phone">
              {item.PhoneStr}
            </span>
            <p className="receiver__Address receiver__Address--opacity">
              {item.AddressDescription}
            </p>
            <p className="receiver__DistrictAndProvince receiver__Address--opacity">
              {orderDetail ? locationItem.District : item.Location.District} , {orderDetail ? locationItem.City : item.Location.City}
            </p>
            <p className="receiver__CountyAndPostalCode receiver__Address--opacity">
              {orderDetail ? locationItem.Country : item.Location.Country} / {item.ZipCode}
            </p>
          </div>
          {myAddress &&
            <div className="col-2 d-flex flex-column flex-lg-row  ">
              <span className="mr-2 my-2">
                <a onClick={this.props.editAddress} >
                  <svg className="iconSize-xxxs" >
                    <use href={Sprite + "#edit"} ></use>
                  </svg >
                </a>
              </span>
              <span className="my-2">
                <a onClick={this.props.deleteAddress}>
                  <svg className="iconSize-xxxs" >
                    <use href={Sprite + "#trash"} ></use>
                  </svg >
                </a>
              </span>
            </div>}
        </div>



      </div>
    )
  }
}
