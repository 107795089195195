/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Sprite from '../Assets/Icons/sprite.svg'
import i18next from 'i18next';
import ApiBase from '../lib/ApiBase';
import { inject, observer } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import KeepInTouchImg from '../Assets/Image/KeepInTouch__Img.svg';
const FavoriItems2 = observer(class FavoriItems2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVariationId: 0,
      addCartLoading: false,
      showErrorModal: false
    };

  }
  
  AddToCart = (item) => {
    this.setState({ addCartLoading: true })

    var AUID = localStorage.getItem("AnonymousUserId")
    var _isLogin = localStorage.getItem("isLogin");
    // console.log(_isLogin)
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    if (item.IsMainVariation == false && item.Variations.length <= 0) {
      this.setState({ showErrorModal: true, addCartLoading: false, errorMessage: i18next.t("NOSTOCK") })
    }
    else {
      ApiBase.Post({
        url: '/add-to-cart',
        data: {
          LanguageId: LangId,
          ProductId: item.ProductId,
          VariationId: this.state.selectedVariationId,
          IsMainVariation: item.IsMainVariation,
          AnonymousUserId: AUID,
          Quantity: 1,
          UserId: _UserId

        },
        successFunction: (e) => {
          // console.log("sepeteekle", e.data)

          // console.log("categories",e.data.data.product.Categories[0].NameTr)
          this.setState({ addCartLoading: false, })
          this.props.notify();
          this.getCartCount();
        },
        errorFunction: (e) => {
          this.setState({ addCartLoading: false, showErrorModal: true, errorMessage: e.data.message })
          // console.log(e.data.message)
        }
      })
    }
  }
  AddToCart2 = (item) => {
    this.setState({ addCartLoading: true })

    var AUID = localStorage.getItem("AnonymousUserId")
    var _isLogin = localStorage.getItem("isLogin");
    // console.log(_isLogin)
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    if (item.IsMainVariation == false && item.Variations.length <= 0) {
      this.setState({ showErrorModal: true, addCartLoading: false, errorMessage: i18next.t("NOSTOCK") })
    }
    else {
      ApiBase.Post({
        url: '/add-to-cart',
        data: {
          LanguageId: LangId,
          ProductId: item.ProductId,
          VariationId: this.state.selectedVariationId,
          IsMainVariation: item.IsMainVariation,
          AnonymousUserId: AUID,
          Quantity: 1,
          UserId: _UserId

        },
        successFunction: (e) => {
          // console.log("sepeteekle", e.data)

          // console.log("categories",e.data.data.product.Categories[0].NameTr)
          this.setState({ addCartLoading: false, })
          this.props.notify();
          this.getCartCount();
          window.location.href = "/sepetim";

        },
        errorFunction: (e) => {
          this.setState({ addCartLoading: false, showErrorModal: true, errorMessage: e.data.message })
          // console.log(e.data.message)
        }
      })
    }

  }
  notify = () => toast.success("Sepete Eklendi !");
  getCartCount = () => {
    var AUID = localStorage.getItem("AnonymousUserId")
    var _isLogin = localStorage.getItem("isLogin");
    // console.log(_isLogin)
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    this.props.Store.CartStore.GetCartCount(_UserId, AUID)
  }
  onChangeVariationValue = (item) => {
    this.setState({ selectedVariationId: item.value }, () => {
      // console.log(this.state.selectedVariationId)
    });
  }
  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        fontSize: 12,
        color: 'black',
        backgroundColor: "transparent",

        '&:hover': {
          backgroundColor: "#F3F0E9"

        },
        '&:focus': {
          backgroundColor: "#F3F0E9"

        },
      }),

      control: (provided, state) => ({
        ...provided,
        marginTop: "5%",
        borderWidth: 0,
        borderBottomWidth: "1px",

        borderColor: '#E5E5E5;',
        backgroundColor: "transparent",
        borderRadius: 0,
        outline: "transparent",
        boxShadow: state.isFocused ? '0 0 0 0.2rem transparent' : '',
        '&:focus': {
          borderColor: "#F3F0E9",
          '&:hover': {
            borderColor: "#F3F0E9"
          },
        },
        "@media only screen and (max-width: 992px)": {
          ...provided["@media only screen and (max-width: 992px)"],
          minHeight:  28,
        },
      }),
      container: (provided) => ({
        ...provided,
        width: "100%",

        //  backgroundColor:'red',
        '&:focus': {
          borderColor: "#F3F0E9",

        },
        "@media only screen and (max-width: 992px)": {
            ...provided["@media only screen and (max-width: 992px)"],
            width: "50%",
          },
      }),
      valueContainer: (provided) => ({
        ...provided,
        justifyContent: 'center',
        "@media only screen and (max-width: 992px)": {
          ...provided["@media only screen and (max-width: 992px)"],
          fontSize:  12,
        },

      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        //  backgroundColor:'red',
        color: ' #6B7070',
        borderLeft: 0

      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        width: 0,
        "@media only screen and (max-width: 992px)": {
          ...provided["@media only screen and (max-width: 992px)"],
          height: "26px",
        },
      }),
      indicatorsContainer: (provided) => ({
        ...provided,
        //  backgroundColor:'red',
        "@media only screen and (max-width: 992px)": {
          ...provided["@media only screen and (max-width: 992px)"],
          height: "26px",
        },

      }),

    }
    const { item, selectedValue, optionItems, url } = this.props;
    const sizeOptions = [];
    item.Variations.map(item => {
      return sizeOptions.push({ value: item.VariationId, label: item.Stock <= 0 ? item.Name + " (Stok Tükendi)" : item.Name })
    });
    return (
      <div className=" product-card__body divFovarilerimSatir">
        <div className="row">
          <div className="col-lg-6 d-flex col-10 align-items-center pr-0">
            <a href={url}>
              <img src={item.MainPhoto} className=" mr-2 mr-lg-4" alt={item.Title} style={{ width: 130, height: 118, objectFit:"contain" }} ></img>
            </a>
            <div className="w-100">
              <p className="product-card__title_detail h-size-3 mb-2">{item.Title}</p>
              <p className="product-card__brand ">{item.DesignerName}</p>
              <div className="d-block d-lg-none w-100 my-2">
                {item.Variations.length > 0 ?
                  <>
                    <Select
                      placeholder={"Varyasyon Seçiniz"}
                      styles={customStyles}
                      options={sizeOptions}
                      onChange={this.onChangeVariationValue}
                    >
                    </Select>
                  </>
                  :
                  <></>
                }
              </div>
              {/* <p className="product-card__size font-size-1 my-2"> <span className="font--opacity"> {i18next.t("SizeUp")} :</span> {item.Size}</p> */}
              {item.DiscountedPrice > 0 &&
                <span className="product-card__oldPrice"> <del>{parseFloat(item.Price).toFixed(2)} TL</del></span>
              }
              {item.DiscountedPrice > 0 ?
                <span className="product-card__price font-size-3 p-size--b mb-0"> {parseFloat(item.DiscountedPrice).toFixed(2)} TL</span>
                :
                <p className="product-card__price font-size-3 p-size--b mb-0"> {parseFloat(item.Price).toFixed(2)} TL</p>
              }
            </div>
          </div>
          <div className="col-4 offset-lg-1 d-none d-lg-flex  align-items-center justify-content-center">
            {item.Variations.length > 0 ?
              <>
                <Select
                  placeholder={"Varyasyon Seçiniz"}
                  styles={customStyles}
                  options={sizeOptions}
                  onChange={this.onChangeVariationValue}
                >
                </Select>
              </>
              :
              <></>
            }
          </div>
          <div className="col-1  align-items-center d-flex justify-content-center">
            <a onClick={this.props.removeItem} >
              <svg className="iconSize-xs" >
                <use href={Sprite + "#deleteProduct"} ></use>
              </svg >
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 p-1 ">
            <a onClick={() => { this.AddToCart(item) }} className={this.state.addCartLoading === true ? "submit-button2 w-100 loading" : " submit-button2 w-100 "}>
              <svg className="iconSize-xxs mr-2" >
                <use href={Sprite + "#menu-shopping"} ></use>
              </svg >
              {i18next.t('AddtoCart')}</a>
          </div>
          <div className="col-lg-6 p-1 ">
            <a onClick={() => { this.AddToCart2(item) }} className={this.state.addCartLoading === true ? "submit-button w-100  loading" : " submit-button w-100 "}>
              {/* <svg className="iconSize-xxs mr-2" >
                <use href={Sprite + "#menu-shopping"} ></use>
              </svg > */}
              HEMEN SATIN AL</a>
          </div>
          {/* <div className="col-6  col-lg-3 p-1">
            <a href="#" className=" UserDetailBuyNow w-100 p-size-3 p-size--b">

              {i18next.t("BuyNow")}</a>
          </div> */}
        </div>
        {/* <ToastContainer
        /> */}
        <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showErrorModal} onHide={() => { this.setState({ showErrorModal: false }) }}>
            <Modal.Header closeButton>
            </Modal.Header>
            <h4 className="LoginModal__title text-center">BAŞARISIZ</h4>
            <p></p>
            <Modal.Body>
              <div className="text-center mb-3">
                <svg className="iconSize-md" >
                  <use href={Sprite + "#sad-face"} ></use>
                </svg >
              </div>
              <form className="LoginModal  ">
                <p className="text-center">{this.state.errorMessage}</p>
                <div className="text-center  w-50  mx-auto">
                  <a onClick={() => { this.setState({ showErrorModal: false }) }}  className="submit-button">{i18next.t('OK')}</a>
                </div>
              </form>
            </Modal.Body>
            {/* <Modal.Footer>
              <div className="KeepInTouch__Img ">
                <img src={KeepInTouchImg} className="img-fluid"></img>
              </div>
            </Modal.Footer> */}
        </Modal>
      </div>
    )
  }
})
export default inject((stores) => ({
  Store: stores.Store,
}))(FavoriItems2);
