/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import BreadCrumb from '../Components/BreadCrumb';
import Form from 'react-bootstrap/Form';
import i18next from 'i18next';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import Sprite from '../Assets/Icons/sprite.svg';
import moment from 'moment';
import CareerPhoto from '../Assets/Image/career-form.png';
import InputMask from 'react-input-mask';
import ApiBase from '../lib/ApiBase';
import KeepInTouchImg from '../Assets/Image/KeepInTouch__Img.svg';
import Modal from 'react-bootstrap/Modal';

export default class Career extends Component {
  constructor(props) {
    super(props);
    this.state = {

      uploadFile: null,
      uploadFileName: '',
      NameSurname: "",
      PhoneNumber: "",
      DateOfBirth: new Date(),
      dateClass: "DatePicker",
      fileBase64: "",
      selectedGender: 0,
      fileExtension: "",
      Message: '',
      Email: "",
      showSuccesModal: false,
      showErrorModal: false,
      errorMessage: ""
    }

  }

  handleCloseDatePicker = () => {
    this.setState({ dateClass: "DatePickerSelected" })
  }

  handleFile = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      let textExt = file.name.split(".")
      this.setState({ uploadFile: file, fileBase64: reader.result, uploadFileName: file.name, fileExtension: textExt.reverse()[0] }, () => { console.log(this.state.fileExtension,) })
    }
    // this.setState({
    //   uploadFileName: e.target.files[0].name,
    //   uploadFile: e.target.files[0],
    // }, () => {
    //   console.log(this.state.uploadFile)
    // })
  };

  sendCareerInformation = () => {
    this.setState({ loading: true })

    ApiBase.Post({
      url: '/add-career-form',
      data: {
        //  FilePath: this.state.fileBase64,
        NameSurname: this.state.NameSurname,
        Phone: this.state.PhoneNumber,
        BirthDate: this.state.DateOfBirth,
        Extension: this.state.fileExtension,
        Gender: this.state.selectedGender,
        Message: this.state.Message,
        Email:this.state.Email
      },
      successFunction: (e) => {
        console.log("name", e.data)
        this.setState({ loading: false, showSuccesModal: true })
      },
      errorFunction: (e) => {
        this.setState({ loading: false, showErrorModal: true, errorMessage: e.data.message })
        console.log(e.data.message)
      }
    })
  }

  handleChangeGender = (e) => {
    this.setState({ selectedGender: e.value })
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        fontSize: 12,
        color: 'black',
      }),
      control: (provided) => ({
        ...provided,

        borderWidth: 0,
        borderBottomWidth: "1px",
        borderColor: '#EDE8DE',
        backgroundColor: "transparent",
        borderRadius: 0,

      }),

      dropdownIndicator: (provided) => ({
        ...provided,
        //  backgroundColor:'red',
        color: '#B29F78',
        borderLeft: 0

      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        width: 0
      }),
      valueContainer: (provided) => ({
        ...provided,
        padding: 0
      }),
      placeholder: (provided) => ({
        ...provided,
        color: "#CEC2A6"
      })


    }
    const options = [
      { value: 2, label: 'Kadın' },
      { value: 1, label: 'Erkek' },
      { value: 3, label: 'Belirtmek İstemiyorum' },
    ]

    return (
      <div className="ApplyDesigner">
        <div className="container">
          <BreadCrumb path={i18next.t("Careers")} />
          <h2 className="h-size-4 text-center mt-4">{i18next.t("WORKWITHUS")}</h2>
          <div className="row mt-4 ">
            <div className="col-lg-10 mx-auto">
              <p className="p-size-1">İzmir’in merkezi olan Alsancak’ta yaklaşık 200 metrekarelik bir showroomda, İzmir’de örneği bulunmayan yeni nesil bir alışveriş deneyimini müşterilerimize sunmak üzere hizmet vermekteyiz.
              <br></br> <br></br>
              Alanında profesyonel tasarımcıların tasarım ürünlerinin yer aldığı özel mimarili mağazamızda, Wondrous ailesinin genç ve dinamik ekibinin bir parçası olmak istersen aşağıdaki formu doldurabilirsin.  </p>
            </div>


          </div>

          <div className=" ApplyDesigner__applyForm row d-flex align-items-center ">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-10">
                  <Form className="iletisim">
                    <div className=" mb-3 ">
                      <div className="input-field">
                        <input type="text" id="name" required value={this.state.NameSurname} onChange={(e) => this.setState({ NameSurname: e.target.value })} />
                        <label htmlFor="name">{i18next.t("NameSurname")}</label>
                      </div>
                    </div>
                    <div className=" mb-3 ">
                      <div className="input-field">
                        <input type="text" id="email" required value={this.state.Email} onChange={(e) => this.setState({ Email: e.target.value })} />
                        <label htmlFor="email">{i18next.t("Email")}</label>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="input-field">
                        {/* <input type="tel" id="telno" required  value={this.state.NameSurname} onChange={(e) => this.setState({ NameSurname: e.target.value })} /> */}
                        <InputMask mask="(999) 999 99 99"
                          className="c-input"
                          maskChar={null}
                          placeholder
                          type="tel"
                          id="telno"
                          value={this.state.PhoneNumber}
                          onChange={(e) => { this.setState({ PhoneNumber: e.target.value }) }}
                          required>

                        </InputMask>
                        <label htmlFor="telno">{i18next.t("PhoneNumber")}</label>
                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="input-field">
                        {/* <DatePicker
                          selected={this.state.DateOfBirth}
                          onChange={(newDate) => this.setState({ DateOfBirth: moment(newDate).format("DD-MM-yyyy") }, () => { console.log(this.state.DateOfBirth) })}
                          disabledKeyboardNavigation
                          placeholderText="This has disabled keyboard navigation"
                        
                        /> */}
                        {/* <DatePicker
                          selected={this.state.startDate} id="dt"
                          onChange={(newDate) => this.setState({ DateOfBirth: moment(newDate).format("DD-MM-yyyy") }, () => { console.log(this.state.DateOfBirth) })}
                          // className={this.state.dateClass}
                          placeholderText={i18next.t("BirthdayDate")}
                          value={this.state.DateOfBirth}
                         onCalendarClose={this.handleCloseDatePicker} 
                          /> */}


                      </div>


                    </div>

                    <div className="mb-3 mt-0">
                      <Select
                        placeholder={i18next.t("Gender")}

                        styles={customStyles}
                        options={options}
                        onChange={this.handleChangeGender}

                      >
                      </Select>

                    </div>
                    <div className="input-field MusteriHizmetleri__textarea">

                      <textarea placeholder="Mesajınız" id="messageBoxForIletisim" className="w-100  " rows="3"
                        required value={this.state.Message} onChange={(e) => { this.setState({ Message: e.target.value }) }} ></textarea>




                    </div>
                    {/* <form>
                    
                     
                        <input type="file" class="form-control-file" id="exampleFormControlFile1" />
                      
                    </form> */}
                    {/*   */}

                    {/* <form method="POST" action="#" enctype="multipart/form-data">

                      <label className="p-size--color3 font--opacity" htmlFor="CV">CV</label>

                      <div class="form-group mb-0 cvChooseafile--border ">
                        <div class="input-group " name="Fichier1">
                          <input type="text" class=" form-control cvChooseafile border-0" placeholder={i18next.t("Chooseafile")} value={this.state.uploadFileName == '' ? null : this.state.uploadFileName} />
                          <div class="input-group-append ">
                            <label for="file-upload" class="custom-file-upload border-0">
                              <svg className="iconSize-xxs" >
                                <use href={Sprite + "#upload"} ></use>
                              </svg ></label>
                            <input id="file-upload" onChange={this.handleFile} className="border-0" type="file" />


                          </div>
                        </div>
                      </div>

                    </form> */}

                  </Form>
                  <div className=" h-size-2 p-0 col-7 border mx-auto ApplyDesigner__btn mt-2">

                    <a className={this.state.loading == true && "loading"} onClick={this.sendCareerInformation}> {i18next.t("Send")}</a>

                  </div>
                </div>

              </div>
            </div>
            <div className="col-lg-6 ApplyDesigner__img d-none d-lg-flex">
              <img src={CareerPhoto}></img>
            </div>

          </div>

        </div>
        <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showErrorModal} onHide={() => { this.setState({ showErrorModal: false }) }}>
            <Modal.Header closeButton>
            </Modal.Header>
            <h4 className="LoginModal__title text-center">{i18next.t('UnSuccessful')}</h4>
            <p></p>
            <Modal.Body>
              <div className="text-center mb-3">
                <svg className="iconSize-md" >
                  <use href={Sprite + "#sad-face"} ></use>
                </svg >
              </div>
              <form className="LoginModal  ">
                <p className="text-center  p-size-1">{this.state.errorMessage}</p>
                <div className="text-center  w-50  mx-auto">
                  <a onClick={() => this.setState({ showErrorModal: false })} className="submit-button">{i18next.t('Home')}</a>
                </div>
              </form>
            </Modal.Body>
            {/* <Modal.Footer>
              <div className="KeepInTouch__Img ">
                <img src={KeepInTouchImg} className="img-fluid"></img>
              </div>
            </Modal.Footer> */}
        </Modal>
        <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showSuccesModal} onHide={() => { this.setState({ showSuccesModal: false }) }}>
            <Modal.Header closeButton>
            </Modal.Header>
            <h4 className="LoginModal__title text-center"> {i18next.t("ProcessSuccess")}</h4>
            <p></p>
            <Modal.Body>
              <div className="text-center mb-3">
                <svg className="iconSize-md" >
                  <use href={Sprite + "#smile"} ></use>
                </svg >
              </div>
              <div className="LoginModal  ">
                <p className="text-center p-size-1"> {i18next.t("SuccessMessage")}</p>
                <div className="text-center  w-50  mx-auto">
                  <a onClick={() => { this.setState({ showSuccesModal: false }) }} className="submit-button">{i18next.t('OK')}</a>
                </div>
              </div>
            </Modal.Body>
            {/* <Modal.Footer>
              <div className="KeepInTouch__Img ">
                <img src={KeepInTouchImg} className="img-fluid"></img>
              </div>
            </Modal.Footer> */}
        </Modal>
      </div >

    )
  }
}
