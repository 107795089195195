
import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Router from './Router';
import './Assets/Css/style.css';

function App() {

  useEffect(() => {
    // Update the document title using the browser API
    //document.title = `Wondrous`;
    // console.log(location)
  });

  // const type = location.pathname == "/" && 1;
  // const title = type == 1 && "anasayfaya hoşgeldiniz"
  return (
    <>

      <Router />
    </>

  );
}

export default App;
