import React, { Component } from 'react'
import i18next from 'i18next';
import Sprite from '../Assets/Icons/sprite.svg';
export default class SepetComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };

  }
  render() {
    const { item, Quantity, Size, Price } = this.props;
    // console.log("iteeeeem",item)
    return (


      <div className="row  p-0  mb-2">
        <div className="col-3  sepetImg p-0 ">
          <img src={item.MainPhoto} className=""></img>
        </div>
        <div className="col-6 p-0 pl-2">
          <p className="product-card__title_cart mb-0 font-size-2">{item.Title}</p>
          <p className="product-card__brand font-size-1">{item.DesignerName}</p>
          {item.VariationGroupId > 0 && item.VariationId > 0 &&
            <span className="product-card__size font-size-2 mr-2">  <b>{item.VariationGroupName}:</b> {item.VariationName} </span>}
          <span className="product-card__itemCount font-size-2"> <b>  {i18next.t('Count')}: </b>{item.Quantity} </span>
        </div>
        <div className="col-3 p-0 d-flex flex-column my-auto pl-2 ">
          {item.DiscountedPrice > 0 ?
            <>
              <span className="product-card__oldPrice mb-1 font-size-1 "> <del>{parseFloat(item.Price).toFixed(2)} TL</del></span>
              <div className="row justify-content-between">
              <span className="product-card__newPrice mb-1  "> {parseFloat(item.DiscountedPrice).toFixed(2)} TL</span>
              <a onClick={this.props.removeCartItem} className="product-card__newPrice mb-3 popover-delete-product ">
                <svg className="iconSize-xs" >
                 <use href={Sprite + "#deleteProduct"} ></use>
                 </svg >
              </a>
              </div>
            </>
            :
            <div className="row justify-content-between">
              <span className="product-card__newPrice mb-1  "> {parseFloat(item.Price).toFixed(2)} TL</span>
              <a onClick={this.props.removeCartItem} className="product-card__newPrice mb-1 popover-delete-product ">
                <svg className="iconSize-xs" >
                  <use href={Sprite + "#deleteProduct"} ></use>
                </svg >
              </a>
            </div>
          }

          {/* <span style={{maxLength:3}} className="product-card__newPrice font-size-2">{Price.toFixed(2)} TL</span> */}
        </div>
      </div>




    )
  }
}
