/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Sprite from '../Assets/Icons/sprite.svg'
import i18next from 'i18next';
import ApiBase from '../lib/ApiBase';
import { inject, observer } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import KeepInTouchImg from '../Assets/Image/KeepInTouch__Img.svg';
const FavorilerimItems = observer(class FavorilerimItems extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedVariationId: this.props.item.VariationId,
      selectedQuantity: 0,
    };
  }

  onChangeVariationValue = (e, item) => {
    this.setState({ selectedVariationId: e.value }, () => {

      console.log(this.state.selectedVariationId)
      this.ChangeCartVariation(item)
    });
  }

  ChangeCardQuantityDecrease = (item) => {

    this.setState({ addCartLoading: true, selectedQuantity: item.Quantity })

    var AUID = localStorage.getItem("AnonymousUserId")
    var _isLogin = localStorage.getItem("isLogin");
    // console.log(_isLogin)
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    if (item.Quantity === 1) {
      this.setState({ showErrorModal: true, errorMessage: "Düşürme işlemi yapılamamaktadır" })
    } else {
      ApiBase.Post({
        url: '/change-cart-quantity',
        data: {
          LanguageId: LangId,
          ProductId: item.ProductId,
          VariationId: this.state.selectedVariationId,
          IsMainVariation: item.IsMainVariation,
          AnonymousUserId: AUID,
          Quantity: item.Quantity > item.Stock ? item.Stock : item.Quantity - 1,
          UserId: _UserId

        },
        successFunction: (e) => {
          // console.log("sepeteekle", e.data)
          item.Quantity--
          item.TotalPrice -= item.DiscountedPrice > 0 ? item.DiscountedPrice : item.Price
          // console.log("categories",e.data.data.product.Categories[0].NameTr)
          this.setState({ addCartLoading: false, })
          // this.notify();
          this.getCartCount();
          this.props.succesFunc();

        },
        errorFunction: (e) => {
          this.setState({ addCartLoading: false, showErrorModal: true, errorMessage: e.data.message })
          console.log(e.data.message)
        }
      })
    }

  }

  ChangeCardIncrease = (item) => {

    this.setState({ addCartLoading: true })

    var AUID = localStorage.getItem("AnonymousUserId")
    var _isLogin = localStorage.getItem("isLogin");
    // console.log(_isLogin)
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;

    // console.log(item.Quantity + 1)

    ApiBase.Post({
      url: '/change-cart-quantity',
      data: {
        LanguageId: LangId,
        ProductId: item.ProductId,
        VariationId: this.state.selectedVariationId,
        IsMainVariation: item.IsMainVariation,
        AnonymousUserId: AUID,
        Quantity: item.Quantity + 1,
        UserId: _UserId

      },
      successFunction: (e) => {
        console.log("sepeteekle", e.data)
        item.Quantity++
        item.TotalPrice += item.DiscountedPrice > 0 ? item.DiscountedPrice : item.Price

        // console.log("categories",e.data.data.product.Categories[0].NameTr)
        this.setState({ addCartLoading: false, })

        // this.notify();
        this.getCartCount();
        this.props.succesFunc();
      },
      errorFunction: (e) => {
        this.setState({ addCartLoading: false, showErrorModal: true, errorMessage: e.data.message })
        console.log(e.data.message)
      }
    })
  }
  getCartCount = () => {
    var AUID = localStorage.getItem("AnonymousUserId")
    var _isLogin = localStorage.getItem("isLogin");
    // console.log(_isLogin)
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    this.props.Store.CartStore.GetCartCount(_UserId, AUID)
  }

  clearCart = () => {
    var AUID = localStorage.getItem("AnonymousUserId")
    var _isLogin = localStorage.getItem("isLogin");
    // console.log(_isLogin)
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }



    ApiBase.Post({
      url: '/clear-cart',
      data: {

        AnonymousUserId: AUID,
        UserId: _UserId,
      },
      successFunction: (e) => {
        this.props.succesFunc();
        // console.log("sepeteekle", e.data)


        // console.log("categories",e.data.data.product.Categories[0].NameTr)
        this.setState({ addCartLoading: false, })
        this.getCartCount();
        // this.notify();

      },
      errorFunction: (e) => {
        // this.setState({ addCartLoading: false, showErrorModal: true, errorMessage: e.data.message })
        console.log(e.data.message)
      }
    })
  }

  notify = () => toast.success("Sepet Güncellendi !");

  ChangeCartVariation = (item) => {

    this.setState({ addCartLoading: true })

    var AUID = localStorage.getItem("AnonymousUserId")
    var _isLogin = localStorage.getItem("isLogin");
    // console.log(_isLogin)
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;

    // console.log(item.Quantity + 1)
    if (this.state.selectedVariationId == item.VariationId){

    }else{
      ApiBase.Post({
        url: '/change-cart-variation',
        data: {
          LanguageId: LangId,
          ProductId: item.ProductId,
          VariationId: this.state.selectedVariationId,
          IsMainVariation: item.IsMainVariation,
          AnonymousUserId: AUID,
          UserId: _UserId,
          Id: item.Id

        },
        successFunction: (e) => {
          // console.log("sepeteekle", e.data)


          // console.log("categories",e.data.data.product.Categories[0].NameTr)
          this.setState({ addCartLoading: false, })
          this.props.succesFunc();
          this.getCartCount();
          // this.notify();

        },
        errorFunction: (e) => {

          this.setState({ addCartLoading: false, showErrorModal: true, errorMessage: e.data.message,selectedVariationId:item.VariationId })
          // console.log(e.data.message)
        }
      })
    }
     
  }
  removeCartProduct = (item) => {
    var AUID = localStorage.getItem("AnonymousUserId")
    var _isLogin = localStorage.getItem("isLogin");
    // console.log(_isLogin)
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;

    // console.log(item.Quantity + 1)

    ApiBase.Post({
      url: '/remove-cart-product',
      data: {
        LanguageId: LangId,
        ProductId: item.ProductId,
        AnonymousUserId: AUID,
        UserId: _UserId,
      },
      successFunction: (e) => {
        this.props.succesFunc();
        console.log("sepeteekle", e.data)
        // console.log("categories",e.data.data.product.Categories[0].NameTr)
        this.setState({ addCartLoading: false, });
        this.getCartCount();
        // this.notify();
        this.props.succesFunc()
      },
      errorFunction: (e) => {
        this.setState({ addCartLoading: false, showErrorModal: true, errorMessage: e.data.message })
        console.log(e.data.message)
      }
    })
  }


  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        fontSize: 12,
        color: 'black',
        backgroundColor: "transparent",

        '&:hover': {
          backgroundColor: "#F3F0E9"

        },
        '&:focus': {
          backgroundColor: "#F3F0E9"

        },
      }),

      control: (provided, state) => ({
        ...provided,
        marginTop: "5%",
        borderWidth: 0,
        borderBottomWidth: "1px",

        borderColor: '#E5E5E5;',
        backgroundColor: "transparent",
        borderRadius: 0,
        outline: "transparent",
        boxShadow: state.isFocused ? '0 0 0 0.2rem transparent' : '',
        '&:focus': {
          borderColor: "#F3F0E9",
          '&:hover': {
            borderColor: "#F3F0E9"

          },
        },
        "@media only screen and (max-width: 992px)": {
          ...provided["@media only screen and (max-width: 992px)"],
          minHeight: 28,
        },

      }),




      container: (provided) => ({
        ...provided,
        width: "75%",

        //  backgroundColor:'red',
        '&:focus': {
          borderColor: "#F3F0E9",

        },
        "@media only screen and (max-width: 992px)": {
          ...provided["@media only screen and (max-width: 992px)"],
          width: "50%",
        },
      }),
      valueContainer: (provided) => ({
        ...provided,
        justifyContent: 'center',
        "@media only screen and (max-width: 992px)": {
          ...provided["@media only screen and (max-width: 992px)"],
          fontSize: 12,
        },

      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        //  backgroundColor:'red',
        color: ' #6B7070',
        borderLeft: 0

      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        width: 0,
        "@media only screen and (max-width: 992px)": {
          ...provided["@media only screen and (max-width: 992px)"],
          height: "26px",
        },
      }),
      indicatorsContainer: (provided) => ({
        ...provided,
        //  backgroundColor:'red',
        "@media only screen and (max-width: 992px)": {
          ...provided["@media only screen and (max-width: 992px)"],
          height: "26px",
        },

      }),

    }

    const { item, succesFunc } = this.props;
    const varOptions = []
    const variationOptions = item.Variations.map(item => {
      return (
        varOptions.push({ value: item.VariationId, label: item.Stock <= 0 ? item.Name + " (Stok Tükendi)" : item.Name })
      )
    })
    return (

      <div className=" product-card__body">
        <div className="row">
          <div className="col-lg-5 col-10 d-flex  align-items-center pr-0">
            <img src={item.MainPhoto} className=" mr-2 mr-lg-4" alt={item.Title} style={{ width: 130, height: 118, objectFit: "contain" }}></img>
            <div className="w-100">
              <p className="product-card__title_detail2 h-size-3 mb-2">{item.Title}</p>
              {/* <p className="product-card__brand ">{item.DesignerName}</p> */}

              <div className="d-block d-lg-none my-2">

                {item.Variations.length > 0 &&
                  <>
                    <span className=" font-size-1 font--opacity mr-2">BEDEN:</span>
                    <Select
                      value={varOptions.find(op => { return op.value == this.state.selectedVariationId })}
                      styles={customStyles}
                      options={varOptions}
                      isSearchable={false}

                      onChange={(e) => { this.onChangeVariationValue(e, item) }}

                    >
                    </Select>
                    {/* <select value={this.state.selectedVariationId} onChange={() => { this.onChangeVariationValue(item) }} className="w-50 ">
                    
                      {item.Variations.map(item => {
                        return (
                          <option value={item.Id}>{item.Stock <= 0 ? item.Name + " (Stok Tükendi)" : item.Name}</option>
                        )
                      })}
                    </select> */}
                  </>
                }
              </div>
              {/* <p className="product-card__size font-size-1 my-2"> <span className="font--opacity"> {"askdjaksdad"} :</span> {"asdasdasda"}</p> */}
              <div className="d-none d-lg-block">
                {item.DiscountedPrice > 0 &&
                  <span className="product-card__oldPrice"> <del>{parseFloat(item.Price).toFixed(2)} TL</del></span>

                }
                {item.DiscountedPrice > 0 ?
                  <span className="product-card__price font-size-3 p-size--b mb-0"> {parseFloat(item.DiscountedPrice).toFixed(2)} TL</span>
                  :
                  <p className="product-card__price font-size-3 p-size--b mb-0"> {parseFloat(item.Price).toFixed(2)} TL</p>

                }
                <p className="product-card__price font-size-3 p-size--b mb-0"> {item.Quantity > item.Stock ? "Kalan Stok: " + item.Stock : ""} </p>
              </div>

              <p className="product-card__count font-size-1 mb-2 d-flex d-lg-none">

                <span className="font--opacity mr-2">{i18next.t("CountUp")} : </span>


                <span className=" d-flex flex-row justify-content-between  w-50 ">
                  <a onClick={() => { this.ChangeCardQuantityDecrease(item) }} >
                    <svg className="iconSize-14" >
                      <use href={Sprite + "#minusCount"} ></use>
                    </svg >
                  </a>
                  <input className="border-0 w-50 text-center" value={item.Quantity} disabled />
                  <a onClick={() => { this.ChangeCardIncrease(item) }} >
                    <svg className="iconSize-14" >
                      <use href={Sprite + "#addCount"} ></use>
                    </svg >
                  </a>
                </span>



              </p>
              <p className="product-card__price d-block d-lg-none font-size-1 mb-2"><span className="font--opacity"> {i18next.t("TotalPrice")}</span> : <span className="p-size-3 p-size--b"> {parseFloat(item.TotalPrice).toFixed(2)} TL </span></p>
            </div>

          </div>
          <div className="col-2 d-none d-lg-flex  align-items-center justify-content-center">
            {item.Variations.length > 0 &&
              <div className="selectProvince w-100">
                <Form.Group >

                  {/* <Form.Control as="select" onChange={(e) => { this.onChangeVariationValue(e, item) }} value={this.state.selectedVariationId} >

                    {item.Variations.map(item => {
                      return (
                        <option value={item.Id}>{item.Stock <= 0 ? item.Name + " (Stok Tükendi)" : item.Name}</option>
                      )
                    })}
                  </Form.Control> */}
                  <Select
                    value={varOptions.find(op => { return op.value == this.state.selectedVariationId })}
                    styles={customStyles}
                    options={varOptions}
                    isSearchable={false}
                    onChange={(e) => { this.onChangeVariationValue(e, item) }}

                  >
                  </Select>
                </Form.Group>
              </div>
              // <select value={this.state.selectedVariationId} onChange={(e) => { this.onChangeVariationValue(e, item) }} className="w-75 ">
              //   {/* <option className="mx-auto" >S</option> */}
              //   {item.Variations.map(item => {
              //     return (
              //       <option value={item.Id}>{item.Stock <= 0 ? item.Name + " (Stok Tükendi)" : item.Name}</option>
              //     )
              //   })}
              // </select>
            }

          </div>
          <div className="col-2  d-none d-lg-flex  align-items-center ">
            <div className=" d-flex justify-content-between  w-100 divChangeCount">
              <a onClick={() => { this.ChangeCardQuantityDecrease(item) }} >
                <svg className="iconSize-xs" >
                  <use href={Sprite + "#minusCount"} ></use>
                </svg >
              </a>
              <input className="border-0 w-50 text-center divChangeCountInput" value={item.Quantity} disabled />
              <a onClick={() => { this.ChangeCardIncrease(item) }} >
                <svg className="iconSize-xs" >
                  <use href={Sprite + "#addCount"} ></use>
                </svg >
              </a>
            </div>
          </div>
          <div className=" col-2  d-none d-lg-flex h-size-3 p-size--b  align-items-center justify-content-center" >
          {parseFloat(item.TotalPrice).toFixed(2)} TL
                    </div>

          <div className="col-1  align-items-center d-flex justify-content-center">
            <a onClick={() => { this.removeCartProduct(item) }} >
              <svg className="iconSize-xs" >
                <use href={Sprite + "#deleteProduct"} ></use>
              </svg >
            </a>
          </div>
        </div>
        <ToastContainer

        />
        <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showErrorModal} onHide={() => { this.setState({ showErrorModal: false }) }}>
            <Modal.Header closeButton>
            </Modal.Header>
            <h4 className="LoginModal__title text-center">BAŞARISIZ</h4>
            <p></p>
            <Modal.Body>
              <div className="text-center mb-3">
                <svg className="iconSize-md" >
                  <use href={Sprite + "#sad-face"} ></use>
                </svg >
              </div>
              <form className="LoginModal  ">
                <p className="text-center">{this.state.errorMessage}</p>
                <div className="text-center  w-50  mx-auto">
                  <a onClick={() => { this.setState({ showErrorModal: false }) }} className="submit-button">{i18next.t('OK')}</a>
                </div>
              </form>
            </Modal.Body>
            {/* <Modal.Footer>
              <div className="KeepInTouch__Img ">
                <img src={KeepInTouchImg} className="img-fluid"></img>
              </div>
            </Modal.Footer> */}
        </Modal>
      </div>

    )
  }
})
export default inject((stores) => ({
  Store: stores.Store,
}))(FavorilerimItems);
