/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import Form from 'react-bootstrap/Form';
import i18next from 'i18next';
import Modal from 'react-bootstrap/Modal';
import KeepInTouchImg from '../Assets/Image/KeepInTouch__Img.svg';
import UyeOlImg from '../Assets/Image/Uyelik_Kayit.png';

import Sprite from '../Assets/Icons/sprite.svg';
import ApiBase from '../lib/ApiBase';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import InputMask from 'react-input-mask';
const UyeOl = observer(class UyeOl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlertModal: false,
      NameSurname: '',
      Email: '',
      PhoneNumber: '',
      Password: '',
      PasswordAgain: '',
      TermsOfUse: false,
      AddSubs: false,
      showErrorModal: false,
      showSuccesModal: false,
      errorMessage: false,
      showLoginModal: false,
      LoginNameSurname: '',
      LoginPassword: ''
    };

  }
  changePassword = () => {
    this.setState({ changePassword: true })
  }
  confirmChangePassword = () => {
    this.setState({ changePassword: false })
  }
  showAlertModal = () => {
    this.setState({ showAlertModal: true, onPressForgotPassword: false })

  }
  hideLoginModal = () => {
    this.setState({
      showLoginModal: false,
      showSuccesModal: false,
      showAlertModal: false,
      forgotPasswordModal: false,
      showNewPasswordModal: false,
      showSuccessActivationModal: false
    })
  }
  _errorFunction = () => {
    var errorMessage = localStorage.getItem("errorMessage")
    this.setState({ errorMessage: errorMessage, showErrorModal: true })
  }
  AddSubscriber = () => {
    this.setState({ addLoading: true })

    ApiBase.Post({
      url: '/add-subscriber',
      data: {
        Email: this.state.Email,
      },
      successFunction: (e) => {

        this.setState({ addLoading: false });
        // this.setState({ loading: false })
      },
      errorFunction: (e) => {
        this.setState({ addLoading: false })
        alert(e.data.message)
      }
    })
  }
  goRegister = () => {
    this.setState({ registerLoading: true })
    if (this.state.TermsOfUse === false) {
      this.setState({ showErrorModal: true, errorMessage: 'Kullanım Şartlarını ve Kişisel Verilen Korunması Kanunu Kabul Ediniz.' })
    } else {
      ApiBase.Post({
        url: '/register',
        data: {
          Name: this.state.NameSurname,
          Email: this.state.Email,
          Phone: this.state.PhoneNumber,
          Password: this.state.Password,
          RePassword: this.state.PasswordAgain,
        },
        successFunction: (e) => {
          if (this.state.AddSubs === true) {
            this.AddSubscriber();
          }
          this.setState({ addLoading: false, showSuccesModal: true });
          // this.setState({ loading: false })
        },
        errorFunction: (e) => {
          this.setState({ addLoading: false, showErrorModal: true, errorMessage: e.data.message })

        }
      })
    }

  }
  onPressForgotPassword = () => {
    this.setState({ forgotPasswordModal: true, showLoginModal: false, showAlertModal: false })

  }
  forgotPasswordSendMail = () => {
    this.setState({ actLoading: true, forgotPasswordModal: true })

    ApiBase.Post({
      url: '/send-forgot-password-mail',
      data: {
        Email: this.state.ForgotEmail
      },
      successFunction: (e) => {
        this.setState({ showAlertModal: true, forgotPasswordModal: true });
        // this.setState({ loading: false })
      },
      errorFunction: (e) => {
        // console.log(e.data)
        this.setState({ showErrorModal: true });

      }
    })
  }
  _loginFunction = () => {
    var AnonymousUserId = localStorage.getItem("AnonymousUserId")
    this.props.Store.CurrentUserStore.Login(this.state.userName, this.state.password, AnonymousUserId, this._errorFunction)
    this.setState({ showLoginModal: false })
  }

  render() {
    const { history } = this.props;
    const lang = localStorage.getItem("lang")

    return (
      <div className="SignUp">
        <div className="container">
          <div className="row py-4">
            <div className="col-lg-6">
              <div className="divBorderLine">
                <div className="divCategoryName mt-3">
                  ÜYE GİRİŞİ
                                    <div className="divHomeBlackLine"></div>
                </div>
                <div className="divFormSubTitle" >E-posta</div>
                <input className="form-control divFromInput" type="email" value={this.state.userName} onChange={(e) => this.setState({ userName: e.target.value })} />
                <div className="divFormSubTitle">Şifre</div>
                <input className="form-control divFromInput" type="password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                <a className="submit-button" onClick={() => { this._loginFunction() }}>GİRİŞ YAP</a>
                <div className="divFormSifremiUnuttum" onClick={this.onPressForgotPassword}>ŞİFREMİ UNUTTUM</div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="divBorderLine">
                <div className="divCategoryName mt-3">
                  ÜYE OL
                                    <div className="divHomeBlackLine"></div>
                </div>
                <div className="divFormSubTitle">İsim Soysisim</div>
                <input type="text" className="form-control divFromInput" id="name" required value={this.state.NameSurname} onChange={(e) => this.setState({ NameSurname: e.target.value })} />
                <div className="divFormSubTitle">E-posta</div>
                <input type="mail" className="form-control divFromInput" id="emailadress" required value={this.state.Email} onChange={(e) => this.setState({ Email: e.target.value })} />
                <div className="divFormSubTitle">Telefon</div>
                <InputMask mask="(999) 999 99 99"
                  className="c-input form-control divFromInput"
                  maskChar={null}
                  placeholder
                  type="tel"
                  id="telno"
                  value={this.state.PhoneNumber}
                  onChange={(e) => { this.setState({ PhoneNumber: e.target.value }) }}
                  required>
                </InputMask>
                <div className="divFormSubTitle">Şifre</div>
                <input type="password" className="form-control divFromInput" id="userNew_password" required value={this.state.Password} onChange={(e) => this.setState({ Password: e.target.value })} />
                <div className="divFormSubTitle">Şifre Tekrar</div>
                <input type="password" className="form-control divFromInput" id="userNewAgain_password" required value={this.state.PasswordAgain} onChange={(e) => this.setState({ PasswordAgain: e.target.value })} />
                <div className="form-check conditions">
                  <input type="checkbox" class="form-check-input" id="conditions" onChange={() => { this.setState({ TermsOfUse: !this.state.TermsOfUse }, () => { console.log(this.state.TermsOfUse) }) }} />
                  <label class="form-check-label" htmlFor="conditions">
                    <span className="p-size-2 p-size--color"><a href="/sozlesmeler" target="_blank" className="text-dark">{i18next.t("TermsOfUse")}</a> {i18next.t("And")}  <a href="/sozlesmeler" target="_blank" className="text-dark"> {i18next.t("ProtectPersonalData")} </a>  {i18next.t("ContractText2")} </span>
                  </label>
                </div>
                <Form className="iletisim">
                  <a onClick={this.goRegister} className="submit-button">{i18next.t("SIGNUP")}</a>

                  {/* <div className="form-check conditions mt-3">
                        <input type="checkbox" class="form-check-input" id="conditions" onChange={() => { this.setState({ AddSubs: !this.state.AddSubs }, () => { console.log(this.state.AddSubs) }) }} />
                        <label class="form-check-label" htmlFor="conditions">
                          <span className="p-size-2 p-size--color">

                            {lang === "tr" ?
                              <> {i18next.t("ForCampaignsMessage")} <a href="#" className="text-dark">  {i18next.t("ElectronicMessages")} </a>  {i18next.t("Iwantto")}
                              </>

                              : <>{i18next.t("Iwantto")}   <a href="#" className="text-dark">  {i18next.t("ElectronicMessages")} </a>  {i18next.t("ForCampaignsMessage")} </>}

                          </span>
                        </label>
                      </div> */}
                  <div className="row mt-4">
                    <div className="col-lg-7  p-0 mx-auto pr-3">
                    </div>
                  </div>
                  <p className="text-center mt-3 py-3 border-top p-size-1">{i18next.t("DoYouHaveaMemberShip")} <a onClick={() => { this.setState({ showLoginModal: true }) }} className="p-size--b p-size--color2">{i18next.t("SignIN")}</a></p>
                </Form>
              </div>
            </div>
            {/* <div className="SignUpImg col-lg-6  text-center">
              <img src={UyeOlImg} className="img-fluid" alt="register" />
            </div> */}
          </div>
          {/* Alert Modal */}
          <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showAlertModal} onHide={this.hideLoginModal}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              <form className="LoginModal">
                <div className="text-center mb-3">
                  <svg className="iconSize-md" >
                    <use href={Sprite + "#smile"} ></use>
                  </svg >
                </div>
                <p className="text-center h-size-3 p-size--color2 ">{i18next.t("AlertModalText")} </p>
                <div className="d-flex px-2">
                  <div className="mx-auto ">
                    <p className=" p-size-3 ps-ize--b mb-2"> Aktivasyon linki e-postası elinize ulaşmadıysa:</p>
                    <p className=" p-size-3 font--opacity  mb-2"> {i18next.t("AlertModalText1")} </p>
                    <p className=" p-size-3  font--opacity mb-2"> {i18next.t("AlertModalText2")}</p>
                    <p className=" p-size-3  font--opacity mb-2">{i18next.t("AlertModalText3")}</p>
                  </div>
                </div>

                <div className="text-center  w-50  mx-auto">
                  <button onClick={() => this.setState({ showAlertModal: false })} className="submit-button">{i18next.t("TurnBack")}</button>
                </div>
              </form>
            </Modal.Body>
            {/* <Modal.Footer>
                <div className="KeepInTouch__Img ">
                  <img src={KeepInTouchImg} className="img-fluid"></img>
                </div>
              </Modal.Footer> */}
          </Modal>
          {/* Error Modal */}
          <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showErrorModal} onHide={() => { this.setState({ showErrorModal: false }) }}>
            <Modal.Header closeButton>
            </Modal.Header>
            <h4 className="LoginModal__title text-center">{i18next.t('UnSuccessful')}</h4>
            <p></p>
            <Modal.Body>
              <div className="text-center mb-3">
                <svg className="iconSize-md" >
                  <use href={Sprite + "#sad-face"} ></use>
                </svg >
              </div>
              <form className="LoginModal  ">
                <p className="text-center  p-size-1">{this.state.errorMessage}</p>
                <div className="text-center  w-50  mx-auto">
                  <a onClick={() => this.setState({ showErrorModal: false })} className="submit-button">{i18next.t('TurnBack')}</a>
                </div>
              </form>
            </Modal.Body>
            {/* <Modal.Footer>
                <div className="KeepInTouch__Img ">
                  <img src={KeepInTouchImg} className="img-fluid"></img>
                </div>
              </Modal.Footer> */}
          </Modal>
          {/* Login Modal */}
          <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showLoginModal} onHide={() => { this.setState({ showLoginModal: false }) }}>
            <Modal.Header closeButton>
            </Modal.Header>
            <h4 className="LoginModal__title text-center">{i18next.t('MemberLogin')}</h4>
            <p></p>
            <Modal.Body>
              <form className="LoginModal  ">
                <div class="form-group  mx-auto">
                  <input type="email" placeholder={i18next.t('EmailAdress')} value={this.state.userName} onChange={(e) => this.setState({ userName: e.target.value })} />
                </div>
                <div class="form-group  mx-auto">
                  <input type="password" placeholder={i18next.t('Password')} value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                </div>
                <div className="text-center  w-50  mx-auto">
                  <a className="h-size-3 p-size--b" onClick={() => { this._loginFunction() }} className="submit-button">{i18next.t('SignIn')}</a>
                </div>
              </form>
              <div className="LoginModal__links">
                <div className=" text-center">
                  <a className="p-size-2 " onClick={this.onPressForgotPassword} >{i18next.t('ForgotPassword')}</a>
                  <div className="LoginModal__hr  mx-auto w-50">
                    <p className="mx-auto"></p>
                  </div>
                  <p className="p-size-2 ">{i18next.t('DontYouHaveaMemberShip')} <a href="/uyeol"> <strong> {i18next.t('SignUp')}</strong></a></p>
                </div>
              </div>
            </Modal.Body>
            {/* <Modal.Footer>
              <div className="KeepInTouch__Img ">
                <img src={KeepInTouchImg} className="img-fluid"></img>
              </div>
            </Modal.Footer> */}
          </Modal>
          {/* Succes Modal */}
          <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showSuccesModal} onHide={this.hideLoginModal}>
            <Modal.Header closeButton>
            </Modal.Header>
            <h4 className="LoginModal__title text-center"> {i18next.t("ProcessSuccess")}</h4>
            <p></p>
            <Modal.Body>
              <div className="text-center mb-3">
                <svg className="iconSize-md" >
                  <use href={Sprite + "#smile"} ></use>
                </svg >
              </div>

              <p className="text-center h-size-3 p-size--color2 ">Aktivasyon Linkiniz e-postanıza gönderilmiştir.</p>
              <div className="d-flex px-2">
                <div className="mx-auto ">
                  <p className=" p-size-3 ps-ize--b mb-2"> Aktivasyon linki e-postası elinize ulaşmadıysa:</p>
                  <p className=" p-size-3 font--opacity  mb-2"> {i18next.t("AlertModalText1")} </p>
                  <p className=" p-size-3  font--opacity mb-2"> {i18next.t("AlertModalText2")}</p>
                  <p className=" p-size-3  font--opacity mb-2">{i18next.t("AlertModalText3")}</p>
                </div>
              </div>

            </Modal.Body>
            {/* <Modal.Footer>
                <div className="KeepInTouch__Img ">
                  <img src={KeepInTouchImg} className="img-fluid"></img>
                </div>
              </Modal.Footer> */}
          </Modal>
        </div >
        {/* Şifremi Unuttum Modal */}
        <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.forgotPasswordModal} onHide={this.hideLoginModal}>
          <Modal.Header closeButton>
          </Modal.Header>
          <h4 className="LoginModal__title text-center">{i18next.t('ForgotPassword')}</h4>
          <p></p>
          <Modal.Body>
            <form className="LoginModal">
              <p className="text-center p-size-1">{i18next.t('ForgotPasswordText')}</p>
              <div class="form-group  mx-auto">
                <input type="email" placeholder={i18next.t('EmailAdress')} value={this.state.ForgotEmail} onChange={(e) => this.setState({ ForgotEmail: e.target.value })} required />
              </div>
              <div className="text-center  w-50  mx-auto">
                <a onClick={this.forgotPasswordSendMail} className="submit-button">{i18next.t('Send')}</a>


              </div>
            </form>
          </Modal.Body>
          {/* <Modal.Footer>
              <div className="KeepInTouch__Img ">
                <img src={KeepInTouchImg} className="img-fluid"></img>
              </div>
            </Modal.Footer> */}
        </Modal>
      </div >
    )
  }
})
export default inject((stores) => ({
  Store: stores.Store,
}))(withRouter(UyeOl));