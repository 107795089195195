/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import i18next from 'i18next';

export default class ArtsAndWorkshopCard extends Component {
    render() {
        const { item,artsHref } = this.props;

        return (
            <div className="ArtsAndWorkshopCard">
                <div className="row">
                    <div className="col-lg-5 ArtsAndWorkshopCard__img  p-0 py-2">
                        <img src={item.CoverPhoto} className="img-fluid"></img>
                    </div>
                    <div className="col-lg-7 ArtsAndWorkshopCard__body  py-2 ">

                        <h4 className="ArtsAndWorkshopCard__title">{item.Title}</h4>
                        <p  className="ArtsAndWorkshopCard__date h-size-2 "> {item.Subtitle}</p>
                        <p  className="ArtsAndWorkshopCard__text module ArtsAndWorkshop__text--clamp"  dangerouslySetInnerHTML={{ __html: item.Description }}></p>
                        <a href={artsHref} className="p-size-3 p-size--b p-size--color2 ">  {i18next.t("MoreDetails")}{'>'}</a>

                    </div>
                </div>

            </div>
        )
    }
}
