/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import CategoryCardImage from '../Assets/Image/categorycard3.svg'; 

import i18next from 'i18next';

export default class CategoryCard extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">

                    <div className="col-lg-4">
                        <div className="category-card d-flex" style={{background: `url(${CategoryCardImage})`}}>
                            <div className="overlay-categorycard"></div>
                            <a href="#" className="category-card__button ">
                            {i18next.t("Designers")}
                        </a>
                        </div>
                    </div>
                    <div className="col-lg-4  ">
                        <div className="category-card d-flex" style={{background: `url(${CategoryCardImage})`}}>
                        <div className="overlay-categorycard"></div>
                            <a href="#" className="category-card__button ">
                            {i18next.t("MustHaves")}
                        </a>
                        </div>
                    </div>
                    <div className="col-lg-4  ">
                        <div className="category-card d-flex" style={{background: `url(${CategoryCardImage})`}}>
                        <div className="overlay-categorycard"></div>
                            <a href="#" className="category-card__button ">
                            {i18next.t("NewCollaboration")}
                        </a>
                        </div>
                    </div>
                    
                  
                    
                </div>
            </div>



        )
    }
}
