import React, { Component } from 'react';
import Sprite from '../Assets/Icons/sprite.svg';
import i18next from 'i18next';
export default class IsBirlikleriImgCard extends Component {
    render() {
        const { item, isbirlikleriHref } = this.props;
        return (
            <div className="IsBirlikleriImgCard">

                <a href={isbirlikleriHref}>  <img src={item.CoverPhoto} alt={item.DesignerName}></img> 

                <div className="IsBirlikleriImgCard__body">
                    <p className="IsBirlikleriImgCard__title">  {item.Title} </p>
                    {/* <div className=" border sub-title--wrapper">  */}
                    <p className="IsBirlikleriImgCard__sub-title"> {item.DesignerName}</p>
                    {/* </div>
               */}
                </div>
                </a>

            
              

            </div>
        )
    }
}
