/* eslint-disable jsx-a11y/anchor-is-valid */
import i18next from 'i18next';
import React, { Component } from 'react';
import BreadCrumb from '../Components/BreadCrumb';
import InspirationBanner from '../Components/InspirationBanner';
import KeepInTouch from '../Components/KeepInTouch';
import ApiBase from '../lib/ApiBase';
import ArtsAndWorkshops from './ArtsAndWorkshops';
import ArtsAndWorkshopsCard from  '../Components/ArtsAndWorkshopCard';

export default class BlogDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingBlogs:false,
      loadingBlogDetail: false,
      BlogDetailData:[],
      BlogList:[],
    }
    // console.log("id", this.props.match.params.blogId)

  }
componentDidMount(){
  this.getBlogDetail();
  this.getSimiliarBlogs();
}
  getBlogDetail = () => {
    this.setState({ loadingBlogDetail: true });
    const _BlogId = this.props.match.params.blogId;
    ApiBase.Post({
      url: '/get-blog-detail',
      data: {
        BlogId: _BlogId
      },
      successFunction: (e) => {
      console.log(e.data)
        this.setState({
          BlogDetailData: e.data.data.blogData,
          
          loadingBlogDetail: false
        });
      },
      errorFunction: (e) => {
        this.setState({ loadingBlogDetail: false })
        // console.log(e.data.message)
      }
    })
  };

  getSimiliarBlogs = () => {
    const _BlogId = this.props.match.params.blogId;
    this.setState({ loadingBlogs: true })
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-similiar-blogs',
      data: {
        LanguageId: LangId,
        BlogId:_BlogId
      },
      successFunction: (e) => {
// /console.log("similiar",e.data.data)
        this.setState({
          BlogList: e.data.data.blogList, 
          loadingBlogs:false

        });
      },
      errorFunction: (e) => {
        this.setState({loadingBlogs:false})
        // console.log(e.data.message)
      }
    })
  };


  render() {

    const item = this.state.BlogDetailData;

    const BannerList = this.state.BlogList.map((item, index) => {
      return (
        <div key={item.Id} className="col-lg-4">
          <InspirationBanner isBlog blogHref={`/blogdetail/${item.Url}`} className={'InspirationBanner__text mt-auto '} item={item} />
        </div>
      )
    });
    return (
      <div className="BlogDetail">
        <div className="container">
          <BreadCrumb title={i18next.t("BlogMagazine")} titleLink={"/blogandmagazine"} path={item.Title} ></BreadCrumb>        
          <div className="BlogAndMagazine__blog">
                        <div className="row">
                            <div className=" col-lg-6 BlogAndMagazine__Description">
                     <div className="BlogAndMagazine__toptittle"> {i18next.t('LatestNews')}</div>
                                <p className="BlogAndMagazine__tittle">{item.Title}</p>
                                <p className="BlogAndMagazine__text  " dangerouslySetInnerHTML={{ __html: item.Description }}>
                                </p>
                                {/* <div className="row">
                                    <div className="pl-5">
                                        <a className="BlogAndMagazine__link2" href={`/blogDetail/${item.Url}`}> {i18next.t('StartShopping')}<span> {'>'} </span></a>
                                    </div>
                                </div> */}
                            </div>
                            <div className="col-lg-6">
                                <div className="BlogAndMagazine__Img BlogAndMagazine__Img--pd ">
                                    <img src={item.CoverPhoto} className="img-fluid" alt={item.Title}></img>

                                </div>
                            </div>
                        </div>
                    </div>
            
          
          <div className="BlogDetail__Banner" >
            <img src={item.DetailPhoto} className="img-fluid" alt={item.Title} ></img>
          </div>
        </div>
        <div className="BlogAndMagazine__header">
            <h4 >Wondrous Magazine</h4></div>
        <div className="InspirationBanner">
          <div className="container">
            <div className="row">
              {BannerList}
            </div>
          </div>
        </div>
        <KeepInTouch></KeepInTouch>
      </div>

    )
  }
}
