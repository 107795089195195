/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import BreadCrumb from '../Components/BreadCrumb';
import InspirationBanner from '../Components/InspirationBanner';

import KeepInTouch from '../Components/KeepInTouch';
import i18next from 'i18next';
import ApiBase from '../lib/ApiBase';

export default class BlogAndMagazine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingBlogs: false,
      BlogList: [],
      FeatureBlog:[],
    };

  }
  componentDidMount() {
    this.getBlogs();
  }
  getBlogs = () => {
    this.setState({ loading: true })
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-blogs',
      data: {
        LanguageId: LangId
      },
      successFunction: (e) => {
         console.log(e.data.data)
        this.setState({
          BlogList: e.data.data.blogList,
          FeatureBlog:e.data.data.featuredBlog,
          loadingBlogs:false
        });
      },
      errorFunction: (e) => {
        this.setState({loadingBlogs:false})
        // console.log(e.data.message)
      }
    })
  }

  render() {
    const BannerList = this.state.BlogList.map((item, index) => {
      //console.log(index);
      const _className = item.IsWidePhoto === true ? 'col-lg-8' : 'col-lg-4';
      const _className2 = item.IsWidePhoto === true ? 'InspirationBanner__text mt-auto InspirationBanner__text--w' : 'InspirationBanner__text mt-auto';

      return (
        <div key={item.Id} className={_className}>
          <InspirationBanner blogHref={`/blogdetail/${item.Url}`} isBlog className={_className2} item={item} />
        </div>
      )
    });

    return (
      <div className="BlogAndMagazine">
        <div className="container">
          <BreadCrumb path={i18next.t('BlogMagazine')}  ></BreadCrumb>
          <div className="BlogAndMagazine__header">
            <h4 >Wondrous {i18next.t('Magazine')}</h4>
            <div className="row ">
              <div className="col-10  mx-auto">
                <p className="">Moda ve sanat alanlarındaki en güncel trendleri, yaratıcı ve yenilikçi çalışmaları lokal ve global olarak takip ederek oluşturduğumuz faydalı içeriklerimizi çok seveceksiniz! </p>
              </div>
            </div>

          </div>
          <div className="BlogAndMagazine__blog">
            <div className="row">
              <div className="BlogAndMagazine__Img col-lg-4 offset-lg-1">

                <img src={this.state.FeatureBlog.CoverPhoto} className="img-fluid" alt={this.state.FeatureBlog.Title}></img>

              </div>
              <div className="col-lg-6 BlogAndMagazine__Description">
                <p className="BlogAndMagazine__toptittle"> {i18next.t('LatestNews')}</p>

                <p className="BlogAndMagazine__tittle">{this.state.FeatureBlog.Title}</p>

                <p className="BlogAndMagazine__text text-justify module BlogandMagazine__text--clamp" dangerouslySetInnerHTML={{ __html: this.state.FeatureBlog.Description }}></p>

                <div className="row">
                  <div className="col-lg-4 col-6 text-lg-right text-left ">

                    <a className="BlogAndMagazine__link1" href={`/blogdetail/${this.state.FeatureBlog.Url}`}> {i18next.t('ReadMore')} <span className="ml-1">  {'>'} </span></a>

                  </div>
                  
                </div>


              </div>
            </div>
          </div>
          <div className="InspirationBanner">
            <div className="container">
              <div className="row">
                {BannerList}
              </div>
            </div>
          </div>
        </div>
        <KeepInTouch></KeepInTouch>
      </div>



    )
  }
}
