import React, { Component } from 'react';
import Sprite from '../Assets/Icons/sprite.svg'
import i18next from 'i18next';
export default class BreadCrumb extends Component {
  render() {
    const { title, titleLink, category, categoryLink, brand, brandLink, path, pathLink, hesabim } = this.props
    return (
      <div className="breadcrumb__text--clamp">
        <div className="container">
          <ol className="breadcrumb mb-0 bg-transparent  ">
            <li className="breadcrumb-item">
              <a href="/">
                <span className="text-uppercase">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.49963 12.9996V9.99953C9.49963 9.86693 9.44696 9.73975 9.35319 9.64598C9.25942 9.55221 9.13224 9.49953 8.99963 9.49953H6.99963C6.86703 9.49953 6.73985 9.55221 6.64608 9.64598C6.55231 9.73975 6.49963 9.86693 6.49963 9.99953V12.9996C6.49963 13.1322 6.44696 13.2594 6.35321 13.3531C6.25945 13.4469 6.13229 13.4996 5.9997 13.4996L3.00006 13.5C2.9344 13.5 2.86937 13.4871 2.8087 13.4619C2.74803 13.4368 2.6929 13.4 2.64647 13.3536C2.60003 13.3071 2.5632 13.252 2.53807 13.1913C2.51293 13.1307 2.5 13.0657 2.5 13V7.22122C2.5 7.15156 2.51456 7.08267 2.54273 7.01896C2.57091 6.95525 2.61209 6.89814 2.66363 6.85128L7.66329 2.30535C7.75532 2.22167 7.87525 2.1753 7.99964 2.17529C8.12403 2.17529 8.24396 2.22165 8.336 2.30533L13.3363 6.85127C13.3879 6.89814 13.4291 6.95525 13.4573 7.01896C13.4854 7.08267 13.5 7.15157 13.5 7.22124V13C13.5 13.0657 13.4871 13.1307 13.4619 13.1914C13.4368 13.252 13.4 13.3071 13.3535 13.3536C13.3071 13.4 13.252 13.4368 13.1913 13.462C13.1306 13.4871 13.0656 13.5 12.9999 13.5L9.99957 13.4996C9.86697 13.4996 9.73981 13.4469 9.64606 13.3531C9.5523 13.2594 9.49963 13.1322 9.49963 12.9996Z" fill="#4C4C4C" />
                  </svg>
                  <span style={{ marginLeft: 10 }}>{'/'}</span>
                </span>
              </a>
            </li>
            {title ?
              <li className="breadcrumb-item">
                <a href={titleLink}>
                  <span className="text-uppercase">{title} <span style={{ marginLeft: 10 }}>{'/'}</span></span>
                </a>
              </li> : <></>}
            {hesabim ?
              <li className="breadcrumb-item active">
                <span className="text-uppercase">{i18next.t("MyAccount")} <span style={{ marginLeft: 10 }}>{'/'}</span></span>
              </li> : <></>}
            {category ?
              <li className="breadcrumb-item">
                <a href={categoryLink}>
                  <span className="text-uppercase">{category} <span style={{ marginLeft: 10 }}>{'/'}</span></span>
                </a>
              </li>
              :
              <></>}

            {brand ?
              <li className="breadcrumb-item">
                <a href={brandLink}>
                  <span className="text-uppercase">{brand} <span style={{ marginLeft: 10 }}>{'/'}</span></span>
                </a>
              </li>
              :
              <></>}
            <li className="breadcrumb-item active">
              <a href={pathLink}>
                <span className="text-uppercase"> {path}</span>
              </a>
            </li>

          </ol>
        </div>
      </div>

    )
  }
}
