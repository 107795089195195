/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Sprite from '../Assets/Icons/sprite.svg';
import { slide as Menu } from 'react-burger-menu';
// import Logo from '../Assets/Image/logo.png';
import Logo from '../Assets/Image/all-shades-logo.svg';
import LogoBlack from '../Assets/Image/all-shades-logo-black.svg';
import i18next from 'i18next';
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import KeepInTouchImg from '../Assets/Image/KeepInTouch__Img.svg';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import SepetComponent from '../Components/SepetComponent';
import { inject, observer } from 'mobx-react';
import ApiBase from '../lib/ApiBase';
import Select from 'react-select';
import { Helmet } from "react-helmet";
const Navbar = observer(class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      className: "searchbar",
      menuOpen: false,
      dropdownOpen: true,
      blogdropdownOpen: false,
      showLoginModal: false,
      profilePopup: false,
      showSuccesModal: false,
      showSuccessActivationModal: false,
      showErrorModal: false,
      forgotPasswordModal: false,
      showAlertModal: false,
      showNewPasswordModal: false,
      isLogin: false,
      profilePopupTarget: null,
      userName: "",
      password: "",
      newPasswordAgain: "",
      newPassword: "",
      searchBarVisible: false,
      searchBarVisibleMobile: false,
      hideIcon: false,
      showChildrenId: 0,
      inputValue: '',
      ForgotEmail: "",
      imageClassName: 'img-fluid matrix1',
      LayoutInfo: [],
      SearchText: "",
      CategoryList: [],
      CartList: [],
      CartTotalPriceStr: "",
      navBarClassName: 'nav__item2',
      favoriteIds: [],
      SearchList: [],
      searchbarText: "",
      WebTitle: "",
      WebDescription: "",

      isScrolled: false,
      isMobile: false
    };

    this.ref = React.createRef();
    this.sepetRef = React.createRef();

    //  console.log("aasdasda",JSON.parse(this.props.Store.CurrentUserStore.CurrentUser))
    // console.log(localStorage.getItem("AccessToken"))
  }
  // onClickMenu = () => {
  //   this.setState({ menuOpen: !this.state.menuOpen })
  // }
  _loginFunction = () => {
    var AnonymousUserId = localStorage.getItem("AnonymousUserId")
    this.props.Store.CurrentUserStore.Login(this.state.userName, this.state.password, AnonymousUserId, this._errorFunction)
    this.setState({ showLoginModal: false })
  }

  removeCartProduct = (item) => {
    var AUID = localStorage.getItem("AnonymousUserId")
    var _isLogin = localStorage.getItem("isLogin");
    // console.log(_isLogin)
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;

    // console.log(item.Quantity + 1)

    ApiBase.Post({
      url: '/remove-cart-product',
      data: {
        LanguageId: LangId,
        ProductId: item.ProductId,
        AnonymousUserId: AUID,
        UserId: _UserId,
      },
      successFunction: (e) => {
        console.log("sepeteekle", e.data)
        // console.log("categories",e.data.data.product.Categories[0].NameTr)
        this.setState({ addCartLoading: false, });
        this.getCartCount();
        this.getMenuCart();
      },
      errorFunction: (e) => {
        this.setState({ addCartLoading: false, showErrorModal: true, errorMessage: e.data.message })
      }
    })
  }


  _errorFunction = () => {
    var errorMessage = localStorage.getItem("errorMessage")
    this.setState({ errorMessage: errorMessage, showErrorModal: true })
  }

  checkScreenWidth = () => {
    if (window.screen.width <= 992) {
      this.setState({ isMobile: true,isScrolled:true });
    }
  }

  componentDidMount() {
    this.checkScreenWidth();
    window.addEventListener("scroll", this.resizeHeaderOnScroll);
    this.getSeoData();
    this.getCategories();
    // console.log("CurrentUser",localStorage.getItem("CurrentUser"))
    this.getLayoutInfo();
    this.generateGuid();

    // console.log("islogin", localStorage.getItem("isLogin"))
    this.getCartCount();
    // console.log("AUID", localStorage.getItem("AnonymousUserId"))
    var Lang = localStorage.getItem("lang")
    if (Lang) {
      // console.log(Lang)
    } else {
      localStorage.setItem("lang", "tr")
    }

    if (this.props.location.pathname === "/adresbilgisi" || this.props.location.pathname === "/odemeyontemi") {
      // console.log(localStorage.getItem("adresBilgileri"))
    } else {
      localStorage.removeItem("adresBilgileri")
    }
  }


  getSeoData = () => {
    this.setState({ seoDataLoading: true });
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-seo-tag-data',
      data: {
        LanguageId: LangId,
        Pathname: this.props.location.pathname,
        DataId: 0
      },
      successFunction: (e) => {
        this.setState({
          WebTitle: e.data.data.seoData.Title,
          WebDescription: e.data.data.seoData.Description
        })
      },
      errorFunction: (e) => {
      }
    })
  }
  getCartCount = () => {
    var AUID = localStorage.getItem("AnonymousUserId")
    var _isLogin = localStorage.getItem("isLogin");
    // console.log(_isLogin)
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
      // console.log(_UserId, AUID)
    }
    this.props.Store.CartStore.GetCartCount(_UserId, AUID)
  }
  generateGuid = () => {
    const isLogin = localStorage.getItem("isLogin")
    var AUID = localStorage.getItem("AnonymousUserId")
    // console.log("AnonymousUserId", AUID)
    if (isLogin !== "true") {

      if (AUID == null) {
        ApiBase.Post({
          url: '/generate-guid',
          data: {

          },
          successFunction: (e) => {
            // console.log("generate guid", e.data.data)
            localStorage.setItem("AnonymousUserId", e.data.data)
          },
          errorFunction: (e) => {

            console.log(e.data.message)
          }
        })
      }


    }
  }
  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      window.location.pathname = `/searchproducts/${false}/${false}/${this.state.SearchText}`;
    }
  }
  getSearch = (e) => {
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    this.setState({ SearchText: e.target.value }, () => {
      ApiBase.Post({
        url: '/search-products',
        data: {
          searchText: this.state.SearchText,
          LanguageId: LangId,
        },
        successFunction: (e) => {
          // console.log("searchList", e.data.data)
          this.setState({
            searchbarText: (e.data.data.List.length <= 0 && this.state.SearchText !== "") ? "Aradığınız kritere ait sonuç bulunamadı" : "",
            SearchList: e.data.data.List
          });
        },
        errorFunction: (e) => {

          console.log("hata", e.data.message)
        }
      })
    })


    //console.log("AnonymousUserId", AUID)

  }
  getMenuCart = () => {
    this.setState({ loadingCart: true })
    var AUID = localStorage.getItem("AnonymousUserId")
    console.log("AUID", AUID)
    var _isLogin = localStorage.getItem("isLogin");
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }

    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    //console.log("AnonymousUserId", AUID)
    ApiBase.Post({
      url: '/get-menu-cart',
      data: {
        AnonymousUserId: AUID,
        UserId: _UserId,
        LanguageId: LangId
      },
      successFunction: (e) => {
        this.setState({
          CartList: [],
          CartList: e.data.data.List,
          CartTotalPriceStr: e.data.data.TotalPriceStr,
          loadingCart: false
        })
      },
      errorFunction: (e) => {

        console.log("hata", e.data.message)
        this.setState({ loadingCart: false })
      }
    })
  }
  /////
  getCategories = () => {
    this.setState({ loadingBlogDetail: true });
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-categories',
      data: {
        LanguageId: LangId
      },
      successFunction: (e) => {
        this.setState({
          CategoryList: e.data.data.categories
        });
      },
      errorFunction: (e) => {
      }
    })
  };
  getLayoutInfo = () => {

    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-layout-info',
      data: {

      },
      successFunction: (e) => {
        //   console.log("layout info ", e.data.data)
        this.setState({
          LayoutInfo: e.data.data
        },function(){console.log(this.state.LayoutInfo)});
      },
      errorFunction: (e) => {

        console.log("layouterror", e.data.message)
      }
    })
  };
  onChangeLangueage(option) {
    localStorage.setItem('lang', option.value);
    window.location.reload();
  }
  onClickDropdown = () => {

    this.setState({ dropdownOpen: !this.state.dropdownOpen })
  }
  ClickBlogDropdown = () => {

    this.setState({ blogdropdownOpen: !this.state.blogdropdownOpen })
  }
  menuOpen = () => {
    this.setState({ menuOpen: !this.state.menuOpen })
  }
  navigateToShop = (item) => {
    this.props.history.push({ pathname: `/shop:${item.CategoryName}/:${item.Id}`, Id: item.Id, CategoryName: item.CategoryName });
    this.setState({ menuOpen: false })
  }
  showLoginModal = (event) => {
    const isLogin = localStorage.getItem("isLogin")
    //console.log(isLogin)
    if (isLogin == "true") {
      this.setState({
        profilePopup: !this.state.profilePopup,
        profilePopupTarget: event.target,
      })
    } else {
      this.setState({ showLoginModal: !this.state.showLoginModal })
    }

  }
  showAlertModal = () => {
    this.setState({ showAlertModal: true, onPressForgotPassword: false })
  }
  hideLoginModal = () => {
    this.setState({
      showLoginModal: false,
      showSuccesModal: false,
      showAlertModal: false,
      forgotPasswordModal: false,
      showNewPasswordModal: false,
      showSuccessActivationModal: false
    })
  }
  showSepetPopover = (event) => {
    this.setState({
      sepetPopup: !this.state.sepetPopup,
      sepetPopupTarget: event.target,
    }, () => {
      if (this.state.sepetPopup == true) {
        // document.getElementById("123").className="fullscreen-overlay"
        // document.body.className = "fullscreen-overlay";
      } else {
        // document.body.className = "";
        //  document.getElementById("123").className=""
      }
      this.getMenuCart();
    })
  };
  hideProfilePopup = () => {
    this.setState({ profilePopup: false })
  }
  hideSepetPopup = () => {
    this.setState({ sepetPopup: false });
    // document.body.className = "";
  }
  // onPressLogin = () => {
  //   if (this.state.userName === "burcu@burcu.com" && this.state.password === "123123123") {
  //     localStorage.setItem("isLogin", true)
  //     this.setState({ showLoginModal: false, showSuccesModal: true });

  //   } else {
  //     alert("başarısız");
  //   }
  // }
  showNewPasswordModal = () => {
    this.setState({ showNewPasswordModal: true })
  }
  showSuccessActivationModal = () => {
    this.setState({ showSuccessActivationModal: true, showNewPasswordModal: false })
  }
  onPressForgotPassword = () => {
    this.setState({ forgotPasswordModal: true, showLoginModal: false, showAlertModal: false })
  }
  onPressLogOut = () => {
    localStorage.setItem("isLogin", false);
    localStorage.removeItem("AccessToken")
    localStorage.removeItem("CurrentUser")
    this.props.history.push("/")
    window.location.reload();
  }
  clearSepet = () => {
    localStorage.removeItem("cartList");
    this.setState({ sepetPopup: false })
  }
  onClickSearchBar = () => {
    this.setState({
      searchBarVisible: !this.state.searchBarVisible,

    }, () => {
      setTimeout(() => {
        this.setState({ className: "searchbar searchbar--w" })
      }, 250);
    })
    if (this.state.searchBarVisible === false) {
      this.setState({ className: "searchbar" })
    }
  }
  onClickMenuCategoryItem = (item) => {

    if (this.state.showChildren === item.Id) {
      this.setState({ showChildren: 0 })
    } else {
      this.setState({ showChildren: item.Id })
    }
  }
  onClickSearchBarMobile = () => {
    this.setState({
      searchBarVisibleMobile: !this.state.searchBarVisibleMobile,
    }, () => {
      setTimeout(() => {
        this.setState({ className: "searchbar searchbar--w bg-transparent" })
      }, 250);
    })
    if (this.state.searchBarVisibleMobile === false) {
      this.setState({ className: " searchbar bg-transparent" })
    }
  }
  resizeHeaderOnScroll = () => {
    if (this.props.location.pathname === "/" && !this.state.isMobile) {
      const distanceY = window.pageYOffset || document.documentElement.scrollTop;
      let headerEl = document.querySelector('.web-navbar');
      // let imageEl = document.querySelector('.lg-img')
      if (distanceY > 95) {
        headerEl.classList.add("smaller")
        // console.log("asdasdas", headerEl.classList);
        // this.setState({ navBarClassName: 'navbar__item2 smaller1' })
        // this.setState({ imageClassName: "img-fluid matrix3" })
        this.setState({ isScrolled: true });
        var x = headerEl.querySelectorAll('.top-links .divHeaderSubCategory');
        for (var i = 0; i < x.length; i++) {
          x[i].classList.add("scrolled");
        }
      } else {
        headerEl.classList.remove("smaller")
        // this.setState({ navBarClassName: 'navbar__item2 lg-img' })
        // this.setState({ imageClassName: "img-fluid matrix1" })
        this.setState({ isScrolled: false });
        var x = headerEl.querySelectorAll('.top-links .divHeaderSubCategory');
        for (var i = 0; i < x.length; i++) {
          x[i].classList.remove("scrolled");
        }

      }
    }

  }
  forgotPasswordSendMail = () => {
    this.setState({ actLoading: true })

    ApiBase.Post({
      url: '/send-forgot-password-mail',
      data: {
        Email: this.state.ForgotEmail
      },
      successFunction: (e) => {
        this.setState({ showAlertModal: true });
      },
      errorFunction: (e) => {
        this.setState({ errorMessage: e.data.message, showErrorModal: true });

      }
    })
  }

  render() {
    const lang = localStorage.getItem('lang') || 'tr';
    const catList = this.state.CategoryList.map((item) => {
      return (
        <a>
          <li key={item.Id} className="nav__sublink ">
            <a href={`/shop/${item.Url}`}>{item.Name} </a>
            <a className="pl-3 p-size--b" onClick={() => { this.onClickMenuCategoryItem(item) }} >{item.Children.length > 0 &&
              <>
                {this.state.showChildren === item.Id ?
                  <svg className="iconSize-xs mr-5" >
                    <use href={Sprite + "#minus"} ></use>
                  </svg >
                  :
                  <svg className="iconSize-xs mr-5" >
                    <use href={Sprite + "#add-sharp"} ></use>
                  </svg >}
                <>
                </>
              </>
            }</a>

            {this.state.showChildren === item.Id &&
              item.Children.length > 0 && item.Children.map(item2 => {
                return (
                  <a href={`/shop/${item2.Url}`} >
                    <li key={item2.Id} className="nav__sublink pl-3 p-size-2">
                      {/* <a href={`/shop/${item.Url}`} > {item.Name} {item.Children.length > 0 && "     + "} */}
                      {item2.Name}
                    </li>
                  </a>
                )
              })
            }
          </li>
        </a >
      )
    })

    const catList2 = this.state.CategoryList.map((item, index) => {
      if (index <= 2) {
        return (
          <a href={`/shop/${item.Url}`} className="divHeaderSubCategory">{item.Name}</a>
        )
      }

    })

    const menuBlogList = [];
    menuBlogList.push(
      <a>
        <li className="nav__sublink ">
          <a href={`/blogandmagazine`}>Blog</a>
        </li>
        <li className="nav__sublink ">
          <a href={`/fashion`}>{i18next.t('Fashion')}</a>
        </li>
        <li className="nav__sublink ">
          <a href={`/artsandworkshops`}>{i18next.t('ArtsWorkshops')}</a>
        </li>
        <li className="nav__sublink ">
          <a href={`/isbirlikleri`}>{i18next.t('Isbirlikleri')}</a>
        </li>
      </a>
    );
    const { history } = this.props;
    const SepetList =
      this.state.CartList != [] ?
        this.state.CartList.map((item, index) => {
          return (
            <SepetComponent item={item} removeCartItem={() => { this.removeCartProduct(item) }}
            />
          )
        })
        :
        [];

    const languageOptions = [
      {
        value: "tr",
        label: "TR"
      },
      {
        value: "en",
        label: "EN"
      }
    ]
    const customStyles = {

      option: (provided, state) => ({
        ...provided,
        fontSize: 12,
        color: 'black',
        backgroundColor: "transparent",

        '&:hover': {
          backgroundColor: "#F3F0E9"

        },
        '&:focus': {
          backgroundColor: "#F3F0E9"

        },
      }),

      control: (provided, state) => ({
        ...provided,
        marginTop: "5%",
        borderWidth: 0,
        borderColor: '#EDE8DE',
        backgroundColor: "transparent",
        borderRadius: 0,
        cursor: 'pointer',
        outline: "transparent",
        boxShadow: state.isFocused ? '0 0 0 0.2rem transparent' : '',
        '&:focus': {
          borderColor: "#F3F0E9",
          '&:hover': {
            borderColor: "#F3F0E9"

          },
        },

      }),



      container: (provided) => ({
        ...provided,
        //  backgroundColor:'red',
        '&:focus': {
          borderColor: "#F3F0E9",
        },
        width: 80,

      }),

      dropdownIndicator: (provided) => ({
        ...provided,
        //  backgroundColor:'red',
        color: '#B29F78',
        borderLeft: 0

      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        width: 0
      })


    }
    return (
      <nav>
        <Helmet>
          <title>{this.state.WebTitle}</title>
          <meta name="description" content={this.state.WebDescription} />
        </Helmet>
        <div className="mobil-navbar d-lg-none d-block">
          <div className=" container d-flex  justify-content-between align-items-center">
            {/* <Select
              value={languageOptions.find(op => { return op.value == lang })}
              styles={customStyles}
              options={languageOptions}
              onChange={this.onChangeLangueage}
              isSearchable={false}
            >
            </Select> */}
            {/* <select onChange={this.onChangeLangueage} value={lang} className=" languageSelect d-lg-none d-inline">

              <option value="tr" >TR</option>
              <option value="en" >EN</option>

            </select> */}
            {this.state.searchBarVisibleMobile === true &&
              <div className="nav__item4">
                <input placeholder="Arama yapınız..." type="text" className={this.state.className} value={this.state.SearchText} onChange={(e) => this.getSearch(e)} onKeyDown={this._handleKeyDown} />
                {this.state.SearchText !== "" ?
                  <div className="suggestion-result">
                    <p className="suggestion-result-title h-size-3 font-weight-bold"> {i18next.t("RelatedResults")} </p>
                    <ul className="list-unstyled">
                      {this.state.SearchList.map((item) => {
                        return (
                          <a href={`/productdetail/${item.Url}`} >{item.Title}</a>
                        )
                      })}
                    </ul>
                    {this.state.SearchList.length > 0 ? <a class="suggestin-all-result" href={`/searchproducts/${false}/${false}/${this.state.SearchText}`}>{i18next.t("SeeAll")}</a> : <p>{this.state.searchbarText} </p>}
                  </div>
                  :
                  <></>}

              </div>
            }
            <div className="nav__item3  w-25 d-flex justify-content-between ml-auto">
              {this.state.searchBarVisibleMobile === false &&
                <a onClick={this.showLoginModal} className=" d-lg-none d-inline-block ">
                  <svg className="iconSize-sm" >
                    <use href={Sprite + "#profile-icon-black"} ></use>
                  </svg >
                </a>
              }
              <a onClick={this.onClickSearchBarMobile} className="d-lg-none d-inline-block">
                {
                  this.state.isScrolled == false ?
                    <svg className="iconSize-sm" >
                      <use href={this.state.searchBarVisibleMobile === true ? Sprite + "#navbar-close" : Sprite + "#menu-search"} ></use>
                    </svg >
                    :
                    <svg className="iconSize-sm" >
                      <use href={this.state.searchBarVisibleMobile === true ? Sprite + "#navbar-close" : Sprite + "#menu-search-black"} ></use>
                    </svg >
                }
              </a>
            </div>
          </div>
        </div>
        {this.props.location.pathname === "/"
          ?
          <>
            <div id="js-header" className=" web-navbar shadow-sm  web-navbar--home  container-fluid ">
              <div className="container d-flex  justify-content-between align-items-center">
                <div className="nav__item1 d-flex justify-content-between">
                  <a onClick={this.menuOpen} className="menu-burger">
                    {
                      this.state.isScrolled == false ?
                        <svg className="iconSize-md" >
                          <use href={Sprite + "#menu-hamburger"} ></use>
                        </svg >
                        :
                        <svg className="iconSize-md" >
                          <use href={Sprite + "#menu-hamburger-black"} ></use>
                        </svg >
                    }

                  </a>
                  {/* <select onChange={this.onChangeLangueage} value={lang} className="languageSelect d-lg-inline d-none">
                  <option value="tr" >TR</option>
                  <option value="en" >EN</option>
                </select> */}
                  {/* <div className="d-none d-lg-block">
                    <Select
                      value={languageOptions.find(op => { return op.value == lang })}
                      styles={customStyles}
                      options={languageOptions}
                      onChange={this.onChangeLangueage}
                      isSearchable={false}

                    >
                    </Select>
                  </div> */}

                </div>
                {this.state.searchBarVisible === false ?
                  <div className={this.state.navBarClassName}>
                    <a href="/">
                      {
                        this.state.isScrolled == false ?
                          <img src={Logo} className={this.state.imageClassName} />
                          :
                          <img src={LogoBlack} className={this.state.imageClassName} />
                      }
                    </a>
                  </div>
                  :
                  <>
                    <div className="nav__item4">
                      <input placeholder="Arama yapınız..." type="text" className={this.state.className} value={this.state.SearchText} onChange={(e) => this.getSearch(e)} onKeyDown={this._handleKeyDown} />
                      {this.state.SearchText !== "" ?
                        <div className="suggestion-result">
                          <p className="suggestion-result-title h-size-3 font-weight-bold"> {i18next.t("RelatedResults")}</p>
                          <ul className="list-unstyled">
                            {this.state.SearchList.map((item) => {
                              return (
                                <a href={`/productdetail/${item.Url}`} >{item.Title}</a>
                              )
                            })}
                          </ul>
                          {this.state.SearchList.length > 0 ? <a class="suggestin-all-result" href={`/searchproducts/${false}/${false}/${this.state.SearchText}`}>{i18next.t("SeeAll")}</a> : <p>{this.state.searchbarText}</p>}

                        </div>
                        :
                        <></>}
                    </div>
                  </>
                }
                <div className="nav__item3 d-flex justify-content-between align-items-center ">
                  <a onClick={this.showLoginModal} className="d-lg-inline d-none">
                    {this.state.isScrolled == false ?
                      <svg className="iconSize-sm" >
                        <use href={Sprite + "#profile-icon"} ></use>
                      </svg >
                      :
                      <svg className="iconSize-sm" >
                        <use href={Sprite + "#profile-icon-black"} ></use>
                      </svg >
                    }
                  </a>
                  <a onClick={this.onClickSearchBar} className="d-lg-inline d-none sb2">
                    {
                      this.state.isScrolled == false ?
                        <svg className="iconSize-sm" >
                          <use href={this.state.searchBarVisible === true ? Sprite + "#navbar-close" : Sprite + "#menu-search"} ></use>
                        </svg >
                        :
                        <svg className="iconSize-sm" >
                          <use href={this.state.searchBarVisible === true ? Sprite + "#navbar-close" : Sprite + "#menu-search-black"} ></use>
                        </svg >
                    }
                  </a>
                  <a onClick={this.showSepetPopover} className="  d-flex align-items-center">
                    {
                      this.state.isScrolled == false ?
                        <svg className="iconSize-sm" >
                          <use href={Sprite + "#menu-shopping"}  ></use>
                        </svg >
                        :
                        <svg className="iconSize-sm" >
                          <use href={Sprite + "#menu-shopping-black"}  ></use>
                        </svg >
                    }
                    {
                      this.props.Store.CartStore.CartCount > 0 && this.props.Store.CartStore.CartCount ?
                        <span className="countItemInBasket font-weight-bold">{this.props.Store.CartStore.CartCount > 0 && this.props.Store.CartStore.CartCount} </span>
                        :
                        <></>
                    }
                  </a>
                </div>
              </div>
              <div className="divTopLinksContainer">
                <div className="container top-links justify-content-around d-none d-lg-flex">
                  {/* <a href={`/shop/kadin/11`}>{i18next.t("Woman")} </a>

                <a href={`/shop/ev/13`}>{i18next.t("HomeAndLife")}</a>
                <a href={`/shop/bebek-cocuk/12`}>{i18next.t("Baby")}</a>
                <a href={`/shop/evcIl-hayvan/14`}>{i18next.t("Pet")}</a> */}
                  {catList2}
                  <a href="/about" className="divHeaderSubCategory">HAKKIMIZDA</a>
                  <a href="/musteriHizmetleri" className="divHeaderSubCategory">İLETİŞİM</a>
                  <a href="/searchproducts/false/false/true" className="divHeaderSubCategoryDiscount">İNDİRİM</a>
                </div>
              </div>
            </div>
          </>
          :
          <div className=" web-navbar container-fluid shadow-sm smaller">
            <div className=" container d-flex  justify-content-between align-items-center">
              <div className="nav__item1 d-flex justify-content-between">
                <a onClick={this.menuOpen} className="menu-burger">
                  <svg className="iconSize-md" >
                    <use href={Sprite + "#menu-hamburger-black"} ></use>
                  </svg >
                </a>
                {/* <div className="d-none d-lg-block">
                  <Select
                    value={languageOptions.find(op => { return op.value == lang })}
                    styles={customStyles}
                    options={languageOptions}
                    onChange={this.onChangeLangueage}
                    isSearchable={false}

                  >
                  </Select>
                </div> */}
              </div>
              {this.state.searchBarVisible === false ?
                <div className="nav__item2 other-pages-logo">
                  <a href="/">
                    <img src={LogoBlack} className="img-fluid matrix1" />
                  </a>
                </div>
                :
                <>
                  <div className="nav__item4">
                    <input placeholder="Arama yapınız..." type="text" className={this.state.className} value={this.state.SearchText} onChange={(e) => this.getSearch(e)} onKeyDown={this._handleKeyDown} />
                    {this.state.SearchText !== "" ?
                      <div className="suggestion-result">
                        <p className="suggestion-result-title h-size-3 font-weight-bold"> {i18next.t("RelatedResults")}</p>
                        <ul className="list-unstyled">
                          {this.state.SearchList.map((item) => {
                            return (
                              <a href={`/productdetail/${item.Url}`}>{item.Title}</a>
                            )
                          })}

                        </ul>
                        {this.state.SearchList.length > 0 ? <a class="suggestin-all-result" href={`/searchproducts/${false}/${false}/${this.state.SearchText}`}>{i18next.t("SeeAll")}</a> : <p>{this.state.searchbarText}</p>}
                      </div>
                      :
                      <></>}
                  </div>
                </>
              }
              <div className="nav__item3 d-flex justify-content-between align-items-center ">
                <a onClick={this.onClickSearchBar} className="d-lg-inline d-none sb2">
                  <svg className="iconSize-sm" >
                    <use href={this.state.searchBarVisible === true ? Sprite + "#navbar-close" : Sprite + "#menu-search-black"} ></use>
                  </svg>
                </a>
                <a onClick={this.showLoginModal} className="d-lg-inline d-none">
                  <svg className="iconSize-sm" >
                    <use href={Sprite + "#profile-icon-black"} ></use>
                  </svg >
                </a>
                <a onClick={this.showSepetPopover} className=" d-flex align-items-center">
                  <svg className="iconSize-sm " >
                    <use href={Sprite + "#menu-shopping-black"}  ></use>
                  </svg >
                  {this.props.Store.CartStore.CartCount > 0 && this.props.Store.CartStore.CartCount ?
                    <span className=" countItemInBasket font-weight-bold"> {this.props.Store.CartStore.CartCount > 0 && this.props.Store.CartStore.CartCount}</span>
                    :
                    <></>
                  }
                </a>
              </div>
            </div>
            {/* <div className="top-links justify-content-around d-none d-lg-flex pt-2">
              <a href={`/shop/kadin/11`}>{i18next.t("Woman")} </a>

              <a href={`/shop/ev/13`}>{i18next.t("HomeAndLife")}</a>
              <a href={`/shop/bebek-cocuk/12`}>{i18next.t("Baby")}</a>
              <a href={`/shop/evcIl-hayvan/14`}>{i18next.t("Pet")}</a>
            </div> */}
          </div>}
        <Menu onClose={() => { this.setState({ menuOpen: false }) }} isOpen={this.state.menuOpen} customBurgerIcon={false} customCrossIcon={false}>
          <a onClick={this.menuOpen}>
            <svg className="iconSize-sm close-sharp" >
              <use href={Sprite + "#close-sharp"} ></use>
            </svg >
          </a>
          <ul className="nav__links list-unstyled">
            <li className="nav__link">
              <a href="/">{i18next.t('Home')}</a>
            </li>
            <li className="nav__link">
              <div>
                <a href="/searchproducts" className="mobil-r d-block d-flex justify-content-lg-between menu-shopping">
                  {i18next.t('Shop')}
                  {/* {this.state.dropdownOpen === true ?
                    <svg className="iconSize-xs mr-5" >
                      <use href={Sprite + "#minus"} ></use>
                    </svg >
                    :
                    <svg className="iconSize-xs mr-5" >
                      <use href={Sprite + "#add-sharp"} ></use>
                    </svg >
                  } */}
                </a>
                {this.state.dropdownOpen === true ?
                  <ul className="list-unstyled navMenuSubCategory">
                    <li className="nav__sublink">
                      <a  >
                        {catList}
                      </a>
                    </li>
                  </ul>
                  :
                  <div></div>
                }
              </div>
            </li>
            <li className="nav__link">
              <a href="/about">{i18next.t('About')}</a>
            </li>
            {/* <li className="nav__link">
              <a href="/designerlist">Markalar</a>
            </li> */}
            {/* <li className="nav__link">
              <div>
                <a onClick={this.ClickBlogDropdown} className="mobil-r d-block d-flex justify-content-lg-between">
                  {i18next.t('BlogMagazine')}
                  {this.state.blogdropdownOpen === true ?
                    <svg className="iconSize-xs mr-5" >
                      <use href={Sprite + "#minus"} ></use>
                    </svg >
                    :
                    <svg className="iconSize-xs mr-5" >
                      <use href={Sprite + "#add-sharp"} ></use>
                    </svg >
                  }
                </a>
                {this.state.blogdropdownOpen === true ?
                  <ul className="list-unstyled navMenuSubCategory">
                    <li className="nav__sublink">
                      <a  >
                        {menuBlogList}
                      </a>
                    </li>
                  </ul>
                  :
                  <div></div>
                }
              </div>
            </li> */}
            {/* <li className="nav__link">
              <a href="/career">{i18next.t('Careers')}</a>
            </li> */}
            <li className="nav__link">
              <a onClick={() => { history.push('/musteriHizmetleri'); this.setState({ menuOpen: false }) }} >İletişim</a>
            </li>
          </ul>
          <div className="nav__info">
            <a className="nav__mail"> {this.state.LayoutInfo.Email}</a>
            <div className="socialMediaTags mt-5">
              <p>{i18next.t('FollowUs')} </p>
              <ul className="d-flex justify-content-lg-between flex-wrap">
                <a href={this.state.LayoutInfo.Facebook} target="_blank" >
                  <svg className="iconSize-sm" >
                    <use href={Sprite + "#facebook"} ></use>
                  </svg >
                </a>
                <a href={this.state.LayoutInfo.Instagram} target="_blank" >
                  <svg className="iconSize-sm" >
                    <use href={Sprite + "#instagram"} ></use>
                  </svg >
                </a>
                <a href={this.state.LayoutInfo.Youtube} target="_blank">
                  <svg className="iconSize-sm" >
                    <use href={Sprite + "#youtube"} ></use>
                  </svg >
                </a>
                <a href={this.state.LayoutInfo.LinkedIn} target="_blank" >
                  <svg className="iconSize-sm" >
                    <use href={Sprite + "#linkedin"} ></use>
                  </svg >
                </a>
              </ul>
            </div>
          </div>
        </Menu>
        {/* Login Modal */}
        <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showLoginModal} onHide={this.hideLoginModal}>
          <Modal.Header closeButton>
          </Modal.Header>
          <h4 className="LoginModal__title text-center">{i18next.t('MemberLogin')}</h4>
          <p></p>
          <Modal.Body>
            <div className="divFormSubTitle">Email</div>
            <input type="email" className="form-control divFromInput" placeholder={i18next.t('EmailAdress')} value={this.state.userName} onChange={(e) => this.setState({ userName: e.target.value })} />
            <div className="divFormSubTitle">Şifre</div>
            <input type="password" className="form-control divFromInput" placeholder={i18next.t('Password')} value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
            <div className="text-center  w-50  mx-auto">
              <a className="h-size-3 p-size--b" onClick={() => { this._loginFunction() }} className="submit-button">{i18next.t('SignIn')}</a>
            </div>
            <div className="LoginModal__links">
              <div className=" text-center">
                <a className="p-size-2 " onClick={this.onPressForgotPassword} >{i18next.t('ForgotPassword')}</a>
                <div className="LoginModal__hr  mx-auto w-50">
                  <p className="mx-auto"></p>
                </div>
                <p className="p-size-2 ">{i18next.t('DontYouHaveaMemberShip')} <a href="/uyeol"> <strong> {i18next.t('SignUp')}</strong></a></p>
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
              <div className="KeepInTouch__Img ">
                <img src={KeepInTouchImg} className="img-fluid"></img>
              </div>
            </Modal.Footer> */}
        </Modal>
        {/* Succes Modal */}
        <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showSuccesModal} onHide={this.hideLoginModal}>
            <Modal.Header closeButton>
            </Modal.Header>
            <h4 className="LoginModal__title text-center"> {i18next.t("ProcessSuccess")}</h4>
            <div className="LoginModal_BottmBorder"></div>
            <p></p>
            <Modal.Body>
              <div className="text-center mb-3">
                <svg className="iconSize-md" >
                  <use href={Sprite + "#smile"} ></use>
                </svg >
              </div>
              <form className="LoginModal  ">
                <p className="text-center p-size-1"> {i18next.t("LoginSuccessMessage")}</p>
                <div className="text-center  w-50  mx-auto">
                  <button onClick={() => history.push('/')} className="submit-button">{i18next.t('Home')}</button>
                </div>
              </form>

            </Modal.Body>
            {/* <Modal.Footer>
              <div className="KeepInTouch__Img ">
                <img src={KeepInTouchImg} className="img-fluid"></img>
              </div>
            </Modal.Footer> */}
        </Modal>
        {/* Error Modal  */}
        <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showErrorModal} onHide={() => { this.setState({ showErrorModal: false }) }}>
          <Modal.Header closeButton>
          </Modal.Header>
          <h4 className="LoginModal__title text-center">{i18next.t('UnSuccessful')}</h4>
          <div className="LoginModal_BottmBorder"></div>
          <p></p>
          <Modal.Body>
            <div className="text-center mb-3">
              <svg className="iconSize-md" >
                <use href={Sprite + "#sad-face"} ></use>
              </svg >
            </div>
            <form className="LoginModal  ">
              <p className="text-center  p-size-1">{this.state.errorMessage}
                {/* {i18next.t('ErrorMessage')} */}
              </p>
              <div className="text-center  w-50  mx-auto">
                <a onClick={() => this.setState({ showErrorModal: false })} className="submit-button">{i18next.t('Home')}</a>
              </div>
            </form>
          </Modal.Body>
          {/* <Modal.Footer>
              <div className="KeepInTouch__Img ">
                <img src={KeepInTouchImg} className="img-fluid"></img>
              </div>
            </Modal.Footer> */}
        </Modal>
        {/* Şifremi Unuttum Modal */}
        <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.forgotPasswordModal} onHide={this.hideLoginModal}>
          <Modal.Header closeButton>
          </Modal.Header>
          <h4 className="LoginModal__title text-center">{i18next.t('ForgotPassword')}</h4>
          <p></p>
          <Modal.Body>
            <form className="LoginModal">
              <p className="text-center p-size-1">{i18next.t('ForgotPasswordText')}</p>
              <div className="divFormSubTitle">Email</div>
              <input type="email" className="form-control divFromInput" placeholder={i18next.t('EmailAdress')} value={this.state.ForgotEmail} onChange={(e) => this.setState({ ForgotEmail: e.target.value })} required />
              <div className="text-center  w-50  mx-auto">
                <a onClick={this.forgotPasswordSendMail} className="submit-button">{i18next.t('Send')}</a>
              </div>
            </form>
          </Modal.Body>
          {/* <Modal.Footer>
              <div className="KeepInTouch__Img ">
                <img src={KeepInTouchImg} className="img-fluid"></img>
              </div>
            </Modal.Footer> */}
        </Modal>
        {/* Alert Modal */}
        <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showAlertModal} onHide={this.hideLoginModal}>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <form className="LoginModal">
              <div className="text-center mb-3">
                <svg className="iconSize-md" >
                  <use href={Sprite + "#smile"} ></use>
                </svg >
              </div>
              <p className="text-center h-size-3 p-size--color2 ">{i18next.t('AlertModalText')} </p>
              <div className="d-flex px-2">
                <div className="mx-auto ">
                  <p className=" p-size-1 mb-2"> {i18next.t('AlertModalTextHeader')}</p>
                  <p className=" p-size-3 font--opacity  mb-2"> {i18next.t('AlertModalText1')}</p>
                  <p className=" p-size-3  font--opacity mb-2"> {i18next.t('AlertModalText2')}</p>
                  <p className=" p-size-3  font--opacity mb-2">{i18next.t('AlertModalText3')}</p>
                </div>
              </div>
              <div className="text-center  w-50  mx-auto">
                <button onClick={this.onPressForgotPassword} className="submit-button">{i18next.t('TurnBack')}</button>
              </div>
            </form>
          </Modal.Body>
          {/* <Modal.Footer>
              <div className="KeepInTouch__Img ">
                <img src={KeepInTouchImg} className="img-fluid"></img>
              </div>
            </Modal.Footer> */}
        </Modal>
        {/*Şifre Yenileme Başarılı Modal*/}
        <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showSuccessActivationModal} onHide={this.hideLoginModal}>
            <Modal.Header closeButton>
            </Modal.Header>
            <h4 className="LoginModal__title text-center">{i18next.t('ProcessSuccess')}</h4>
            <Modal.Body>
              <form className="LoginModal  ">
                <div className="text-center mb-3">
                  <svg className="iconSize-md" >
                    <use href={Sprite + "#smile"} ></use>
                  </svg >
                </div>
                <p className="text-center p-size-1">{i18next.t('NewPasswordSuccessText')}</p>
                <div className="text-center  w-50  mx-auto">
                  <button onClick={() => history.push('/')} className="submit-button">{i18next.t('Home')}</button>
                </div>
              </form>
            </Modal.Body>
            {/* <Modal.Footer>
              <div className="KeepInTouch__Img ">
                <img src={KeepInTouchImg} className="img-fluid"></img>
              </div>
            </Modal.Footer> */}
        </Modal>
        <div ref={this.ref} className="ProfilPopever">
          <Overlay
            rootClose={true}
            onHide={this.hideProfilePopup}
            show={this.state.profilePopup}
            target={this.state.profilePopupTarget}
            placement="bottom"
            container={this.ref.current}
            containerPadding={20}
          >
            <Popover id="popover-contained">
              <Popover.Title >
                <div className="ProfilPopever__header d-flex">
                  <h6 class="d-inline" >{i18next.t('MyAccount')}</h6>
                  <a onClick={this.hideProfilePopup} className="ml-auto">
                    <svg className="iconSize-xs" >
                      <use href={Sprite + "#close-sharp"} ></use>
                    </svg>

                  </a>
                </div>
              </Popover.Title>
              <Popover.Content>
                <div className="ProfilPopever__body">
                  <p>
                    <svg className="iconSize-xxs" >
                      <use href={Sprite + "#profile-icon2"} ></use>
                    </svg> <a href="/profilim" className="p-size-3">{i18next.t('MyProfile')} </a>
                  </p>
                  <p>
                    <svg className="iconSize-xxs" >
                      <use href={Sprite + "#truck2"} ></use>
                    </svg ><a href="/siparislerim" className="p-size-3"> {i18next.t('MyOrders')}</a>
                  </p>
                  <p className="ProfilPopever__like-button">
                    <svg className="iconSize-xxs" >
                      <use href={Sprite + "#like-button2"} ></use>
                    </svg ><a href="/favorilerim" className="p-size-3">{i18next.t('MyFavorites')}</a>
                  </p>
                  <p>
                    <svg className="iconSize-xxs" >
                      <use href={Sprite + "#map-pin2"} ></use>
                    </svg ><a href="/adreslerim" className="p-size-3">{i18next.t('MyAdresses')}</a>
                  </p>
                  {/* <p>
                    <svg className="iconSize-xxxs" >
                      <use href={Sprite + "#gift"} ></use>
                    </svg ><a className="p-size-3">{i18next.t('MyGifts')}</a>
                  </p> */}
                  <p className="ProfilPopever__logout">
                    <svg className="iconSize-xxs" >
                      <use href={Sprite + "#log-out2"} ></use>
                    </svg ><a onClick={this.onPressLogOut} className="p-size-3"> {i18next.t('LogOut')}</a>
                  </p>
                </div>
              </Popover.Content>
            </Popover>
          </Overlay>
        </div>
        <div ref={this.sepetRef}>
          <Overlay
            rootClose={true}
            onHide={this.hideSepetPopup}
            show={this.state.sepetPopup}
            target={this.state.sepetPopupTarget}
            placement="bottom"
            container={this.sepetRef.current}
            containerPadding={20}
          // rootClose 
          >
            <Popover id="popover-contained" className="sepetPopover">
              <Popover.Title >
                <div className=" d-flex">
                  <a href="/sepetim">
                    <h6 class="d-inline" >{i18next.t('MyBasket')}</h6>
                  </a>
                  <a onClick={this.hideSepetPopup} className="ml-auto">
                    <svg className="iconSize-xs" >
                      <use href={Sprite + "#close-sharp"} ></use>
                    </svg>

                  </a>
                </div>
              </Popover.Title>
              <Popover.Content>
                <div className=" BasketPopever__body px-4 " id="style-4">
                  {SepetList}
                </div>
                {SepetList.length > 0 ?
                  <div className=" BasketPopever__footer px-4 border-top">
                    <div className="row BasketPopover__Result">
                      <div className="col-6 pl-0">
                        <p className=" BasketPopover__Total p-size-3 p-size--b">GENEL TOPLAM:</p>
                      </div>
                      <div className="col-6 text-right">
                        <p className=" BasketPopover__TotalPrice p-size-3 p-size--b">{this.state.CartTotalPriceStr} TL</p>
                      </div>
                    </div>
                    <div className="row p-0">
                      <div className="col-12 p-0">
                        <a href="/sepetim" className="BasketPopover__btn h-size-2">{i18next.t('CompleteShopping')}</a>
                      </div>
                    </div>
                    {/* <button className="p-size-2 border-0  bg-transparent" onClick={this.clearSepet} >
                      <p className="mb-0 p-size--b p-size--color2"> {i18next.t('ClearBasket')}</p>

                    </button> */}
                  </div>
                  :
                  this.state.loadingCart == true ? ""
                    :
                    <div className="col-12">
                      <p className=" BasketPopover__Total p-size-3 p-size--b">Sepetinizde ürün bulunmamaktadır!</p>
                    </div>
                }
              </Popover.Content>
            </Popover>
          </Overlay>
        </div>
      </nav >


    )
  }
})
export default inject((stores) => ({
  Store: stores.Store,
}))(withRouter(Navbar));