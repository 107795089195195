/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import BreadCrumb from '../Components/BreadCrumb';
import Sprite from '../Assets/Icons/sprite.svg';
import ProductCard from '../Components/ProductCard';
import Slider from "react-slick";
import i18next from 'i18next';
import Select from 'react-select';
import ApiBase from '../lib/ApiBase';
import { inject, observer } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import KeepInTouchImg from '../Assets/Image/KeepInTouch__Img.svg';
import imageZoom from '../lib/ImageZoom'
import SimpleReactLightbox from 'simple-react-lightbox'
import { SRLWrapper } from "simple-react-lightbox";

import LoadingGif from '../Assets/Icons/LoadingGif.gif';
const ProductDetail = observer(class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: 'burak',
      nav1: null,
      nav2: null,
      favoriteList: [],
      isFavorited: -1,
      selectedSizeId: 0,
      cart: [],
      addCartLoading: false,
      showErrorModal: false,
      errorMessage: "",
      selectedCountId: 1,
      ProductDetailData: [],
      DesignerList: [],
      ProductCategoryList: [],
      ProductCategoryName: "",
      ProductPhotos: [],
      RecommendedProducts: [],
      ProductVariations: [],
      SelectedVariationPrice: 0,
      SelectedVariationDiscountedPrice: 0,
      favoriteIds: [],
      totalStock: 0,
      SelectedCountNumber: 1,
      dropdown1: false,
      dropdown2: false,
      dropdown3: false,
    };

  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
    this.getProductDetail();
    this.getRecommendedProducts();
    this.getFavoriteIds();
  }
  getFavoriteIds = async () => {
    var _isLogin = localStorage.getItem("isLogin");
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    var favIdList = [];
    await ApiBase.Post({
      url: '/get-favorite-ids',
      data: {

        UserId: _UserId,

      },
      successFunction: (e) => {
        e.data.data.List.map(item =>
          favIdList.push(item.ProductId));
        this.setState({ favoriteIds: favIdList })
        //this.setState({ CartList: e.data.data.List, CartTotalPriceStr: e.data.data.TotalPriceStr })
      },
      errorFunction: (e) => {
      }
    })
  }
  toggleFavorite = (item) => {
    const i = this.state.favoriteList.indexOf(item.Id)

    if (i >= 0) {
      this.state.favoriteList.splice(i, 1)
    } else {
      this.state.favoriteList.push(item.Id)
    }
    this.setState({ favoriteList: this.state.favoriteList });
  };

  handleChangeCount = (item) => {
    this.setState({ selectedCountId: item.label }, () => {
    })
  }
  handleIncreaseCount = () => {

    this.setState({ SelectedCountNumber: this.state.SelectedCountNumber + 1 });
  }
  handleDecreaseCount = () => {
    if (this.state.SelectedCountNumber - 1 > 0) {
      this.setState({ SelectedCountNumber: this.state.SelectedCountNumber - 1 });
    }
  }

  getProductDetail = () => {
    this.setState({ loading: true })
    const productId = this.props.match.params.productId;
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-product-detail',
      data: {
        LanguageId: LangId,
        ProductId: productId
      },
      successFunction: (e) => {
        this.setState({
          ProductDetailData: e.data.data.product,
          DesignerList: e.data.data.product.Designer,
          ProductPhotos: e.data.data.product.Photos,
          ProductVariations: e.data.data.product.Variations,
          ProductCategoryList: e.data.data.product.Categories,
          loading: false,
          totalStock: e.data.data.product.IsMainVariation === true ? e.data.data.product.Stock : e.data.data.product.Variations.reduce((totalStock, x) => totalStock + x.Stock, 0)
        });
      },
      errorFunction: (e) => {
        this.setState({ loadingBlogs: false, loading: false })
      }
    })
  };

  /////
  getRecommendedProducts = () => {
    this.setState({ loading: true })
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-featured-products',
      data: {
        LanguageId: LangId,
      },
      successFunction: (e) => {
        this.setState({
          RecommendedProducts: e.data.data.productList,
        });
      },
      errorFunction: (e) => {
        this.setState({ loadingBlogs: false })
      }
    })
  }

  onChangeSelectSize = (item) => {

    this.setState({ selectedSizeId: item.value }, () => {
      const a = this.state.ProductVariations.find(x => x.VariationId === this.state.selectedSizeId)
      this.setState({
        SelectedVariationPrice: a.Price,
        SelectedVariationDiscountedPrice: a.DiscountedPrice
      })
    });
  }

  AddToCart = () => {

    const productId = this.props.match.params.productId;
    var AUID = localStorage.getItem("AnonymousUserId") ? localStorage.getItem("AnonymousUserId") : null
    var _isLogin = localStorage.getItem("isLogin");
    var _UserId = 0;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    if (this.state.ProductDetailData.IsMainVariation == false && this.state.ProductVariations.length <= 0) {
      this.setState({ showErrorModal: true, addCartLoading: false, errorMessage: i18next.t("NOSTOCK") })
    }
    else {
      this.setState({ addCartLoading: true })
      ApiBase.Post({
        url: '/add-to-cart',
        data: {
          LanguageId: LangId,
          ProductId: productId,
          VariationId: this.state.selectedSizeId,
          IsMainVariation: this.state.ProductDetailData.IsMainVariation,
          AnonymousUserId: AUID,
          Quantity: this.state.SelectedCountNumber,
          UserId: _UserId

        },
        successFunction: (e) => {
          const senddata = {
            LanguageId: LangId,
            ProductId: productId,
            VariationId: this.state.selectedSizeId,
            IsMainVariation: this.state.ProductDetailData.IsMainVariation,
            AnonymousUserId: AUID,
            Quantity: this.state.selectedCountId,
            UserId: _UserId
          };

          this.setState({ addCartLoading: false, })
          this.notify();
          this.getCartCount();
        },
        errorFunction: (e) => {
          this.setState({ addCartLoading: false, showErrorModal: true, errorMessage: e.data.message })
        }
      })
    }

  }
  AddToCart2 = () => {

    const productId = this.props.match.params.productId;
    var AUID = localStorage.getItem("AnonymousUserId") ? localStorage.getItem("AnonymousUserId") : null
    var _isLogin = localStorage.getItem("isLogin");
    var _UserId = 0;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    if (this.state.ProductDetailData.IsMainVariation == false && this.state.ProductVariations.length <= 0) {
      this.setState({ showErrorModal: true, addCartLoading: false, errorMessage: i18next.t("NOSTOCK") })
    }
    else {
      this.setState({ addCartLoading: true })
      ApiBase.Post({
        url: '/add-to-cart',
        data: {
          LanguageId: LangId,
          ProductId: productId,
          VariationId: this.state.selectedSizeId,
          IsMainVariation: this.state.ProductDetailData.IsMainVariation,
          AnonymousUserId: AUID,
          Quantity: this.state.SelectedCountNumber,
          UserId: _UserId

        },
        successFunction: (e) => {
          const senddata = {
            LanguageId: LangId,
            ProductId: productId,
            VariationId: this.state.selectedSizeId,
            IsMainVariation: this.state.ProductDetailData.IsMainVariation,
            AnonymousUserId: AUID,
            Quantity: this.state.selectedCountId,
            UserId: _UserId
          };

          this.setState({ addCartLoading: false, })
          this.notify();
          this.getCartCount();
          window.location.href = "/sepetim";
        },
        errorFunction: (e) => {
          this.setState({ addCartLoading: false, showErrorModal: true, errorMessage: e.data.message })
        }
      })
    }

  }
  notify = () => toast('Sepete Eklendi!', {
    className: "Toastify__toast--default",
    bodyClassName: ".Toastify__toast-body",

    autoClose: 3000
  });

  getCartCount = () => {
    var AUID = localStorage.getItem("AnonymousUserId")
    var _isLogin = localStorage.getItem("isLogin");

    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId

    }
    this.props.Store.CartStore.GetCartCount(_UserId, AUID)
  }

  AddFavorite = (ProductId) => {
    this.setState({ addFavoriteLoading: true }, () => {
    })
    var _isLogin = localStorage.getItem("isLogin");
    var cUser = [];
    if (_isLogin === "true") {
      cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
    }
    ApiBase.Post({
      url: '/add-favourite',
      data: {
        ProductId: ProductId,
        CurrentUser: cUser
      },
      successFunction: (e) => {
        this.setState({ addFavoriteLoading: false })
        this.getFavoriteIds();
      },
      errorFunction: (e) => {
        this.setState({ showErrorModal: true, errorMessage: e.data.message, addFavoriteLoading: false })
        document.body.style.backgroundColor = "transparent";
        document.body.style.opacity = 1

      }
    })
  }

  removeFavorites = (ProductId) => {
    this.setState({ addFavoriteLoading: true }, () => {
    });
    var _isLogin = localStorage.getItem("isLogin");
    var cUser = [];
    if (_isLogin === "true") {
      cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
    }


    ApiBase.Post({
      url: '/delete-favourite',
      data: {
        ProductId: ProductId,
        CurrenUser: cUser
      },
      successFunction: (e) => {
        this.getFavoriteIds().then(() => {
          this.setState({ addFavoriteLoading: false })
        })
      },
      errorFunction: (e) => {
        this.setState({ addFavoriteLoading: false, showErrorModal: true, errorMessage: e.data.message });

      }
    })
  }
  setZoomElement() {
    if (this.state.showResultDiv == true) {
      const imgElement = document.querySelector('.mainslider .slick-active.slick-current div img');
      imageZoom(imgElement);
    }

  }

  onClickDropdown1 = () => {
    this.setState({ dropdown1: !this.state.dropdown1 })
  }
  onClickDropdown2 = () => {
    this.setState({ dropdown2: !this.state.dropdown2 })
  }
  onClickDropdown3 = () => {
    this.setState({ dropdown3: !this.state.dropdown3 })
  }
  render() {
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: this.state.RecommendedProducts.length < 4 ? this.state.RecommendedProducts.length : 4,
      arrows: true,
      swipeToSlide: true,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,


      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            autoplaySpeed: 3000,
            arrows: false,


          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: true,
            autoplaySpeed: 3000,
            arrows: false,


          }
        }
      ]

    };

    var photoSlider2Settings = {
      vertical: true,
      verticalSwiping: true,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: (this.state.ProductPhotos.length > 4 ? 4 : this.state.ProductPhotos.length),
      swipeToSlide: true,
      focusOnSelect: true,
      className: "center",
      arrows: false,

      responsive: [
        {
          breakpoint: 576,
          settings: {
            vertical: false,
            verticalSwiping: false,
            slidesToShow: (this.state.ProductPhotos.length > 3 ? 3 : this.state.ProductPhotos.length),
            slidesToScroll: 1,
            infinite: false,
            dots: true,
            arrows: false
          }
        },
        {
          breakpoint: 438,
          settings: {
            vertical: false,
            verticalSwiping: false,
            slidesToShow: (this.state.ProductPhotos.length > 3 ? 3 : this.state.ProductPhotos.length),
            slidesToScroll: 1,
            infinite: false,
            dots: true,
            arrows: false
          }
        },

      ]

    }

    const ListItem = this.state.RecommendedProducts.map((pitem) => {
      return (
        <li key={pitem.ProductId} >
          <ProductCard item={pitem} _className={this.state.favoriteIds.indexOf(pitem.Id) >= 0 ? "product-card__like-button active" : "product-card__like-button"} toggleFavorite={() => { this.state.favoriteIds.indexOf(pitem.Id) >= 0 ? this.removeFavorites(pitem.Id) : this.AddFavorite(pitem.Id) }} history={this.props.history} ></ProductCard>
        </li>
      );
    });

    const customStyles = {

      option: (provided, state) => ({
        ...provided,
        fontSize: 12,
        color: 'black',
        backgroundColor: "transparent",

        '&:hover': {
          backgroundColor: "#F3F0E9"

        },
        '&:focus': {
          backgroundColor: "#F3F0E9"

        },
      }),

      control: (provided, state) => ({
        ...provided,
        marginTop: "5%",
        borderWidth: 0,
        borderBottomWidth: "1px",
        borderColor: '#EDE8DE',
        backgroundColor: "transparent",
        borderRadius: 0,
        outline: "transparent",
        fontSize: window.innerWidth < 576 ? "13px" : "inherit",
        boxShadow: state.isFocused ? '0 0 0 0.2rem transparent' : '',
        '&:focus': {
          borderColor: "#F3F0E9",
          '&:hover': {
            borderColor: "#F3F0E9"

          },
        },

      }),



      container: (provided) => ({
        ...provided,
        '&:focus': {
          borderColor: "#F3F0E9",

        },

      }),

      dropdownIndicator: (provided) => ({
        ...provided,
        color: '#B29F78',
        borderLeft: 0

      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        width: 0
      }),
      placeholder: (provided) => ({
        ...provided,
        "@media only screen and (max-width: 576px)": {
          ...provided["@media only screen and (max-width: 576px)"],
          fontSize: 13,
        },
      })

    }

    const options = [
      { value: 1, label: '1' },
      { value: 2, label: '2' },
      { value: 3, label: '3' },
      { value: 4, label: '4' },
      { value: 5, label: '5' },
      { value: 6, label: '6' },
      { value: 7, label: '7' },
      { value: 8, label: '8' },
      { value: 9, label: '9' },
      { value: 10, label: '10' },

    ]

    const sizeOptions = [];
    this.state.ProductVariations.map(item => {
      return sizeOptions.push({ value: item.VariationId, label: item.Stock <= 0 ? item.Name + " (Stok Tükendi)" : item.Name })
    });

    const options2 = {
      buttons: {
        showAutoplayButton: false,
        showNextButton: false,
        showPrevButton: false,
        showDownloadButton: false,
        showThumbnailsButton: false,
      },
      settings: {
        disableWheelControls: true,
      },
      thumbnails: {
        showThumbnails: false,

      }
    };

    const CatName = this.state.ProductCategoryList.map(item => {
      return LangId === 1 ? item.NameTr : item.NameEn
    })

    const _productId = parseInt(this.props.match.params.productId);
    return (
      <>
        {this.state.addFavoriteLoading == true &&
          <div className="favoriteoverlay">

          </div>
        }
        <BreadCrumb titleLink={"/searchproducts"} title={i18next.t('Shop')} brand={this.state.DesignerList.Name} brandLink={`/designers/${this.state.DesignerList.Url}`} path={this.state.ProductDetailData.Title} ></BreadCrumb>
        <div className="productDetail">
          <div className="container">
            <div className="row py-4">
              <div className="col-lg-6 ">
                <div className="row d-flex">
                  <div className="  col-sm-3 order-3 order-sm-1 mt-3 mt-sm-0 px-sm-0" >
                    <Slider {...photoSlider2Settings}
                      // vertical={true}
                      // verticalSwiping={true}
                      initialSlide={0}
                      asNavFor={this.state.nav1}
                      ref={slider => (this.slider2 = slider)}
                    >
                      {
                        this.state.ProductPhotos.map((photo) => {
                          return (<img key={1} className="ProductDetailImgSizeSm" src={photo.PhotoPath} />)
                        })
                      }
                    </Slider>
                  </div>
                  <div className="col-sm-9 order-1 order-sm-2 img-zoom-container">
                    <Slider
                      arrows={false}
                      initialSlide={0}
                      asNavFor={this.state.nav2}
                      ref={slider => (this.slider1 = slider)}
                      afterChange={() => this.setZoomElement()}
                      className={"mainslider"}
                    >
                      {this.state.ProductPhotos.map((photo) => {
                        return (
                          <SimpleReactLightbox>
                            <SRLWrapper options={options2}>
                              <img key={1} className={"ProductDetailImgSize product-" + photo.Id} src={photo.PhotoPath} onMouseEnter={() => this.setState({ showResultDiv: true })} onMouseLeave={() => this.setState({ showResultDiv: false })} />
                            </SRLWrapper>

                          </SimpleReactLightbox>
                        );
                      })
                      }
                    </Slider>

                  </div>
                </div>
                {/* </>} */}
              </div>
              <div className="col-lg-6">
                <div className={this.state.showResultDiv == false ? "d-none img-zoom-result" : "img-zoom-result"}></div>
                {
                  this.slider1 && this.setZoomElement()
                }
                {/* {
                  this.slider1 && this.slider1.slickGoTo(0)
                }
                {
                  this.slider2 && this.slider2.slickGoTo(0)
                } */}
                {this.state.loading === true ?
                  <div className="loadingImage"></div>
                  :
                  <div className="product-card__body">
                    <div className="divAbsoluteFav" onClick={() => { this.state.favoriteIds.indexOf(_productId) >= 0 ? this.removeFavorites(_productId) : this.AddFavorite(_productId) }}>
                      {
                        this.state.favoriteIds.indexOf(_productId) >= 0 ?
                          <svg width="40" height="40" viewBox="0 0 40 40" fill="#222222" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="20" cy="20" r="19.5" fill="white" stroke="#222222" />
                            <path d="M19.497 13.815L20 14.3346L20.503 13.815C22.3725 11.8837 25.394 11.8837 27.2635 13.815C29.1455 15.7592 29.1455 18.9205 27.2635 20.8647L20.2156 28.1457C20.0977 28.2675 19.9023 28.2675 19.7844 28.1457L12.7365 20.8647C10.8545 18.9205 10.8545 15.7592 12.7365 13.815C14.606 11.8837 17.6275 11.8837 19.497 13.815Z" stroke="#222222" stroke-width="1.4" />
                          </svg>
                          :
                          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="20" cy="20" r="19.5" fill="white" stroke="#222222" />
                            <path d="M19.497 13.815L20 14.3346L20.503 13.815C22.3725 11.8837 25.394 11.8837 27.2635 13.815C29.1455 15.7592 29.1455 18.9205 27.2635 20.8647L20.2156 28.1457C20.0977 28.2675 19.9023 28.2675 19.7844 28.1457L12.7365 20.8647C10.8545 18.9205 10.8545 15.7592 12.7365 13.815C14.606 11.8837 17.6275 11.8837 19.497 13.815Z" stroke="#222222" stroke-width="1.4" />
                          </svg>
                      }
                    </div>
                    <p className="product-card__title_detail">{this.state.ProductDetailData.Title}</p>
                    {/* <p><a href={`/designers/${this.state.DesignerList.Url}`} className="product-card__brand product-card__brand--hr">{this.state.DesignerList.Name}</a></p> */}

                    {this.state.ProductDetailData.DiscountedPrice > 0 || this.state.SelectedVariationDiscountedPrice > 0 ?
                      <>
                        {this.state.selectedSizeId > 0 || this.state.SelectedVariationDiscountedPrice > 0 ?
                          <span className="product-card__oldPrice"> <del>{parseFloat(this.state.SelectedVariationPrice).toFixed(2)} TL</del></span>
                          :
                          <span className="product-card__oldPrice"> <del>{parseFloat(this.state.ProductDetailData.Price).toFixed(2)} TL</del></span>
                        }
                      </>
                      :
                      <>
                      </>
                    }
                    <span className="product-card__newPrice--font">
                      {this.state.selectedSizeId > 0 ?
                        <>
                          {
                            this.state.SelectedVariationDiscountedPrice > 0 ?
                              <span className="product-card__newPrice">{parseFloat(this.state.SelectedVariationDiscountedPrice).toFixed(2)} TL</span>
                              :
                              <span className="product-card__newPrice">{parseFloat(this.state.SelectedVariationPrice).toFixed(2)} TL</span>
                          }
                        </>
                        :
                        <>
                          {
                            this.state.ProductDetailData.DiscountedPrice > 0 ?
                              <span className="product-card__newPrice">{parseFloat(this.state.ProductDetailData.DiscountedPrice).toFixed(2)} TL</span>
                              :
                              <span className="product-card__newPrice">{parseFloat(this.state.ProductDetailData.Price).toFixed(2)} TL</span>
                          }
                        </>
                      }
                    </span>

                    {this.state.ProductDetailData.DiscountPercentage > 0 ?
                      <span className="product-card__discountAmount product-card__discountAmount--ps">
                        -% {this.state.ProductDetailData.DiscountPercentage} indirim
                      </span>
                      : <></>}
                    <div className="divProductCardLine"></div>
                    {
                      this.state.ProductDetailData.IsMainVariation === true || this.state.ProductVariations.length <= 0 ?
                        <></>
                        :
                        // <div className="divVariationContainer">
                        //   <span className="spanVariationName">{this.state.ProductDetailData.VariationName}:</span>
                        //   {
                        //     this.state.ProductVariations.map(function(item){
                        //         return(<span className="spanVariationValue">{item.Name}</span>)
                        //     })
                        //   }
                        // </div>
                        <div className="row" style={{marginTop:12}}>
                          <div className="col-lg-6 ">
                            <Select placeholder={"Beden Seçiniz"} className="divVaryasyonSelect" styles={customStyles} options={sizeOptions}
                              onChange={this.onChangeSelectSize}>
                            </Select>
                          </div>
                        </div>

                    }
                    {/* <p className="product-card__description p-size-1" dangerouslySetInnerHTML={{ __html: this.state.ProductDetailData.ShortDescription }}></p> */}
                    <div className="row mb-4">
                      {/* <div className="col-6">
                        <Select
                          placeholder={i18next.t("Count")}
                          styles={customStyles}
                          options={options}
                          onChange={this.handleChangeCount}
                        >
                        </Select>
                      </div> */}
                      <div className="col-lg-6 col-md-12">
                        <div className="divSelectCount">
                          <div className="divMinusCount" onClick={this.handleDecreaseCount}>
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2.5 8.5H13.5" stroke="#162126" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </div>
                          <div className="divTheCount">{this.state.SelectedCountNumber}</div>
                          <div className="divPlusCount" onClick={this.handleIncreaseCount}>
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2.5 8.5H13.5" stroke="#162126" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M8 3V14" stroke="#162126" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </div>

                        </div>
                      </div>
                    </div>

                    {(this.state.ProductDetailData.Stock <= 0 && this.state.ProductDetailData.Variations.length <= 0) || this.state.totalStock <= 0 ?
                      <></>
                      :
                      <>
                        <div className="row">
                          <div className="col-lg-6 col-md-12">
                            <div className="divAddToCart">
                              <a onClick={this.AddToCart} className={this.state.addCartLoading === true ? "submit-button2  loading" : "submit-button2"}>
                                <svg className="iconSize-xs" >
                                  <use href={Sprite + "#menu-shopping-black"} ></use>
                                </svg > &nbsp;
                          {this.state.addCartLoading === true ? "SEPETE EKLE" : i18next.t('AddtoCart')}
                              </a>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="divAddToCart">
                              <a onClick={this.AddToCart2} className={this.state.addCartLoading === true ? "submit-button  loading" : "submit-button"}>
                                HEMEN SATIN AL
                        </a>
                            </div>
                          </div>
                        </div>
                      </>
                    }

                    {/* {this.state.ProductDetailData.IsMainVariation === true || this.state.ProductVariations.length <= 0 ?
                        <></>
                        :
                        <div className="col-6">
                          <Select placeholder={"Varyasyon Seçiniz"} styles={customStyles} options={sizeOptions}
                            onChange={this.onChangeSelectSize}>
                          </Select>
                        </div>

                      } */}

                    {(this.state.ProductDetailData.Stock <= 0 && this.state.ProductDetailData.Variations.length <= 0) || this.state.totalStock <= 0 ?

                      <p className="submit-button2 ">{i18next.t('NOSTOCK')}</p>
                      :
                      <div className="row">
                        <div className="col-lg-5  pr-lg-0">
                          {/* <a onClick={this.AddToCart} className={this.state.addCartLoading === true ? "product-card__addBasket  loading" : "product-card__addBasket mb-2 mb-lg-0"}>
                            <svg className="iconSize-xxs" >
                              <use href={Sprite + "#menu-shopping"} ></use>
                            </svg > &nbsp;
              {this.state.addCartLoading === true ? "SEPETE EKLE" : i18next.t('AddtoCart')}
                          </a> */}
                        </div>
                        {/* <div className="col-lg-5  ">
                <a href="#" className="product-card__buyNow d-block ">
                  {i18next.t('BuyNow')}
                </a>
              </div> */}
                        {/* <span onClick={() => { this.state.favoriteIds.indexOf(_productId) >= 0 ? this.removeFavorites(_productId) : this.AddFavorite(_productId) }} className={this.state.favoriteIds.indexOf(_productId) >= 0 ? "product-card__addFavorite  col-lg-2  d-lg-block d-none ml-3 ProductDetail-favoriteActive" : "product-card__addFavorite  col-lg-2  d-lg-block d-none ml-3"} >

                          <svg className="iconSize-xxs">
                            <use href={Sprite + "#like-button"} ></use>
                          </svg >

                        </span> */}
                      </div>
                    }

                  </div>
                }
                <div className="ProductDetail__TechnicalInfo">
                  <div className="divDropContainer" onClick={this.onClickDropdown1}> Ürün Bilgisi
                  {this.state.dropdown1 == true ?
                      <span className="spanDropIcon">
                        <svg className="iconSize-xs" >
                          <use href={Sprite + "#minus"} ></use>
                        </svg >
                      </span>
                      :
                      <span className="spanDropIcon">
                        <svg className="iconSize-xs" >
                          <use href={Sprite + "#add-sharp"} ></use>
                        </svg >
                      </span>
                    }
                  </div>
                  {
                    this.state.dropdown1 == true ?
                      <p className="divProductDetailDescContainer" dangerouslySetInnerHTML={{ __html: this.state.ProductDetailData.ShortDescription }}>
                      </p>
                      :
                      <></>
                  }
                  {/* <p className="p-size-1" dangerouslySetInnerHTML={{ __html: this.state.ProductDetailData.TechnicalInfo }}></p> */}
                </div>
              </div>
            </div>
            {/* <div className="container  product-card--pd">
    <div className="product-card">
      <h3 className="text-center my-4"> {i18next.t('SimiliarProducts')}</h3>
      <Slider {...settings}>
        {ListItem}
      </Slider>
    </div>
  </div> */}
            <div className="container  product-card--pd">
                <div className="divCategoryName mb-5 mt-3">
                  Benzer Ürünler
                    <div className="divHomeBlackLine"></div>
                </div>
                <Slider {...settings}>
                  {ListItem}
                </Slider>
            </div>
          </div>
          <ToastContainer />
          {/* Error Modal  */}
          <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showErrorModal} onHide={() => { this.setState({ showErrorModal: false }) }}>
            <Modal.Header closeButton>
            </Modal.Header>
            <h4 className="LoginModal__title text-center">BAŞARISIZ</h4>
            <p></p>
            <Modal.Body>
              <div className="text-center mb-3">
                <svg className="iconSize-md" >
                  <use href={Sprite + "#sad-face"} ></use>
                </svg >
              </div>
              <form className="LoginModal  ">
                <p className="text-center">{this.state.errorMessage}</p>
                <div className="text-center  w-50  mx-auto">
                  <a onClick={() => { this.setState({ showErrorModal: false }) }} className="submit-button">{i18next.t('OK')}</a>
                </div>
              </form>
            </Modal.Body>
            {/* <Modal.Footer>
                <div className="KeepInTouch__Img ">
                  <img src={KeepInTouchImg} className="img-fluid"></img>
                </div>
              </Modal.Footer> */}
          </Modal>
        </div>
      </>
    );
  }
})
export default inject((stores) => ({
  Store: stores.Store,
}))(ProductDetail);