/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Sprite from '../Assets/Icons/sprite.svg'
import i18next from 'i18next';
export default class SozlesmeCard extends Component {
  render() {
    const { className1, className2, className3, className4, className5, className6, className7 } = this.props;
    return (
      <div className="ProfilDetailCard SozlesmeCard">
        <div className="ProfilPopever__body">
          <p className={className4}>
            <a href="/termsconditions" className="profildetail__title">Beden Rehberi</a>
          </p>
          <p className={`${className3} p-0`}>
            <p className="ProfilPopever__like-button">
              <a href="/privacy-policy" className="profildetail__title"> {i18next.t("FtPrivacyPolicy")}</a>
            </p>
          </p>
          <p className={className1}>
            <a href="/shipping-returns" className="profildetail__title "> İptal &amp; İade Koşulları </a>
          </p>
          <p className={className2}>
            <a href="/membership" className="profildetail__title"> Kullanıcı Sözleşmesi</a>
          </p>



          {/* <p className={className5}>
            <a href="/faq" className="profildetail__title">FAQ</a>
          </p> */}



        </div>


      </div>
    )
  }
}
