import React, { Component } from 'react'
import BreadCrumb from '../Components/BreadCrumb';
import SozlesmeCard from '../Components/SozlesmeCard';
import SozlesmeCardMobil from '../Components/SozlesmeCardMobil';
import i18next from 'i18next';

export default class TermsConditions extends Component {
    render() {
        return (
            <>
                <BreadCrumb path={"BEDEN REHBERİ"}></BreadCrumb>
                <div className="sozlesme body--margin">
                    <div className="container-fluid">
                        <div className="container">
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3  d-none d-lg-block divLeftCol">
                                <SozlesmeCard className4={"bg--secondary"} />
                            </div>
                            <div className="col-lg-3  d-lg-none UserDetail__section1">
                                <SozlesmeCardMobil active4></SozlesmeCardMobil>
                            </div>
                            <div className="col-lg-9">
                                <div className="divCategoryName mt-3">
                                    BEDEN REHBERİ
                                    <div className="divHomeBlackLine"></div>
                                </div>
                                {/* <p className="p-size-1">
                                6502 sayılı Tüketicinin Korunması Hakkında Kanunun 48 inci ve 84 üncü maddelerine dayanılarak hazırlanan, 27.11.2014 tarih ve 29188 Sayılı Resmi Gazetede yayımlanarak yürürlüğe giren "Mesafeli Sözleşmeler Yönetmeliği" hükümleri gereğince internet üzerinden gerçekleştirilen mesafeli satışlar için hazırlanan Ön Bilgilendirme Formu ile Mesafeli Satış Sözleşmesi aşağıda yer almaktadır. İşbu Ön Bilgilendirme Formu elektronik ortamda müşteri tarafından okunarak kabul edildikten sonra Mesafeli Satış Sözleşmesi kurulması aşamasına geçilecektir.
                             <br></br>  <br></br>
                            İşbu Sözleşmenin konusu, Alıcı'nın <a href="/">www.shopwondrous.com </a>  internet sitesinden elektronik ortamda siparişini yaptığı ürünlerin satışı ve teslimi ile ilgili olarak 6052 Sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmeler Yönetmeliği hükümleri gereğince tarafların hak ve yükümlülüklerinin saptanmasıdır.
                            <p className="ft-link py-3"> Ödeme ve iadeye ilişkin hükümler </p>
                                <span className="ft-link">Kredi Kartı ile Ödeme:</span> Kredi kartı ile ödemede bulunabilmek için kredi kartı bilgilerini tam ve eksiksiz olarak ilgili kısımlardaki bölüme doldurmanız gerekmektedir. Kredi kartınızla tek çekimde ödemede bulunabileceğiniz gibi, farklı taksit seçeneklerine bölünmek suretiyle de ödemede bulunabilirsiniz. Bankanız kampanyalar düzenleyerek sizin seçtiğiniz taksit adedinin daha üstünde bir taksit adedi uygulayabilir, taksit öteleme gibi hizmetler sunulabilir. Bu tür kampanyalar bankanızın inisiyatifindedir ve şirketimizin bilgisi dâhilinde olması durumunda sayfalarımızda kampanyalar hakkında bilgi verilmektedir. Kredi kartınızın hesap kesim tarihinden itibaren sipariş toplamı taksit adedine bölünerek kredi kartı özetinize bankanız tarafından yansıtılacaktır. Banka taksit tutarlarını küsurat farklarını dikkate alarak aylara eşit olarak dağıtmayabilir. Detaylı ödeme planınızın oluşturulması bankanız inisiyatifindedir.
                            <br></br>
                            Diğer yandan vadeli satışların sadece Bankalara ait kredi kartları ile yapılması nedeniyle, Alıcı, ilgili faiz oranlarını ve temerrüt faizi ile ilgili bilgileri bankasından ayrıca teyit edeceğini, yürürlükte bulunan mevzuat hükümleri gereğince faiz ve temerrüt faizi ile ilgili hükümlerin Banka ve Alıcı arasındaki kredi kartı sözleşmesi kapsamında uygulanacağını kabul, beyan ve taahhüt eder.
                            </p>
                            <p className="p-size-1">
                                <span className="ft-link">Havale ile Ödeme:</span> Havale ile ödemede bulunmak isterseniz, şirketimizin ......., şube kodu ..... hesap numarası .................................... ve IBAN numarası TR............................................................... olan hesabına havale yapabilirsiniz.
                            <br></br>
                            Diğer yandan vadeli satışların sadece Bankalara ait kredi kartları ile yapılması nedeniyle, müşteri, ilgili faiz oranlarını ve temerrüt faizi ile ilgili bilgileri bankasından ayrıca teyit edeceğini, yürürlükte bulunan mevzuat hükümleri gereğince faiz ve temerrüt faizi ile ilgili hükümlerin Banka ve müşteri arasındaki kredi kartı sözleşmesi kapsamında uygulanacağını kabul, beyan ve taahhüt eder. </p>
                            <p className="p-size-1">
                                <span className="ft-link">Kredi Kartına İade Prosedürü:</span> Müşterinin cayma hakkını kullandığı durumlarda ya da siparişe konu olan ürünün çeşitli sebeplerle tedarik edilememesi veya Tüketici Sorunları Hakem Heyeti kararları ile tüketiciye bedel iadesine karar verilen durumlarda, alışveriş kredi kartı ile tek çekim veya taksitli olarak yapılmışsa, kredi kartına iade prosedürü aşağıda belirtilmiştir:
                            <br></br>
                            Müşterimiz ürünü kaç taksit ile aldıysa Banka müşteriye geri ödemesini taksitle yapmaktadır. (Firma Unvanı) bankaya ürün bedelinin tamamını tek seferde ödedikten sonra, banka poslarından yapılan taksitli harcamaların müşterimizin kredi kartına iadesi durumunda, konuya müdahil tarafların mağdur duruma düşmemesi için talep edilen iade tutarları, yine taksitli olarak hamil taraf hesaplarına banka tarafından aktarılır. Müşterinin satış iptaline kadar ödemiş olduğu taksit tutarları, eğer iade tarihi ile kartın hesap kesim tarihleri çakışmazsa her ay karta 1 iade yansıyacak ve müşteri iade öncesinde ödemiş olduğu taksitleri satışın taksitleri bittikten sonra, iade öncesinde ödemiş olduğu taksitleri sayısı kadar ay daha alacak ve mevcut borçlarından düşmüş olacaktır.
                            <br></br>
                            Müşteri, bu prosedürü okuduğunu ve kabul ettiğini kabul ve taahhüt eder. </p>
                            <p className="p-size-1">
                                <span className="ft-link">Havale ile Ödemede İade Prosedürü:</span>Havale ile ödeme halinde iade tüketiciden banka hesap bilgileri istenerek, tüketicinin belirttiği hesaba (hesabın fatura adresindeki kişinin adına veya kullanıcı üyenin adına olması şarttır) havale şeklinde yapılacaktır. </p>

                            <p className="p-size-1">
                                <span className="ft-link">Teslimat:</span> Teslimat, anlaşmalı kargo şirketlerinden biri aracılığı ile müşterinin sipariş formunda belirttiği adresinde elden teslim edilecektir. Teslim anında müşterinin adresinde bulunmaması durumunda dahi şirket edimini tam ve eksiksiz olarak yerine getirmiş olarak kabul edilecektir. Bu nedenle, müşterinin ürünü geç teslim almasından kaynaklanan her türlü zarar ile ürünün kargo şirketinde beklemiş olması ve/veya kargonun şirkete geri iade edilmesinden dolayı giderler müşteriye aittir. Kargo bedeli ürün bedeline dahil değildir. Kargo bedeli sipariş tutarına eklenmekte ve tüketici tarafından ödenmektedir. Kargo bedeline ilişkin olarak yapılan kampanyalar şirketimiz inisiyatifindedir.  </p>

                            <p className="p-size-1">
                                <span className="ft-link">Genel Hükümler :</span>Müşteri, <a href="/">www.shopwondrous.com  </a>internet sitesinde sözleşme konusu ürün olan ürünlerin temel nitelikleri, satış fiyatı ve ödeme şekli ile teslimata ilişkin ön bilgileri okuyup bilgi sahibi olduğunu ve elektronik ortamda gerekli teyidi verdiğini beyan eder.
                            <br></br>
                            Sözleşme konusu ürün, on dört günlük süreyi aşmamak koşulu ile her bir ürün için müşterinin yerleşim yerinin uzaklığına bağlı olarak müşteri veya gösterdiği adresteki kişi/kuruluşa teslim edilir.
                            <br></br>

                             Sözleşme konusu ürün, müşteriden başka bir kişi/kuruluşa teslim edilecek ise, teslim edilecek kişi/kuruluşun teslimatı kabul    etmemesinden şirket  sorumlu tutulamaz.
                              <br></br>

                           Şirket, sözleşme konusu ürünün sağlam, eksiksiz, siparişte belirtilen niteliklere uygun ve varsa garanti belgeleri ve kullanım      kılavuzları ile teslim edilmesinden sorumludur.
                                  <br></br>

                              Sözleşme konusu ürünün teslimatı için bedelinin müşterinin tercih ettiği ödeme şekli ile ödenmiş olması şarttır. Herhangi bir nedenle ürün bedeli ödenmez veya banka kayıtlarında iptal edilir ise, şirket ürünün teslimi yükümlülüğünden kurtulmuş kabul edilir.  </p>

                            <p className="p-size-1">
                                <span className="ft-link">Cayma Hakkı :</span>Müşteri, sözleşme konusu ürünün kendisine veya gösterdiği adresteki kişi/kişilere tesliminden itibaren yedi gün içinde giyilmemiş, yıkanmamış ve herhangi bir işlem görmemiş olması halinde cayma hakkına sahiptir. Şirketin Mesafeli Sözleşmeler Yönetmeliğinin on ikinci maddesinde belirtilen yükümlülüklerini yerine getirmemesi halinde cayma hakkı süresi on dört gündür. Cayma hakkının kullanıldığına dair bildirimin bu süre içinde yazılı olarak  bildirmesi gereklidir.
                                                                <br></br>
                                Tüketicinin cayma bildiriminin şirkete yazılı olarak ulaştığı tarihten itibaren 20 gün içinde ürün bedeli müşteriye iade edilir.
                                <br></br>
                                385 Sayılı Vergi Usul Kanunu Genel Tebliği uyarınca iade işlemlerinin yapılabilmesi için tarafınıza göndermiş olduğumuz iade bölümü bulunan faturada ilgili bölümlerin eksiksiz olarak doldurulması ve imzalandıktan sonra tarafımıza ürün ile birlikte geri gönderilmesi gerekmektedir. Ancak, KKTC ve yurtdışı gönderilerinde müşterilerimiz siparişlerinde KDV ödemediği için, gümrükte almış olduğu ürünlerin vergilerini ödemekle yükümlüdür. Gümrükten her ne şekilde olursa olsun teslim alınmayan ürünler için müşterilerin cayma hakkı bulunmamaktadır.
                                <br></br>
                                Taraflarca aksi kararlaştırılmadıkça tüketici, aşağıdaki sözleşmelerde cayma hakkını kullanamaz.
                                <br></br>
                                a) Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile hizmetin ifasına başlanan hizmet sözleşmeleri,
                                <br></br>
                                b) Fiyatı borsa veya teşkilatlanmış diğer piyasalarda belirlenen mallara ilişkin sözleşmeler,
                                <br></br>
                                c) Tüketicinin istekleri veya açıkça onun kişisel ihtiyaçları doğrultusunda hazırlanan, niteliği itibariyle geri gönderilmeye elverişli olmayan ve çabuk bozulma tehlikesi olan veya son kullanma tarihi geçme ihtimali olan malların teslimine ilişkin sözleşmeler,
                                <br></br>
                                ç) Tüketici tarafından ambalajının açılmış olması şartıyla, ses veya görüntü kayıtlarına, yazılım programlarına ve bilgisayar sarf malzemelerine ilişkin sözleşmeler,
                                <br></br>
                                d) Gazete, dergi gibi süreli yayınların teslimine ilişkin sözleşmeler,
                                <br></br>
                                e) Bahis ve piyangoya ilişkin hizmetlerin ifasına ilişkin sözleşmeler,
                                <br></br>
                                f) Elektronik ortamda anında ifa edilen hizmetler ve tüketiciye anında teslim edilen gayri maddi mallara ilişkin sözleşmeler.
                                <br></br>
                                Cayma hakkının kullanılması durumunda ürün ve paket kontrolü  şirket tarafından yapılacaktır. Cayma hakkı nedeni ile iade edilen ürünün kargo bedeli müşteri tarafından karşılanır.
                                <br></br>
                                Şirket, müşterinin ürünü kullanıp geri gönderme ve sair durumları tespit ettiği hallerde ve ürünün müşteriye teslim edildiği andaki hali ile iade edilememesi halinde ürünü iade almayacak ve bedelini iade etmeyecektir.  </p>

                            <p className="p-size-1">
                                <span className="ft-link">Temerrüt Hükümleri :</span> Tarafların işbu sözleşmeden kaynaklanan edimlerini yerine getirmemesi durumunda Borçlar Kanunu'nun 106-108. maddesinde yer alan Borçlunun Temerrüdü hükümleri uygulanacaktır. Temerrüt durumlarında, herhangi bir tarafın edimlerini süresi içinde haklı bir sebebi olmaksızın yerine getirmemesi durumunda diğer taraf söz konusu edimin yerine getirilmesi için edimini yerine getirmeyen tarafa yedi günlük süre verecektir. Bu süre zarfında da yerine getirilmemesi durumunda edimini yerine getirmeyen taraf mütemerrit olarak addolunacak ve alacaklı edimin ifasını talep etmek suretiyle malın teslimini ve/veya sözleşmenin feshini ve bedelin iadesini talep etme hakkına sahip olacaktır. </p>

                            <p className="p-size-1">
                                <span className="ft-link">Yetkili Mahkeme  :</span> Tİşbu sözleşmenin uygulanmasında, Gümrük ve Ticaret Bakanlığınca ilan edilen değere kadar Tüketici Sorunları Hakem Heyetleri İzmir ili Tüketici Mahkemeleri yetkilidir.
                                <br></br>

                                    Siparişin gerçekleşmesi durumunda müşteri işbu sözleşmenin tüm koşullarını kabul etmiş sayılır.

                                    </p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
