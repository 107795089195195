import React, { Component } from 'react'
import BreadCrumb from '../Components/BreadCrumb';
import SozlesmeCard from '../Components/SozlesmeCard';
import SozlesmeCardMobil from '../Components/SozlesmeCardMobil';
import i18next from 'i18next';

export default class Membership extends Component {
    render() {
        return (
            <>
                <BreadCrumb path={i18next.t("FtMembership")}></BreadCrumb>
                <div className="sozlesme body--margin">
                    <div className="container-fluid">
                        <div className="container">
                        </div>
                    </div>
                    <div className="container ">
                        <div className="row">
                            <div className="col-lg-3  d-none d-lg-block divLeftCol">
                                <SozlesmeCard className2={"bg--secondary"} />
                            </div>
                            <div className="col-lg-3  d-lg-none UserDetail__section1">
                                <SozlesmeCardMobil active2></SozlesmeCardMobil>
                            </div>
                            <div className="col-lg-9">
                                <div className="divCategoryName mt-3">
                                    KULLANICI SÖZLEŞMESİ
                                    <div className="divHomeBlackLine"></div>
                                </div>
                                {/* <p className="p-size-1">
                                <ul className="list-unstyled">
                                    <li className="ft-main-link">1. Taraflar
                                         <li className="ft-sub-link">a) <a href="/" className="ft-sub-link2"> www.shopwondrous.com </a>  internet sitesinin Kültür Mh. Plevne Blv. Sema Apt. No:1/2A Konak, İZMİR adresinde faaliyet gösteren HALUK TEZCAN YAPI MİMARLIK TURİZM SANAYİ VE TİCARET A.Ş.  (Bundan böyle “Wondrous” olarak anılacaktır).</li>
                                        <li className="ft-sub-link">b) <a href="/" className="ft-sub-link2">  www.shopwondrous.com  </a> internet sitesine üye olan internet kullanıcısı ("Üye")</li>
                                    </li>
                                    <li className="ft-main-link">2. Sözleşmenin Konusu </li>
                                    <li  className="ft-sub-link">İşbu Sözleşme’nin konusu Wondrous’n sahip olduğu internet sitesi  www.shopwondrous.com’dan üyenin faydalanma şartlarının belirlenmesidir. </li>
                                    <li className="ft-main-link">3. Tarafların Hak ve Yükümlülükleri  </li>
                                    <li  className="ft-sub-link"> 3.1.  Üye,  www.shopwondrous.com internet sitesine üye olurken verdiği kişisel ve diğer sair bilgilerin kanunlar önünde doğru olduğunu, Wondrous’n bu bilgilerin gerçeğe aykırılığı nedeniyle uğrayacağı tüm zararları aynen ve derhal tazmin edeceğini beyan ve taahhüt eder.
                                    <br></br> <br></br>
                                    3.2.  Üye, Wondrous tarafından kendisine verilmiş olan şifreyi başka kişi ya da kuruluşlara veremez, üyenin sözkonusu şifreyi kullanma hakkı bizzat kendisine aittir. Bu sebeple doğabilecek tüm sorumluluk ile üçüncü kişiler veya yetkili merciler tarafından Wondrous’a karşı ileri sürülebilecek tüm iddia ve taleplere karşı, Wondrous’n söz konusu izinsiz kullanımdan kaynaklanan her türlü tazminat ve sair talep hakkı saklıdır.
                                    <br></br> <br></br>
                                    3.3.  Üye  www.shopwondrous.com  internet sitesini kullanırken yasal mevzuat hükümlerine riayet etmeyi ve bunları ihlal etmemeyi baştan kabul ve taahhüt eder. Aksi takdirde, doğacak tüm hukuki ve cezai yükümlülükler tamamen ve münhasıran üyeyi bağlayacaktır.
                                    <br></br> <br></br>
                                    3.4.  Üye,  www.shopwondrous.com internet sitesini hiçbir şekilde kamu düzenini bozucu, genel ahlaka aykırı, başkalarını rahatsız ve taciz edici şekilde, yasalara aykırı bir amaç için, başkalarının fikri ve telif haklarına tecavüz edecek şekilde kullanamaz. Ayrıca, üye başkalarının hizmetleri kullanmasını önleyici veya zorlaştırıcı faaliyet (spam, virus, truva atı, vb.) ve işlemlerde bulunamaz.
                                    <br></br> <br></br>
                                    3.5.  Wondrous üye verilerinin yetkisiz kişilerce okunmasından ve üye yazılım ve verilerine gelebilecek zararlardan dolayı sorumlu olmayacaktır. Üye, www.shopwondrous.com internet sitesinin kullanılmasından dolayı uğrayabileceği herhangi bir zarar yüzünden Wondrous'dan tazminat talep etmemeyi peşinen kabul etmiştir.
                                    <br></br> <br></br>
                                    3.6.  Üye, diğer www.shopwondrous.com kullanıcılarının verilerine izinsiz olarak ulaşmamayı veya bunları kullanmamayı kabul etmiştir. Aksi takdirde, bundan doğacak hukuki ve cezai sorumluluklar tamamen üyeye aittir.
                                    <br></br> <br></br>
                                    3.7.  İşbu üyelik sözleşmesi içerisinde sayılan maddelerden bir ya da birkaçını ihlal eden üye işbu ihlal nedeniyle cezai ve hukuki olarak şahsen sorumlu olup, Wondrous’ı bu ihlallerin hukuki ve cezai sonuçlarından ari tutacaktır. Ayrıca; işbu ihlal nedeniyle, olayın hukuk alanına intikal ettirilmesi halinde, Wondrous’ın üyeye karşı üyelik sözleşmesine uyulmamasından dolayı tazminat talebinde bulunma hakkı saklıdır.
                                    <br></br> <br></br>
                                    3.8.  Wondrous’n usulsüz kullanım halinde her zaman tek taraflı olarak üyenin üyeliğini silme, müşteriye ait belge ve bilgileri silme hakkı vardır. Üye işbu tasarrufu önceden kabul eder. Bu durumda, Wondrous’n hiçbir sorumluluğu yoktur.
                                    <br></br> <br></br>
                                    3.9.  www.shopwondrous.com  internet sitesi yazılım ve tasarımı Wondrous kullanımında olup, bunlara ilişkin telif hakkı ve/veya diğer fikri mülkiyet hakları ilgili kanunlarca korunmakta olup, bunlar  üye tarafından izinsiz kullanılamaz, iktisap edilemez ve değiştirilemez. Bu web sitesinde adı geçen başkaca şirketler ve ürünleri sahiplerinin ticari markalarıdır ve ayrıca fikri mülkiyet hakları kapsamında korunmaktadır.
                                    <br></br> <br></br>
                                    3.10.  Wondrous tarafından  www.shopwondrous.com   internet sitesinin iyileştirilmesi, geliştirilmesine yönelik olarak ve/veya yasal mevzuat çerçevesinde siteye erişmek için kullanılan İnternet servis sağlayıcısının adı ve Internet Protokol (IP) adresi, Siteye erişilen tarih ve saat, sitede bulunulan sırada erişilen sayfalar ve siteye doğrudan bağlanılmasını sağlayan Web sitesinin Internet adresi gibi birtakım bilgiler toplanabilir.
                                    <br></br> <br></br>
                                    3.11. Wondrous kullanıcılarına daha iyi hizmet sunmak, ürünlerini ve hizmetlerini iyileştirmek, sitenin kullanımını kolaylaştırmak için kullanımını kullanıcılarının özel tercihlerine ve ilgi alanlarına yönelik çalışmalarda üyelerin kişisel bilgilerini kullanabilir. Wondorus, üyenin  www.shopwondrous.com internet sitesi üzerinde yaptığı hareketlerin kaydını bulundurma hakkını saklı tutar.
                                    <br></br> <br></br>
                                    3.12.  Wondorus’a üye olan kişi, yürürlükte bulunan ve/veya yürürlüğe alınacak uygulamalar kapsamında Wondorus tarafından  kendisine ürün ve hizmet tanıtımları, reklamlar, kampanyalar, avantajlar, anketler  ve diğer müşteri memnuniyeti uygulamaları sunulmasına izin verdiğini beyan ve kabul eder.  Üye, Wondorus’a üye olurken ve/veya başka yollarla geçmişte vermiş olduğu ve/veya gelecekte vereceği kişisel ve alışveriş bilgilerinin ve alışveriş ve/veya tüketici davranış bilgilerinin  yukarıdaki amaçlarla toplanmasına, kullanılmasına ve arşivlenmesine izin verdiğini beyan ve kabul eder. Üye aksini bildirmediği sürece  üyeliği sona erdiğinde de  verilerin toplanmasına, kullanılmasına ve arşivlenmesine izin verdiğini beyan ve kabul eder. Üye aksini bildirmediği  sürece  kendisi ile  internet, telefon, SMS,  vb iletişim kanalları kullanarak irtibata geçilmesine izin verdiğini beyan ve kabul eder. Üye yukarıda bahsi geçen bilgilerin toplanması,  paylaşılması, kullanılması, arşivlenmesi ve kendisine erişilmesi nedeniyle doğrudan ve/veya dolaylı maddi ve/veya manevi menfi ve/veya müspet, velhasıl herhangi bir zarara uğradığı konusunda talepte bulunmayacağını ve Wondorus’ ı sorumlu tutmayacağını beyan ve kabul eder. Üye veri paylaşım tercihlerini değiştirmek isterse, bu talebini Wondorus’a  iletebilir.
                                    <br></br> <br></br>
                                    3.13. Wondorus, üyenin kişisel bilgilerini yasal bir zorunluluk olarak istendiğinde veya (a) yasal gereklere uygun hareket etmek veya Wondorus’a tebliğ edilen yasal işlemlere uymak; (b) Wondorus’n haklarını ve mülkiyetini korumak ve savunmak için gerekli olduğuna iyi niyetle kanaat getirdiği hallerde açıklayabilir.
                                    <br></br> <br></br>
                                    3.14.  Wondorus web sitesinin virüs ve benzeri amaçlı yazılımlardan arındırılmış olması için mevcut imkanlar dahilinde tedbir alınmıştır. Bunun yanında nihai güvenliğin sağlanması için kullanıcının, kendi virus koruma sistemini tedarik etmesi ve gerekli korunmayı sağlaması gerekmektedir. Bu bağlamda üye Wondorus web sitesi'ne girmesiyle, kendi yazılım ve işletim sistemlerinde oluşabilecek tüm hata ve bunların doğrudan ya da dolaylı sonuçlarından kendisinin sorumlu olduğunu kabul etmiş sayılır.
                                    <br></br> <br></br>
                                    3.15. Wondorus, sitenin içeriğini dilediği zaman değiştirme, kullanıcılara sağlanan herhangi bir hizmeti değiştirme ya da sona erdirme veya Wondorus web sitesi'nde kayıtlı kullanıcı bilgi ve verilerini silme hakkını saklı tutar.
                                    <br></br> <br></br>
                                    3.16.  Wondorus, üyelik sözleşmesinin koşullarını üyelerine e-mail, sms vb yollarla bildirimde bulunmak koşuluyla her zaman değiştirebilir, güncelleyebilir veya iptal edebilir. Değiştirilen, güncellenen ya da yürürlükten kaldırılan her hüküm, yayın tarihinde tüm üyeler bakımından hüküm ifade edecektir.
                                    <br></br> <br></br>
                                    3.17.  Taraflar, Wondorus’a ait tüm bilgisayar kayıtlarının tek ve gerçek münhasır delil olarak, HMK madde 193'e uygun şekilde esas alınacağını ve söz konusu kayıtların bir delil sözleşmesi teşkil ettiği hususunu kabul ve beyan eder. </li>
                                    <li className="ft-main-link">4. Sözleşmenin Feshi
                                    <li className="ft-sub-link">İşbu sözleşme üyenin üyeliğini iptal etmesine kadar yürürlükte kalacaktır. Wondrous üyenin üyelik sözleşmesinin herhangi bir hükmünü ihlal etmesi durumunda üyenin üyeliğini iptal ederek sözleşmeyi tek taraflı olarak feshedebilecektir. </li>
                                    </li>

                                    <li className="ft-main-link">5. İhtilafların Halli
                                          <li className="ft-sub-link">İşbu sözleşmeye ilişkin ihtilaflarda İzmir Mahkemeleri ve İcra Daireleri yetkilidir. </li>

                                    </li>
                                    <li className="ft-main-link">6. Yürürlük
                                            <li className="ft-sub-link">
                                            Üyenin, üyelik kaydı yapması üyenin üyelik sözleşmesinde yer alan tüm maddeleri okuduğu ve üyelik sözleşmesinde yer alan maddeleri kabul ettiği anlamına gelir. İşbu Sözleşme üyenin üye olması anında akdedilmiş ve karşılıklı olarak yürürlüğe girmiştir. </li>
                                    </li>

                                </ul>
                            </p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
