/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import creditCardLogo from '../Assets/Image/creditCardLogo.svg';
import Sprite from '../Assets/Icons/sprite.svg';


export default class CreditCards extends Component {
    render() {
        const { item } = this.props;
        return (
            <div className="row  CreditCards">
                <div className=" col-10 ">

                    <div className="creditCard__Img">
                        <img src={creditCardLogo} className="img-fluid"></img>
                    </div>
                    <p className="my-2 h-size-3 p-size--b">{item.creditCardNumber}</p>
                    <span className="p-size-3 mr-2">  {item.receiverName}</span>
                    <span className=" p-size-3 font--opacity"> {item.expirationDate} </span>
                </div>
                <div className="col-2  d-flex flex-column flex-lg-row  ">
                    <span className="mr-2 my-2">

                        <a href="#">
                            <svg className="iconSize-xxxs" >
                                <use href={Sprite + "#edit"} ></use>
                            </svg >
                        </a>

                    </span>
                    <span className=" my-2" >
                        <a href="#">
                            <svg className="iconSize-xxxs" >
                                <use href={Sprite + "#trash"} ></use>
                            </svg >
                        </a>

                    </span>
                </div>
            </div>





        )
    }
}
