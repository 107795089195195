/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
// import Logo from '../Assets/Image/footerlogo.png';
import Sprite from '../Assets/Icons/sprite.svg'
import i18next from 'i18next';
import ApiBase from '../lib/ApiBase';
import footerphone from '../Assets/Image/footer-phone.svg'; // with import
import footermail from '../Assets/Image/footer-mail.svg'; // with import
import footerpin from '../Assets/Image/footer-pin.svg'; // with import
import Modal from 'react-bootstrap/Modal';
import Logo from '../Assets/Image/all-shades-logo.svg';




export default class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      LayoutInfo: [],
      ContractList: [],
      showLoginModal: false,

    }
  }
  componentDidMount() {
    this.getLayoutInfo();
    this.getContracts();
  }

  getContracts = () => {
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-contracts',
      data: {
        LanguageId: LangId,
      },
      successFunction: (e) => {
        this.setState({
          ContractList: e.data.data.contractList,
        });
      },
      errorFunction: (e) => {
        console.log(e.data.message)

      }
    })
  }

  getLayoutInfo = () => {

    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-layout-info',
      data: {

      },
      successFunction: (e) => {

        this.setState({
          LayoutInfo: e.data.data
        });
      },
      errorFunction: (e) => {

        console.log("layouterror", e.data.message)
      }
    })
  };


  render() {
    let _isLogin = localStorage.getItem("isLogin");
    return (
      <>
        <div className="footer">
          <div className="container">
            <div className="divFooterLogoContainer">
              <img src={Logo} />
            </div>
            <div className="row divFooterTabContainer">
              <div className="col-lg-9 col-md-12 mx-auto text-center">
                {
                  this.state.ContractList.map((item) => (
                    <a href={`/sozlesmeler/${item.Id}`} className="divFooterTab">{item.Title}</a>
                  ))
                }
              </div>
            </div>
            <div className="footer__copyright">
              <div className="row pb-4">
                <div className="col-lg-4 text-lg-left text-center">
                  <p className="divFooterCopyright"> ©2021 - ALL SHADES |   {i18next.t("Copyright")} </p>
                </div>
                <div className="col-lg-4 text-center">
                  <div className="footer__socialMediaTags">
                    <a href={this.state.LayoutInfo.Facebook} target="_blank">
                      <svg className="iconSize-xs" >
                        <use href={Sprite + "#facebook-white"} ></use>
                      </svg >
                    </a>
                    <a href={this.state.LayoutInfo.Twitter} target="_blank">
                      <svg className="iconSize-xs" >
                        <use href={Sprite + "#twitter-white"} ></use>
                      </svg >
                    </a>
                    <a href={this.state.LayoutInfo.Instagram} target="_blank">
                      <svg className="iconSize-xs" >
                        <use href={Sprite + "#instagram-white"} ></use>
                      </svg >
                    </a>
                    <a href={this.state.LayoutInfo.Youtube} target="_blank">
                      <svg className="iconSize-xs" >
                        <use href={Sprite + "#youtube-white"} ></use>
                      </svg >
                    </a>
                    <a href={this.state.LayoutInfo.LinkedIn} target="_blank">
                      <svg className="iconSize-xs" >
                        <use href={Sprite + "#linkedin-white"} ></use>
                      </svg >
                    </a>

                  </div>
                </div>
                <div className="col-lg-4 text-center text-lg-right">
                  <div className="footer__prlogo">
                    <a href="https://www.pryazilim.com/">
                      <svg className="iconSize-xs" >
                        <use href={Sprite + "#pr-logo"} ></use>
                      </svg >
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showLoginModal} onHide={this.hideLoginModal}>
          <Modal.Header closeButton>
          </Modal.Header>
          <h4 className="LoginModal__title text-center">{i18next.t('MemberLogin')}</h4>
          <p></p>
          <Modal.Body>
            <div className="divFormSubTitle">Email</div>
            <input type="email" className="form-control divFromInput" placeholder={i18next.t('EmailAdress')} value={this.state.userName} onChange={(e) => this.setState({ userName: e.target.value })} />
            <div className="divFormSubTitle">Şifre</div>
            <input type="password" className="form-control divFromInput" placeholder={i18next.t('Password')} value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
            <div className="text-center  w-50  mx-auto">
              <a className="h-size-3 p-size--b" onClick={() => { this._loginFunction() }} className="submit-button">{i18next.t('SignIn')}</a>
            </div>
            <div className="LoginModal__links">
              <div className=" text-center">
                <a className="p-size-2 " onClick={this.onPressForgotPassword} >{i18next.t('ForgotPassword')}</a>
                <div className="LoginModal__hr  mx-auto w-50">
                  <p className="mx-auto"></p>
                </div>
                <p className="p-size-2 ">{i18next.t('DontYouHaveaMemberShip')} <a href="/uyeol"> <strong> {i18next.t('SignUp')}</strong></a></p>
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
          <div className="KeepInTouch__Img ">
            <img src={KeepInTouchImg} className="img-fluid"></img>
          </div>
        </Modal.Footer> */}
        </Modal>
      </>
    )
  }
}
