import React, { Component } from 'react';
import BreadCrumb from '../Components/BreadCrumb';
import KeepInTouch from '../Components/KeepInTouch';
import i18next from 'i18next';
import ApiBase from '../lib/ApiBase';
export default class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingBlogs: false,
      AboutDetail: [],

    };
  }

  componentDidMount() {
    this.getAbout();
  }

  getAbout = () => {
    this.setState({ loading: true })
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-about-detail',
      data: {
        LanguageId: LangId,
      },
      successFunction: (e) => {
        // console.log("name", e.data.data.aboutList)
        this.setState({
          AboutDetail: e.data.data.aboutData
        });
      },
      errorFunction: (e) => {
        this.setState({ loadingBlogs: false })
      }
    })
  }
  render() {
    return (
      <>
        <BreadCrumb path={i18next.t("About").toUpperCase()} />
        <div className="About">
          <div className="container">
            <div className="divCategoryName mb-5 mt-3">
              Hakkımızda
                    <div className="divHomeBlackLine"></div>
            </div>
            <div className="row mb-5">
              <div className="col-lg-12 mx-auto ">
                <p className="p-size-1 text-center text-lg-center" dangerouslySetInnerHTML={{ __html: this.state.AboutDetail.Description }}></p>
                <img src={this.state.AboutDetail.PhotoPath} className="img-fluid"></img>
                <div className=" mt-5 px-0 col-lg-6 mx-auto">
                  <a className=" submit-button2" href="/musteriHizmetleri">
                    BİZİMLE İLETİŞİME GEÇİN
                </a>
                </div>
              </div>
            </div>
          </div>
          <KeepInTouch />
        </div>
      </>
    )
  }
}
