/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import InspirationBanner from '../Components/InspirationBanner';
import ProductCard from '../Components/ProductCard';
import CategoryCard from '../Components/CategoryCard';
import Slider from "react-slick";
import BreadCrumb from '../Components/BreadCrumb';
import i18next from 'i18next';
import CategorySliderCard from '../Components/CategorySliderCard';
import ApiBase from '../lib/ApiBase';
import Modal from 'react-bootstrap/Modal';
import KeepInTouchImg from '../Assets/Image/KeepInTouch__Img.svg';
import Sprite from '../Assets/Icons/sprite.svg';
import { inject, observer } from 'mobx-react';
const Fashion = observer(class Fashion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      className: "product-card__like-button",
      name: 'burak',
      favoriteList: [],
      isFavorited: -1,
      BannerList: [],
      FashionList: [],
      RecommendedProducts: [],
      CategorySlider:[],
      favoriteIds:[]
    };
  }

  componentDidMount() {
    this.getFashions();
    this.getRecommendedProducts();
    this.getBanners();
    this.getCategorySlider();
    this.getFavoriteIds();
  }

  getFashions = () => {
    this.setState({ loading: true })
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-fashions',
      data: {
        LanguageId: LangId
      },
      successFunction: (e) => {
        console.log(e.data.data)
        this.setState({
          FashionList: e.data.data.fashionList
        });
      },
      errorFunction: (e) => {
        this.setState({ loadingBlogs: false })
        // console.log(e.data.message)
      }
    })
  }
  ////
  getRecommendedProducts = () => {
    this.setState({ loading: true })
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-featured-products',
      data: {
        LanguageId: LangId,
      },
      successFunction: (e) => {
         console.log("name", e.data.data.productList)
        this.setState({
          RecommendedProducts: e.data.data.productList,
        });
      },
      errorFunction: (e) => {
        this.setState({ loadingBlogs: false })
        // console.log(e.data.message)
      }
    })
  }

  ////
  toggleFavorite = (item) => {
    const i = this.state.favoriteList.indexOf(item.ProductId)

    if (i >= 0) {
      this.state.favoriteList.splice(i, 1)
      // console.log("i 0 dan büyük",this.state.favoriteList)
    } else {
      this.state.favoriteList.push(item.ProductId)
      // console.log("i 0 dan küçük", this.state.favoriteList)
    }
    this.setState({ favoriteList: this.state.favoriteList });
    // // console.log(this.state.favoriteList)
    // console.log("iiii", i)
  };

  getBanners = () => {
    this.setState({ loading: true })
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-banners',
      data: {
        LanguageId: LangId,
      },
      successFunction: (e) => {
        // console.log("banner", e.data.data)
        this.setState({
          BannerList: e.data.data.bannerList,
        });
      },
      errorFunction: (e) => {
        this.setState({ loadingBlogs: false })
        // console.log(e.data.message)
      }
    })
  }


  getCategorySlider = () => {
    this.setState({ loading: true })
    var Lang = localStorage.getItem("lang")
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-category-slider',
      data: {
        LanguageId: LangId,
        Take:10,
      },
      successFunction: (e) => {
         console.log("categorySlider", e.data.data)
        this.setState({
       CategorySlider:e.data.data.categories
        });
      },
      errorFunction: (e) => {
        this.setState({ loadingBlogs: false })
        // console.log(e.data.message)
      }
    })
  }
  AddFavorite = (ProductId) => {
    this.setState({ addFavoriteLoading: true })
    var _isLogin = localStorage.getItem("isLogin");
    var cUser = [];
    if (_isLogin === "true") {
      cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
    }
    ApiBase.Post({
      url: '/add-favourite',
      data: {
        ProductId: ProductId,
        CurrentUser: cUser
      },
      successFunction: (e) => {
        // console.log("favoriekle", e.data)
        this.getFavoriteIds();
        this.setState({addFavoriteLoading:false})
        // this.toggleFavorite(item)
      },
      errorFunction: (e) => {
        this.setState({ addFavoriteLoading:false,showErrorModal: true, errorMessage: e.data.message })
        // console.log(e.data.message)
        if (e.data.message === "Ürün, favori ürünleriniz arasında bulunmaktadır.") {
          // this.toggleFavorite(item)
        }
      }
    })
  }
  removeFavorites = (ProductId) => {
    this.setState({ addFavoriteLoading: true });
    var _isLogin = localStorage.getItem("isLogin");
    var cUser = [];
    if (_isLogin === "true") {
      cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
    }


    ApiBase.Post({
      url: '/delete-favourite',
      data: {
        ProductId: ProductId,
        CurrenUser: cUser
      },
      successFunction: (e) => {
        // console.log("remove", e.data)

        this.getFavoriteIds();
        this.setState({addFavoriteLoading:false})
        // this.setState({ loading: false })
      },
      errorFunction: (e) => {
        // console.log(e.data.message)
        this.setState({addFavoriteLoading:false, actLoading: false, showErrorModal: true, errorMessage: e.data.message });

      }
    })
  }
  getFavoriteIds = () => {
    var _isLogin = localStorage.getItem("isLogin");
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    var favIdList = [];
    ApiBase.Post({
      url: '/get-favorite-ids',
      data: {

        UserId: _UserId,

      },
      successFunction: (e) => {
        // console.log("favoriteıds", e.data.data.List)
        e.data.data.List.map(item =>
          favIdList.push(item.ProductId));
        this.setState({ favoriteIds: favIdList })
        //this.setState({ CartList: e.data.data.List, CartTotalPriceStr: e.data.data.TotalPriceStr })
      },
      errorFunction: (e) => {

        // console.log("hata", e.data.message)
      }
    })
  }
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: this.state.RecommendedProducts.length < 5 ? this.state.RecommendedProducts.length : 5,
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            arrows: false,
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            autoplaySpeed: 3000,

          }
        },
        {
          breakpoint: 576,
          settings: {
            arrows: false,
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: true,
            autoplaySpeed: 3000,


          }
        },

      ],

    };
    var settings2 = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: this.state.CategorySlider.length < 6 ? this.state.CategorySlider.length : 6,
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            arrows: false,
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true,
      autoplaySpeed: 3000,
          }
        },



      ],

    };
    const categorySliderCard = this.state.CategorySlider.map(item => {
      return <CategorySliderCard item={item} />
    });

    const ListItem = this.state.RecommendedProducts.map((pitem) => {
      return (
        <li key={pitem.ProductId} >
          <ProductCard item={pitem} _className={this.state.favoriteIds.indexOf(pitem.Id) >= 0 ? "product-card__like-button active" : "product-card__like-button"} toggleFavorite={() => { this.state.favoriteIds.indexOf(pitem.Id) >= 0 ? this.removeFavorites(pitem.Id) : this.AddFavorite(pitem.Id) }} ></ProductCard>
        </li>
      );
    });

    const BannerList = this.state.FashionList.map((item, index) => {
      //console.log(index);
      const _className = item.IsWidePhoto === true ? 'col-lg-8' : 'col-lg-4';
      const _className2 = item.IsWidePhoto === true ? 'InspirationBanner__text mt-auto InspirationBanner__text--w ' : 'InspirationBanner__text mt-auto';
      return (
        <div key={item.Id} className={_className}>
          <InspirationBanner fashionHref={'/' + item.ButtonUrl} className={_className2} item={item} />
        </div>
      )
    });

    const banner3list = this.state.BannerList.map(item => {
      return (

        <div className="col-lg-4">
          <div className="category-card d-flex" style={{ background: `url(${item.PhotoPath})` }}>
            <div className="overlay-categorycard"></div>
            <a href={item.ContinueUrl} className="category-card__button ">
              {item.Title}
            </a>
          </div>
        </div>
      )
    })

    return (
      <>
      {this.state.addFavoriteLoading == true &&
        <div className="favoriteoverlay">

        </div>
      }
      <div className="Fashion">
        <div className="container">
          <BreadCrumb path={i18next.t("FashionUp")} />
          <h2 className="h-size-4 text-center mt-4">{i18next.t("FashionUp")}</h2>
          <div className="row ">
            <div className="col-10 offset-1">
              <p className="p-size-1 text-center mt-3">Moda alanındaki güncel trendleri, yaratıcı ve yenilikçi markaları lokal ve global olarak takip ediyor, sizler ile en hızlı şekilde buluşturuyoruz.  </p>

            </div>
          </div>
          <div className="row">
            {BannerList}
          </div>
          <h5 className="container text-center h-size-4 mt-4">{i18next.t("Categories")}</h5>
          <div className="container  product-card--pd">
            <div className="product-card">
              <Slider {...settings2}>
                {categorySliderCard}
              </Slider>
            </div>
          </div>
          <div className="container">
          <div className="row">
            {banner3list}

          </div>
        </div>
          <h5 className="container text-center h-size-4 mt-4">{i18next.t("RecentBestSellers")}</h5>
          <div className="container  product-card--pd">
            <div className="product-card">
              <Slider {...settings}>
                {ListItem}
              </Slider>
            </div>
          </div>

          <div className=" row mb-5 ">
            <div className="col-lg-6 mx-auto">
              <div className="col-lg-5 mx-auto">
                <div className="slider__button">
                  <a href="#" className="h-size-2"> {i18next.t("AllProducts")}</a> </div>
              </div>
            </div>

          </div>

        </div>



        <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showErrorModal} onHide={() => this.setState({ showErrorModal: false })}>
            <Modal.Header closeButton>
            </Modal.Header>
            <h4 className="LoginModal__title text-center">{i18next.t('UnSuccessful')}</h4>
            <p></p>
            <Modal.Body>
              <div className="text-center mb-3">
                <svg className="iconSize-md" >
                  <use href={Sprite + "#sad-face"} ></use>
                </svg >
              </div>
              <form className="LoginModal  ">
                <p className="text-center  p-size-1">{this.state.errorMessage}</p>
                <div className="text-center  w-50  mx-auto">
                  <a onClick={() => this.setState({ showErrorModal: false })}  className="submit-button">{i18next.t('OK')}</a>
                </div>
              </form>
            </Modal.Body>
            {/* <Modal.Footer>
              <div className="KeepInTouch__Img ">
                <img src={KeepInTouchImg} className="img-fluid"></img>
              </div>
            </Modal.Footer> */}
        </Modal>
      </div>
      </>
    )
  }
})
export default inject((stores) => ({
  Store: stores.Store,
}))(Fashion);
