import React, { Component } from 'react'
import BreadCrumb from '../Components/BreadCrumb';
import SozlesmeCard from '../Components/SozlesmeCard';
import SozlesmeCardMobil from '../Components/SozlesmeCardMobil';
import i18next from 'i18next';

export default class Faq extends Component {
    render() {
        return (
            <div className="sozlesme body--margin">
                <div className="container-fluid">
                    <div className="container">
                        <BreadCrumb  path="FAQ"></BreadCrumb>
                    </div>
                </div>
                <div className="container py-5">
                    <div className="row">
                        <div className="col-lg-3  d-none d-lg-block ">
                            <SozlesmeCard className5={"bg--secondary"} />
                        </div>
                        <div className="col-lg-3  d-lg-none UserDetail__section1">
                            <SozlesmeCardMobil active5></SozlesmeCardMobil>
                        </div>
                        <div className="col-lg-9">
                            <h4 className="h-size-4 mt-3">Sıkça Sorulan Sorular</h4>
                            {/* <p className="p-size-1">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean dignissim in vitae eu diam nulla. Ultrices augue varius id mi pellentesque sit arcu adipiscing. Quam pulvinar nulla eros, mus dictum sed. A, pretium tortor quisque consectetur lobortis eu gravida.
                            </p> */}
                            </div>
                    </div>
                </div>
            </div>
        )
    }
}
