import React, { Component } from 'react';
// import ProductCardImage from '../Assets/Image/productcard1.svg';

import Sprite from '../Assets/Icons/sprite.svg';
import i18next from 'i18next';



export default class ProductCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };

  }
  render() {
    const { item, designer, designerName, disabled } = this.props;
    const { _className } = this.props;
    var totalStock = 0;
    totalStock = item.IsMainVariation === true ? item.Stock : item.Variations.reduce((totalStock, x) => totalStock + x.Stock, 0);
    return (
      // <div className="container product-cards">
      <>
        <div className="product-card  my-3">
          <a className="z2 text-dark" href={`/productdetail/${item.Url}`}>
            <div className="product-card__image">
              {/* {
                totalStock <= 0 ?
                  <div className="product-card__image__nostock__overlay"><div className="divNoStockTitle">{i18next.t("NoStock2")}</div></div>
                  :
                  <></>
              } */}
              <div className="product-card-buttons-container">
                {
                  totalStock <= 0 ?
                    <>
                      <div className="divMyButtons">
                        <div className="submit-button">TÜKENDİ</div>
                      </div>
                    </>
                    :
                    <>
                      <div className="divMyButtons">
                        <div className="submit-button">HEMEN AL</div>
                        <div className="submit-button">SEPETE EKLE</div>
                      </div>
                    </>

                }
              </div>
              <div className="productCardImageContainer" style={{ background: `url(${item.MainPhoto})`, backgroundPosition: 'Center', backgroundSize: 'Contain', backgroundRepeat: 'no-repeat' }}></div>
              {/* <img src={item.MainPhoto} className="img-fluid" /> */}
              {item.DiscountPercentage > 0 ?
                <>
                  <span className="product-card__discountAmount">
                    -%{item.DiscountPercentage}
                  </span>
                </>
                :
                <>
                </>}
              
            </div>
            <div className="product-card__body">
              <p className="product-card__title">{item.Title}</p>
              <p className="product-card__Subtitle">{"All Shades"}</p>
              {/* {designer ? <p className="product-card__brand">{designerName}</p> : <p className="product-card__brand">{item.Designer.Name}</p>} */}
              <div className="product-card__PriceContainer">
                {item.DiscountedPrice > 0 ?
                  <>
                    <span className="product-card__oldPrice"> <del>{parseFloat(item.Price).toFixed(2)} TL</del></span>
                    <span className="product-card__newPrice">{parseFloat(item.DiscountedPrice).toFixed(2)} TL</span>
                  </>
                  :
                  <>
                    <span className="product-card__newPrice"> {parseFloat(item.Price).toFixed(2)} TL</span>
                  </>}
              </div>
              {/* <div className="product-card-add-cart">SEPETE EKLE</div> */}
            </div>
          </a>
          <span disabled={disabled} onClick={this.props.toggleFavorite} className={_className} >
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="20" cy="20" r="19.5" fill="none" stroke="#909090" />
                  <path d="M19.497 13.815L20 14.3346L20.503 13.815C22.3725 11.8837 25.394 11.8837 27.2635 13.815C29.1455 15.7592 29.1455 18.9205 27.2635 20.8647L20.2156 28.1457C20.0977 28.2675 19.9023 28.2675 19.7844 28.1457L12.7365 20.8647C10.8545 18.9205 10.8545 15.7592 12.7365 13.815C14.606 11.8837 17.6275 11.8837 19.497 13.815Z" stroke="#909090" stroke-width="1.4" />
                </svg>
              </span>
        </div>

        {/* <div className="row mt-5">
        <div className="col-8 col-md-4 col-lg-3 mx-auto">
          <div className="slider__button">
            <a href="#" >Tüm seçili ürünler</a>
          </div>
        </div>
      </div> */}
      </>
      // </div>
    );
  }
}

