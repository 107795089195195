import React, { Component } from 'react';
import OrderSummary from '../Components/OrderSummary';
import i18next from 'i18next';
import Form from 'react-bootstrap/Form';
import BreadCrumb from '../Components/BreadCrumb';
import DeliveryInformation from '../Components/AddressCard';
import AddressCard from '../Components/AddressCard';
import Sprite from '../Assets/Icons/sprite.svg';
import Modal from 'react-bootstrap/Modal';
import KeepInTouchImg from '../Assets/Image/KeepInTouch__Img.svg';
import ApiBase from '../lib/ApiBase';
import { inject, observer } from 'mobx-react';
import InputMask from 'react-input-mask';
const AdresBilgisi = observer(class AdresBilgisi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      favoriteList: [],
      isFavorited: -1,
      selectedSameAddress: true,
      showAddresEditModal: false,
      showSuccesModal: false,
      showErrorModal: false,
      hasAddressCard: false,
      IsGiftWrap: false,
      isLogin: false,
      isBireysel: true,
      isKurumsal: false,
      BillTypeTCNo: "",
      BillTypeNameSurname: "",
      BillTypeCompanyName: "",
      BillTypeTaxNumber: "",
      BillTypeTaxName: "",
      orderSummary: [
        {

          TotalPrice: '99,99',
          CargoPrice: '6,99',
          GeneralTotalPrice: '106,98'
        }
      ],

      selectedId: 0,

      AUNameSurname: "",
      AUEmail: "",
      AUPhoneNumber: "",
      AUZipCode: "",
      AUSelectedCityId: 0,
      AUSelectedDistrictId: 0,
      AUAddressDescription: "",

      BillTypeAUNameSurname: "",
      BillTypeAUEmail: "",
      BillTypeAUPhoneNumber: "",
      BillTypeAUZipCode: "",
      BillTypeAUSelectedCityId: 0,
      BillTypeAUSelectedDistrictId: 0,
      BillTypeAUAddressDescription: "",

      NewAddressTitle: "",
      NewAddressNameSurname: "",
      NewAddressSelectedCityId: 0,
      NewAddressSelectedDistrictId: 0,
      NewAddressAddressDescription: "",
      NewAddressZipCode: "",
      NewAddressPhoneNumber: "",

      AddressList: [],

      adresdropdownFatura: false,
      SelectedTeslimatAddressIndex: 0,
      SelectedTeslimatAddressId: 0,
      SelectedFaturaAddresId: 0,
      SelectedFaturaAddresIndex: 0,

      CityList: [],
      DistrictList: [],
      DistrictListAU: [],
      DistrictListAUFatura: [],

      SelectedAddressDescriptionTeslimat: "",
      SelectedAddressDescriptionFatura: "",

      errorMessage: "",
      odemeyeGecLoading: false,
      CartPrices: [],
    };


  }

  componentDidMount() {

    this.getCityList();
    var _isLogin = localStorage.getItem("isLogin");
    var cUser = [];
    if (_isLogin === "true") {
      cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      this.GetAddresses();
    }
    window.scrollTo(0, 0);
    this.getCartPrice()
  }
  getCartPrice = () => {
    this.setState({ actLoading: true });

    var _isLogin = localStorage.getItem("isLogin");
    // console.log(_isLogin)
    var _UserId = null;
    if (_isLogin === "true") {
      var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId
    }
    var Lang = localStorage.getItem("lang")
    var AUID = localStorage.getItem("AnonymousUserId");
    var LangId = Lang === "tr" ? 1 : 2;
    ApiBase.Post({
      url: '/get-cart-prices',
      data: {
        LanguageId: LangId,
        UserId: _UserId,
        AnonymousUserId: AUID,
      },
      successFunction: (e) => {
        console.log("cartPrice", e.data.data)

        this.setState({
          CartPrices: e.data.data
        })
      },
      errorFunction: (e) => {
        // console.log(e.data.message)
        this.setState({ actLoading: false, showErrorModal: true, errorMessage: e.data.message });

      }
    })
  }
  ////////
  getCityList = () => {
    this.setState({ CityLoading: true });
    ApiBase.Post({
      url: '/get-city-list',
      data: {
        CountryId: 223,
      },
      successFunction: (e) => {
        //   console.log("şehirler", e.data.data)
        this.setState({ CityList: e.data.data.cities, CityLoading: false });

      },
      errorFunction: (e) => {
        this.setState({ CityLoading: false })
        // console.log(e.data.message)
      }
    })
  };
  getDistrictList = () => {
    this.setState({ DistrictLoading: true });
    ApiBase.Post({
      url: '/get-district-list',
      data: {
        CityId: this.state.NewAddressSelectedCityId,
      },
      successFunction: (e) => {
        //  console.log("ilçeler", e.data.data)
        this.setState({ DistrictList: e.data.data.districts, DistrictLoading: false });

      },
      errorFunction: (e) => {
        this.setState({ DistrictLoading: false })
        // console.log(e.data.message)
      }
    })
  };

  onChangeCity = (e) => {
    this.setState({ NewAddressSelectedCityId: e.target.value }, () => {
      this.getDistrictList();
    })
  };

  onChangeDistrict = (e) => {
    this.setState({ NewAddressSelectedDistrictId: e.target.value });
  };
  ////////login değilse

  getDistrictListAU = () => {
    this.setState({ DistrictLoading: true });
    ApiBase.Post({
      url: '/get-district-list',
      data: {
        CityId: this.state.AUSelectedCityId,
      },
      successFunction: (e) => {
        //  console.log("ilçeler", e.data.data)
        this.setState({ DistrictListAU: e.data.data.districts, DistrictLoading: false });

      },
      errorFunction: (e) => {
        this.setState({ DistrictLoading: false })
        // console.log(e.data.message)
      }
    })
  };

  onChangeCityTeslimat = (e) => {
    this.setState({ AUSelectedCityId: e.target.value }, () => {
      // console.log(this.state.AUSelectedCityId)
      this.getDistrictListAU();
    })
  };

  onChangeDistrictTeslimat = (e) => {
    this.setState({ AUSelectedDistrictId: e.target.value });
  };

  getDistrictListFatura = () => {
    this.setState({ DistrictLoading: true });
    ApiBase.Post({
      url: '/get-district-list',
      data: {
        CityId: this.state.BillTypeAUSelectedCityId,
      },
      successFunction: (e) => {
        //  console.log("ilçeler", e.data.data)
        this.setState({ DistrictListAUFatura: e.data.data.districts, DistrictLoading: false });

      },
      errorFunction: (e) => {
        this.setState({ DistrictLoading: false })
        // console.log(e.data.message)
      }
    })
  };

  onChangeCityFatura = (e) => {
    this.setState({ BillTypeAUSelectedCityId: e.target.value }, () => {
      this.getDistrictListFatura();
    })
  };

  onChangeDistrictFatura = (e) => {
    this.setState({ BillTypeAUSelectedDistrictId: e.target.value });
  };


  //////
  addAdress = () => {
    this.setState({ actLoading: true });
    var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);


    ApiBase.Post({
      url: '/add-address',
      data: {
        Title: this.state.NewAddressTitle,
        NameSurname: this.state.NewAddressNameSurname,
        City: this.state.NewAddressSelectedCityId,
        District: this.state.NewAddressSelectedDistrictId,
        Description: this.state.NewAddressAddressDescription,
        Phone: this.state.NewAddressPhoneNumber,
        CurrentUser: cUser,
        Zip: this.state.NewAddressZipCode

      },
      successFunction: (e) => {
        // console.log(e.data)
        this.setState({ showAddresEditModal: false, showSuccesModal: true, SuccessMessage: e.data.message })
        // this.setState({ loading: false })
        this.GetAddresses();
      },
      errorFunction: (e) => {
        // console.log(e.data.message)
        this.setState({ showErrorModal: true, errorMessage: e.data.message });

      }
    })
  }

  GetAddresses = () => {
    this.setState({ actLoading: true });
    var cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
    ApiBase.Post({
      url: '/get-addresses',
      data: {
        CurrenUser: cUser
      },
      successFunction: (e) => {
        // console.log("addressList", e.data.data.AddressList)
        this.setState({
          AddressList: e.data.data.AddressList,
          SelectedTeslimatAddressId: e.data.data.AddressList.length > 0 ? e.data.data.AddressList[0].AdressId : 0
        })
        // this.setState({ loading: false })
      },
      errorFunction: (e) => {
        // console.log(e.data.message)
        // this.setState({ showErrorModal: true, errorMessage: e.data.message });

      }
    })
  }

  ///////////

  SelectTeslimatAdresi = (item, index) => {
    this.setState({ SelectedTeslimatAddressIndex: index, adresdropdown: false, SelectedTeslimatAddressId: item.AdressId, SelectedAddressDescriptionTeslimat: item.AddressDescription });
  }

  SelectFaturaAdresi = (item, index) => {
    this.setState({ SelectedFaturaAddresIndex: index, adresdropdownFatura: false, SelectedFaturaAddresId: item.AdressId, SelectedAddressDescriptionFatura: item.AddressDescription });
  }
  ///////
  showAddresEditModal = () => {
    this.setState({ showAddresEditModal: true })
  }
  hideLoginModal = () => {
    this.setState({ showAddresEditModal: false, showSuccesModal: false, showErrorModal: false })
  }
  showSuccesModal = () => {
    this.setState({ showSuccesModal: true, showAddresEditModal: false })

  }

  onClickOdemeyeGec = () => {

    this.setState({ odemeyeGecLoading: true });
    var AUID = localStorage.getItem("AnonymousUserId")
    var _isLogin = localStorage.getItem("isLogin");
    var cUser = [];
    var _UserId = 0;
    if (_isLogin === "true") {
      cUser = JSON.parse(this.props.Store.CurrentUserStore.CurrentUser);
      _UserId = cUser.UserId;
    }

    ApiBase.Post({
      url: '/payment-process',
      data: {
        UserId: _UserId,
        AnonymousUserId: AUID,
        IsLoggedIn: _isLogin,
        IsForChecking: true,
        BillAddressId: this.state.SelectedFaturaAddresId,
        DeliveryAddressId: this.state.SelectedTeslimatAddressId,
        IsCorporate: !this.state.isBireysel,
        PersonalNameSurname: this.state.BillTypeNameSurname,
        CitizenshipNo: this.state.BillTypeTCNo,
        CompanyName: this.state.BillTypeCompanyName,
        TaxNumber: this.state.BillTypeTaxNumber,
        TaxDepartment: this.state.BillTypeTaxName,
        DeliveryEmail: this.state.AUEmail,
        DeliveryNameSurname: this.state.AUNameSurname,
        DeliveryPhone: this.state.AUPhoneNumber,
        DeliveryCityId: this.state.AUSelectedCityId,
        DeliveryDistrictId: this.state.AUSelectedDistrictId,
        DeliveryZipCode: this.state.AUZipCode,
        DeliveryAddress: this.state.AUAddressDescription,
        IsSameAddress: this.state.selectedSameAddress,
        BillNameSurname: this.state.BillTypeAUNameSurname,
        BillEmail: this.state.BillTypeAUEmail,
        BillPhone: this.state.BillTypeAUPhoneNumber,
        BillCityId: this.state.BillTypeAUSelectedCityId,
        BillDistrictId: this.state.BillTypeAUSelectedDistrictId,
        BillZipCode: this.state.BillTypeAUZipCode,
        BillAddress: this.state.BillTypeAUAddressDescription,
        IsGiftWrap: this.state.IsGiftWrap
      },
      successFunction: (e) => {
        //  console.log("ilçeler", e.data.data)
        this.setState({ odemeyeGecLoading: false })
        this.props.history.push({
          pathname: '/odemeyontemi',
          state: {
            SelectedTeslimatAddressId: this.state.SelectedTeslimatAddressId,
            SelectedFaturaAddresId: this.state.SelectedFaturaAddresId,

            TCNo: this.state.BillTypeTCNo,
            BillTypeNameSurname: this.state.BillTypeNameSurname,
            BillTypeCompanyName: this.state.BillTypeCompanyName,
            BillTypeTaxNumber: this.state.BillTypeTaxNumber,
            BillTypeTaxName: this.state.BillTypeTaxName,

            TeslimatNameSurname: this.state.AUNameSurname,
            TeslimatEmail: this.state.AUEmail,
            TeslimatPhone: this.state.AUPhoneNumber,
            TeslimatZipCode: this.state.AUZipCode,
            TeslimatCityId: this.state.AUSelectedCityId,
            TeslimatDistrictId: this.state.AUSelectedDistrictId,
            TeslimatAddressDesc: this.state.AUAddressDescription,

            FaturaNameSurname: this.state.BillTypeAUNameSurname,
            FaturaEmail: this.state.BillTypeAUEmail,
            FaturaPhone: this.state.BillTypeAUPhoneNumber,
            FaturaZipCode: this.state.BillTypeAUZipCode,
            FaturaCityId: this.state.BillTypeAUSelectedCityId,
            FaturaDistrictId: this.state.BillTypeAUSelectedDistrictId,
            FaturaAddressDecription: this.state.BillTypeAUAddressDescription,

            IsBireysel: this.state.isBireysel,
            IsSameAddress: this.state.selectedSameAddress,

            SelectedAddressDescriptionFatura: this.state.SelectedAddressDescriptionFatura,
            SelectedAddressDescriptionTeslimat: this.state.SelectedAddressDescriptionTeslimat,

            IsGiftWrap: this.state.IsGiftWrap
          }

        })

      },
      errorFunction: (e) => {
        this.setState({ odemeyeGecLoading: false, showErrorModal: true, errorMessage: e.data.message })
        // console.log(e.data.message)
      }
    })



  }



  selectCheckBox = (id) => {
    this.setState({ selectedId: id })
  }
  render() {

    const { history } = this.props;

    const orderSummary = this.state.orderSummary.map(item => {
      return <OrderSummary loading={this.state.odemeyeGecLoading} onClickOdemeyeGec={this.onClickOdemeyeGec} item={this.state.CartPrices} />
    });

    const address = this.state.AddressList.map((item, index) => {
      return (
        <div className="kayitliadresler mb-2">
          <AddressCard item={item} />
          <div onClick={() => { this.SelectTeslimatAdresi(item, index) }} className="addressCardDropdown d-flex align-items-center">

            <svg className="iconSize-xxs" >
              <use href={Sprite + "#plus"} ></use>
            </svg >

          </div></div>
      )
    });

    const cityOptions = this.state.CityList.map((item) => {
      return (
        <option value={item.CityId}> {item.City} </option>
      )
    })

    const districtOptions = this.state.DistrictList.map((item) => {
      return (
        <option value={item.DistrictId}>{item.District}</option>
      )
    })

    const districtOptionsTeslimat = this.state.DistrictListAU.map((item) => {
      return (
        <option value={item.DistrictId}>{item.District}</option>
      )
    });

    const districtOptionsFatura = this.state.DistrictListAUFatura.map((item) => {
      return (
        <option value={item.DistrictId}>{item.District}</option>
      )
    });


    const addressFatura = this.state.AddressList.map((item, index) => {
      return (
        <div className="kayitliadresler mb-2">
          <AddressCard item={item} />
          <div onClick={() => { this.SelectFaturaAdresi(item, index) }} className="addressCardDropdown d-flex align-items-center">

            <svg className="iconSize-xxs" >
              <use href={Sprite + "#plus"} ></use>
            </svg >

          </div></div>
      )
    });
    var _isLogin = localStorage.getItem("isLogin");
    return (
      <>
        <BreadCrumb title={i18next.t("MyBasket")} titleLink={"/sepetim"} path={i18next.t("AddressDescription")} />
        <div className="AdresBilgisi">
          <div className="container">
            <div className="row section1">
              <div className="col-lg-9">
                <div className="row ">
                  <div className="col-lg-6 teslimatAdresi">
                    <h5>{i18next.t("DeliveryAddress")}</h5>
                    {_isLogin === "true" ?
                      <>
                        {this.state.AddressList.length > 0 ?
                          <div className="kayitliadresler mb-2">
                            <AddressCard item={this.state.AddressList[this.state.SelectedTeslimatAddressIndex]} />
                            <div className="addressCardDropdown d-flex align-items-center" onClick={() => { this.setState({ adresdropdown: !this.state.adresdropdown }) }}>
                              <svg className="iconSize-xs" >
                                <use href={Sprite + "#chevron-arrow-down"} ></use>
                              </svg >
                            </div>
                          </div>
                          :
                          <div className="p-5 text-center">
                            <p className="h-size-3">Kayıtlı adres bulunamadı.</p>
                          </div>}
                        {this.state.adresdropdown === true ?
                          <div className="addressCardDropdownItems" id="style-4">
                            {address}
                          </div>
                          :
                          ""}
                        <div className=" text-center mx-auto mt-4">
                          <a onClick={this.showAddresEditModal} className="submit-button2 border-0"> +
                                  <span className="p-size-2"> {i18next.t('AddNewAddress')}</span>
                          </a>
                        </div>
                      </>
                      :
                      //üye değilse
                      <>
                        <Form className="iletisim">
                          <div className="divFormSubTitle">{i18next.t("NameSurname")}</div>
                          <input type="text" className="form-control divFromInput" id="name" required value={this.state.AUNameSurname} onChange={(e) => this.setState({ AUNameSurname: e.target.value })} />
                          <div className="divFormSubTitle">{i18next.t("EmailAdress")}</div>
                          <input type="mail" className="form-control divFromInput" id="emailadress" required value={this.state.AUEmail} onChange={(e) => this.setState({ AUEmail: e.target.value })} />
                          <div className="divFormSubTitle">{i18next.t("PhoneNumber")}</div>
                          <InputMask mask="(999) 999 99 99"
                            className="c-input form-control divFromInput"
                            maskChar={null}
                            placeholder
                            type="tel"
                            id="telnoTeslimat"
                            value={this.state.AUPhoneNumber}
                            onChange={(e) => this.setState({ AUPhoneNumber: e.target.value })}
                            required>
                          </InputMask>
                          <div className="divFormSubTitle">{i18next.t("PostalCode")}</div>
                          <input type="text" className="form-control divFromInput" id="postalcode" required value={this.state.AUZipCode} onChange={(e) => this.setState({ AUZipCode: e.target.value })} />
                          <div className="divFormSubTitle">İl Seçiniz</div>
                          <Form.Group >
                            <select className="form-control divFromInput" onChange={this.onChangeCityTeslimat} value={this.state.AUSelectedCityId}>
                              <option className="selected">{i18next.t("SelectProvince")}​</option>
                              {cityOptions}
                            </select>
                          </Form.Group>
                          <div className="divFormSubTitle">İlçe Seçiniz</div>
                          <Form.Group >
                            <select className="form-control divFromInput" onChange={this.onChangeDistrictTeslimat} value={this.state.AUSelectedDistrictId}>
                              <option className="selected">{i18next.t("SelectDistrict")}​</option>
                              {districtOptionsTeslimat}
                            </select>
                          </Form.Group>
                          <div className="divFormSubTitle">{i18next.t("FullAddress")}</div>
                          <textarea className="w-100 form-control divFromInput" rows="1" required value={this.state.AUAddressDescription} onChange={(e) => this.setState({ AUAddressDescription: e.target.value })} ></textarea>
                        </Form>
                      </>}

                    <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showAddresEditModal} onHide={this.hideLoginModal}>
                      <Modal.Header closeButton>
                      </Modal.Header>
                      <h4 className="LoginModal__title text-center">{i18next.t('NEWADDRESS')}</h4>
                      <Modal.Body>
                        <Form className="iletisim iletisim--pd">
                          <div className="divFormSubTitle">{i18next.t('AddressTitle')}</div>
                          <input type="text" className="form-control divFromInput" id="addresstitle" required value={this.state.NewAddressTitle} onChange={(e) => this.setState({ NewAddressTitle: e.target.value })} />
                          <div className="divFormSubTitle">{i18next.t("NameSurname")}</div>
                          <input type="text" className="form-control divFromInput" id="name" required value={this.state.NewAddressNameSurname} onChange={(e) => this.setState({ NewAddressNameSurname: e.target.value })} />
                          <div className="divFormSubTitle">İl Seçiniz</div>
                          <Form.Group >
                            <select className="form-control divFromInput" onChange={this.onChangeCity} value={this.state.NewAddressSelectedCityId}>
                              <option className="selected">{i18next.t("SelectProvince")}</option>
                              {cityOptions}
                            </select>
                          </Form.Group>
                          <div className="divFormSubTitle">İlçe Seçiniz</div>
                          <Form.Group >
                            <select className="form-control divFromInput" onChange={this.onChangeDistrict} value={this.state.NewAddressSelectedDistrictId} >
                              <option className="selected">{i18next.t("SelectDistrict")}</option>
                              {districtOptions}
                            </select>
                          </Form.Group>
                          <div className="divFormSubTitle">{i18next.t("FullAddress")}</div>
                          <textarea className="w-100 form-control divFromInput" rows="1" required value={this.state.NewAddressAddressDescription} onChange={(e) => this.setState({ NewAddressAddressDescription: e.target.value })}></textarea>
                          <div className="divFormSubTitle">{i18next.t("PhoneNumber")}</div>
                          <InputMask mask="(999) 999 99 99"
                            className="c-input form-control divFromInput"
                            maskChar={null}
                            placeholder
                            type="tel"
                            id="telno"
                            value={this.state.Phone}
                            onChange={(e) => { this.setState({ NewAddressPhoneNumber: e.target.value }) }}
                            required>
                          </InputMask>
                          <div className="divFormSubTitle">{i18next.t("PostalCode")}</div>
                          <input type="text" className="form-control divFromInput" id="postalcode" required value={this.state.NewAddressZipCode} onChange={(e) => this.setState({ NewAddressZipCode: e.target.value })} />
                          <div className="col-lg-7 text-center  mx-auto">
                            {this.state.updateAddress === true ?
                              <a onClick={this.updateAddress} className="submit-button2  font-weight-bold"> {i18next.t("UPDATE")}
                              </a>
                              :
                              <a onClick={this.addAdress} className="submit-button2  font-weight-bold"> {i18next.t("SAVE")}
                              </a>
                            }
                          </div>
                          <div className="col-lg-7  text-center  mx-auto">
                            <a onClick={this.hideLoginModal} className="addAddressBtnCancel font-weight-bold "> {i18next.t("CANCEL")}
                            </a>
                          </div>
                        </Form>
                      </Modal.Body>
                      {/* <Modal.Footer>
                        <div className="KeepInTouch__Img ">
                          <img src={KeepInTouchImg} className="img-fluid"></img>
                        </div>
                      </Modal.Footer> */}
                    </Modal>
                    {/* Succes Modal */}
                    <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showSuccesModal} onHide={this.hideLoginModal}>
                      <Modal.Header closeButton>
                      </Modal.Header>
                      <h4 className="LoginModal__title text-center"> {i18next.t("ProcessSuccess")}</h4>
                      <p></p>
                      <Modal.Body>
                        <div className="text-center mb-3">
                          <svg className="iconSize-md" >
                            <use href={Sprite + "#smile"} ></use>
                          </svg >
                        </div>
                        <form className="LoginModal  ">
                          <p className="text-center p-size-1"> {i18next.t("SuccessMessage")}</p>
                          <div className="text-center  w-50  mx-auto">
                            <a onClick={() => this.setState({ showSuccesModal: false })} className="submit-button">{i18next.t('OK')}</a>
                          </div>
                        </form>
                      </Modal.Body>
                      {/* <Modal.Footer>
                        <div className="KeepInTouch__Img ">
                          <img src={KeepInTouchImg} className="img-fluid"></img>
                        </div>
                      </Modal.Footer> */}
                    </Modal>
                    {/* Error Modal  */}
                    <Modal size="lg" dialogClassName="modal-dialog-centered" show={this.state.showErrorModal} onHide={this.hideLoginModal}>
                      <Modal.Header closeButton>
                      </Modal.Header>
                      <h4 className="LoginModal__title text-center">{i18next.t('UnSuccessful')}</h4>
                      <p></p>
                      <Modal.Body>
                        <div className="text-center mb-3">
                          <svg className="iconSize-md" >
                            <use href={Sprite + "#sad-face"} ></use>
                          </svg >
                        </div>
                        <form className="LoginModal  ">
                          <p className="text-center  p-size-1">{this.state.errorMessage}</p>
                          <div className="text-center  w-50  mx-auto">
                            <a onClick={() => this.setState({ showErrorModal: false })} className="submit-button">TAMAM</a>
                          </div>
                        </form>
                      </Modal.Body>
                      {/* <Modal.Footer>
                        <div className="KeepInTouch__Img ">
                          <img src={KeepInTouchImg} className="img-fluid"></img>
                        </div>
                      </Modal.Footer> */}
                    </Modal>
                  </div>
                  <div className="col-lg-6">
                    <h5>{i18next.t("BillingAddress")}</h5>
                    <div className="form-check my-3">
                      <input type="checkbox" class="form-check-input" value={this.state.selectedSameAddress} onChange={() => { this.setState({ selectedSameAddress: !this.state.selectedSameAddress }) }} checked={this.state.selectedSameAddress} />
                      <label htmlFor="exampleCheck1" className="h-size-3" >  {i18next.t("SameAddress")} </label>
                    </div>
                    {this.state.selectedSameAddress === true ?
                      <></>
                      :
                      <>
                        {_isLogin === "true" ?
                          <>
                            {this.state.AddressList.length > 0 ?
                              <div className="kayitliadresler mb-2">
                                <AddressCard item={this.state.AddressList[this.state.SelectedFaturaAddresIndex]} />
                                <div className="addressCardDropdown d-flex align-items-center" onClick={() => { this.setState({ adresdropdownFatura: !this.state.adresdropdownFatura }) }}>
                                  <svg className="iconSize-xs" >
                                    <use href={Sprite + "#chevron-arrow-down"} ></use>
                                  </svg >
                                </div>
                              </div>
                              :
                              <div className="p-5 text-center">
                                <p className="h-size-3">Kayıtlı adres bulunamadı.</p>
                              </div>}
                            {this.state.adresdropdownFatura === true ?
                              <div className="addressCardDropdownItems" id="style-4">
                                {addressFatura}
                              </div>
                              :
                              ""}
                            <div className="  text-center mx-auto mt-4">
                              <a onClick={this.showAddresEditModal} className="submit-button2 border-0"> +
                                  <span className="p-size-2"> {i18next.t('AddNewAddress')}</span>
                              </a>
                            </div>
                          </>
                          :
                          //üye değilse
                          <>
                            <Form className="iletisim">
                              <div className="divFormSubTitle">{i18next.t("NameSurname")}</div>
                              <input type="text" className="form-control divFromInput" id="nameFatura" required value={this.state.BillTypeAUNameSurname} onChange={(e) => this.setState({ BillTypeAUNameSurname: e.target.value })} />
                              <div className="divFormSubTitle">{i18next.t("EmailAdress")}</div>
                              <input type="mail" className="form-control divFromInput" id="emailadressFatura" required value={this.state.BillTypeAUEmail} onChange={(e) => this.setState({ BillTypeAUEmail: e.target.value })} />
                              <div className="divFormSubTitle">{i18next.t("PhoneNumber")}</div>
                              <InputMask mask="(999) 999 99 99"
                                className="c-input form-control divFromInput"
                                maskChar={null}
                                placeholder
                                type="tel"
                                id="telnoFatura"
                                value={this.state.BillTypeAUPhoneNumber}
                                onChange={(e) => this.setState({ BillTypeAUPhoneNumber: e.target.value })}
                                required>
                              </InputMask>
                              <div className="divFormSubTitle">{i18next.t("PostalCode")}</div>
                              <input type="text" className="form-control divFromInput" id="postalcodeFatura" required value={this.state.BillTypeAUZipCode} onChange={(e) => this.setState({ BillTypeAUZipCode: e.target.value })} />
                              <div className="divFormSubTitle">İl Seçiniz</div>
                              <Form.Group >
                                <select className="form-control divFromInput" onChange={this.onChangeCityFatura} value={this.state.BillTypeAUSelectedCityId}>
                                  <option >{i18next.t("SelectProvince")}​</option>
                                  {cityOptions}
                                </select>
                              </Form.Group>
                              <div className="divFormSubTitle">İlçe Seçiniz</div>
                              <Form.Group >
                                <select className="form-control divFromInput" onChange={this.onChangeDistrictFatura} value={this.state.BillTypeAUSelectedDistrictId}>
                                  <option >{i18next.t("SelectDistrict")}​</option>
                                  {districtOptionsFatura}
                                </select>
                              </Form.Group>
                              <div className="divFormSubTitle">{i18next.t("FullAddress")}</div>
                              <textarea className="w-100 form-control divFromInput" rows="1" required value={this.state.BillTypeAUAddressDescription} onChange={(e) => this.setState({ BillTypeAUAddressDescription: e.target.value })} ></textarea>
                            </Form>
                          </>}
                      </>}
                    <h5>{i18next.t("BillType")}</h5>
                    <div className="row my-4">
                      <div className="col-4">
                        <div class="form-check">
                          <input className="form-check-input" type="radio" name="faturatipi" id="exampleRadios1" value="option1" checked={this.state.isBireysel} onChange={() => { this.setState({ isBireysel: true, isKurumsal: false }) }} />
                          <label className="form-check-label" htmlFor="exampleRadios1" className="h-size-3">
                            {i18next.t("Individual")}</label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div class="form-check">
                          <input className="form-check-input" type="radio" name="faturatipi" id="exampleRadios2" value="option2" checked={this.state.isKurumsal} onChange={() => { this.setState({ isKurumsal: true, isBireysel: false }) }} />
                          <label className="form-check-label" htmlFor="exampleRadios2" className="h-size-3">
                            {i18next.t("Corporate")}</label>
                        </div></div>
                    </div>
                    {this.state.isKurumsal === true ?
                      <Form className="iletisim my-4">
                        <div className="divFormSubTitle">{i18next.t("CompanyName")}</div>
                        <input type="text" className="form-control divFromInput" id="firmName" required value={this.state.BillTypeCompanyName} onChange={(e) => this.setState({ BillTypeCompanyName: e.target.value })} />
                        <div className="divFormSubTitle">{i18next.t("TaxNumber")}</div>
                        <input type="text" className="form-control divFromInput" id="taxNo" required value={this.state.BillTypeTaxNumber} onChange={(e) => this.setState({ BillTypeTaxNumber: e.target.value })} />
                        <div className="divFormSubTitle">{i18next.t("TaxOffice")}</div>
                        <input type="text" className="form-control divFromInput" id="taxAdministration" required value={this.state.BillTypeTaxName} onChange={(e) => this.setState({ BillTypeTaxName: e.target.value })} />
                      </Form>
                      :
                      <Form className="iletisim my-4">
                        <div className="divFormSubTitle">{i18next.t("TC Kimlik No")}</div>
                        <input type="text" className="form-control divFromInput" id="firmName" required value={this.state.BillTypeTCNo} onChange={(e) => this.setState({ BillTypeTCNo: e.target.value })} />
                        <div className="divFormSubTitle">{i18next.t("NameSurname")}</div>
                        <input type="text" className="form-control divFromInput" id="taxNo" required value={this.state.BillTypeNameSurname} onChange={(e) => this.setState({ BillTypeNameSurname: e.target.value })} />
                      </Form>}
                    <div className="divGiftWrap">
                      <input type="checkbox" class="form-check-input" value={this.state.IsGiftWrap} onChange={() => { this.setState({ IsGiftWrap: !this.state.IsGiftWrap }) }} checked={this.state.IsGiftWrap} />
                      <label htmlFor="taxNo">{i18next.t("IsGiftWrap")}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                {orderSummary}
              </div>
            </div>
            {/* <div className="AdresBilgi__subtitle">
            <h4 className="h-size-1">{i18next.t("RefundAndExchangeTerms")}</h4>
            <p className="p-size-1">
              <br /><br />
              <strong>Satın aldığım ürünü nasıl değiştirebilirim?</strong>
              <br /><br />
              www.shopwondrous.com  adresinden satın almış olduğunuz kullanılmamış ve tadilat yapılmamış ürünlerinizi, fatura ya da fişiniz ile birlikte fatura tarihinden itibaren en geç 30 gün içerisinde mağazamızdan değiştirebilirsiniz.
              <br /><br />
              <strong>Satın aldığım ürünü nasıl iade edebilirim?</strong>
              <br /><br />
              www.shopwondrous.com adresinden satın aldığınız ürünlerin iadesi için önce "Hesabım" sekmesinden ilgili siparişinizi seçmeniz ve talebinizi oluşturmanız gerekir. Sipariş satırında yer alan "Kargoya Verildi" yazısını tıklayın ve açılan sayfadaki “Siparişi İade” yönlendirmesi ile iade etmek istediğiniz ürünü ve iade sebebinizi seçerek süreci başlatabilirsiniz. Sonrasında herhangi bir SÜRAT Kargo şubesinden iade gönderiminizi bedelsiz olarak yapabilirsiniz. Teslimat adresi olarak “WONDROUS MAĞAZASI Plevne Bulvarı Kültür Mah. Sema Apt. No 1/2A ALSANCAK KONAK/İZMİR” yazabilir veya SÜRAT KARGO firmalarında WONDROUS Mağazası olarak ismen aratarak adresimize ulaşabilirsiniz. Ürününüz ilgili birimimize ulaştığında ödeme tipine göre işleminiz başlatılmış olur ve ürün bedeli hesabınıza iade edilir.
              <br /><br />
              <strong>Ürünü iade ederken kargo ücreti öder miyim?</strong>
              <br /><br />
              Hayır, aynı gün teslimat hizmeti kullanmadan verdiğiniz tüm standart gönderilerinizin iadesini ücretsiz olarak gerçekleştirebilirsiniz.
              <br /><br />
              <strong>İade etme süresi kaç gündür?</strong>
              <br /><br />
              Tüketici Kanunu'ndaki, "Sebep Göstermeden Cayma Hakkı" maddesine göre, ürünü teslim aldığınız tarihten itibaren ilk on dört (14) iş günüdür. Üründen kaynaklanan herhangi bir kusur sebebiyle yapacağınız iadelerde, teslim tarihinden itibaren 45 gün içerisinde iade işlemini gerçekleştirebilirsiniz.
              <br /><br />
              <strong>İade işleminden ne kadar zaman sonra geri ödeme hesabıma yansır?</strong>
              <br /><br />
              İade ettiğiniz tüm ürünlerin iade şartlarına uygunluğu kontrol edilir. İadeniz kabul edilip para iadeniz gerçekleştiğinde iadenin hesabınıza yansıma süresi banka tarafından belirlenir.
            </p>
          </div> */}
          </div>
        </div >
      </>
    )
  }
})
export default inject((stores) => ({
  Store: stores.Store,
}))(AdresBilgisi);
